import { BaseApi } from '@/helpers/base-api'

export default class MapApi extends BaseApi {
  constructor () {
    super('/api/v1/map')
    this.errorMessage = 'There was an issue when trying to fetch the markers. If this error persists, please contact our support.'
  }

  public getAllMapMarkers = (query: string): Promise<MapDto[]> => this.instance.get(`${query}`)

}
