import {
    VuexModule,
    Module,
    Action,
    Mutation,
  } from "vuex-module-decorators";
  import DashboardApi from "@/api/dashboard-api";
  import { formatDate } from "../../helpers/data";
  import dayjs from "dayjs";
  
  @Module({ namespaced: true, name: "dashboard" })
  export default class DashboardModule extends VuexModule {

    public filters = `?date_from=${dayjs().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toISOString()}&date_to=${dayjs().set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999).toISOString()}`; // Default filter for today;
    public taskFilters = "";
    public dashboardAppointments = [];
    public dashboardAppointmentsBar = [];
    public dashboardPendingTasks = [];

    // Loaders
    public isLoadingAppointmentsList = true;
    public isLoadingPendingTasks = true;
    public isChartLoading = true;
  
      @Action({ commit: "setDashboardAppointments" })
      async getDashboardAppointmentsAction(query: string): Promise<any> {
        this.setIsLoadingAppointmentsList(true);
        const dashboardApi = new DashboardApi();
        const dashboardAppointments = await dashboardApi.getDashboardAppointments(query);
        this.setIsLoadingAppointmentsList(false);
        return dashboardAppointments;
      }

      @Action({ commit: "setDashboardAppointmentsBars" })
      async getDashboardAppointmentsBarsAction(query: string): Promise<any> {
        this.setIsLoadingCharts(true)
        const dashboardApi = new DashboardApi();
        const dashboardPendingTasks = await dashboardApi.getDashboardAppointmentsBars(query);
        this.setIsLoadingCharts(false)

        return dashboardPendingTasks;
      }

      @Action({ commit: "setDashboardPendingTasks" })
      async getDashboardPendingTasks(query: string): Promise<any> {
        this.setIsLoadingPendingTasks(true)
        const dashboardApi = new DashboardApi();
        const dasboardPendingTasks = await dashboardApi.getDashboardTasksPending(query);
        this.setIsLoadingPendingTasks(false)
        return dasboardPendingTasks;
      }

      @Mutation
      public setDashboardAppointments(dashboardAppointments: any): void {
          this.dashboardAppointments = dashboardAppointments;
      }

      @Mutation
      public setDashboardAppointmentsBars(dashboardAppointmentsBar: any): void {
        this.dashboardAppointmentsBar = dashboardAppointmentsBar
      }

      @Mutation
      public setDashboardPendingTasks(dashboardPendingTasks: any): void {
          this.dashboardPendingTasks = dashboardPendingTasks;
      }

      @Mutation
      public setFilters(filters: string): void {
          this.filters = filters;
      }

      @Mutation
      public setTaskFilters(taskFilters: string): void {
          this.taskFilters = taskFilters;
      }

    @Mutation
    public setIsLoadingAppointmentsList(isLoadingAppointmentsList: boolean): void {
      this.isLoadingAppointmentsList = isLoadingAppointmentsList;
    }

    @Mutation
    public setIsLoadingPendingTasks(isLoadingPendingTasks: boolean): void {
      this.isLoadingPendingTasks = isLoadingPendingTasks;
    }

    @Mutation
    public setIsLoadingCharts(isChartLoading: boolean): void {
      this.isChartLoading = isChartLoading;
    }

    @Mutation
    public clearDashboardData(): void {
      this.dashboardAppointments = [];
      this.dashboardAppointmentsBar = [];
      this.dashboardPendingTasks = [];
    }
}
  