
import { Component, Vue, Watch } from 'vue-property-decorator'
import { commonModule, companyModule } from '@/store/modules/store-accessor'
import Statistics from './Statistics.vue';
import Personnel from './Personnel.vue';
import Payments from './Payments.vue';
import Partners from './Partners.vue';
import Management from './Management.vue';
import KeycloakSession from '@/helpers/keycloak-session';

@Component({
  components: { Statistics, Personnel, Payments, Management, Partners }
})
export default class InventoryItems extends Vue {
  currentTab =  0;

  get companyInfo() {
    return companyModule.company;
  }

  get partnersEnabled () {
    if(companyModule.company?.modules?.includes('DFB_PORTAL_PARTNERS')) {
      return true;
    }
    else {
      return false;
    }
  }

  async mounted() {
    companyModule.getCompanyInfo();
  }

  isAdmin() {
    const kc = new KeycloakSession()
    return kc.userHasRole('admin');
  }
}
