import { BaseApi } from '@/helpers/base-api'

export default class InstallationsApi extends BaseApi {
  constructor () {
    super('/api/v1/installations')
    this.errorMessage = 'There was an issue when trying to fetch the installations. If this error persists, please contact our support.'
  }

  public getAllInstallations = (query: string): Promise<InstallationDto[]> => this.instance.get(`${query}`)
  public getAllInstallationsById = (clientId: string): Promise<InstallationDto[]> => this.instance.get(`/client/${clientId}`)
  public getAllInstallationsCount = (): Promise<CountDto[]> => this.instance.get('/count')
  public getInstallation = (installationId: number): Promise<InstallationDetailsDto> => this.instance.get(`/${installationId}`)
  public getInstallationHistory = (): Promise<InstallationHistoryDto> => this.instance.get(`/history`)
  public getInstallationNextTagId = (): Promise<InstallationNextTagIdDto> => this.instance.get('/tag/next')

  public getInstallationPDF = (installationId: number): Promise<Blob> => this.instance.get(`/${installationId}/pdf`, {responseType: 'arraybuffer'})
  public getInstallationFile = (installationId: string, fileId: string): Promise<Blob> => this.instance.get(`/${installationId}/file/${fileId}`, {responseType: 'arraybuffer'})
  public getInstallationPhoto = (installationId: string, photoId: string): Promise<Blob> => this.instance.get(`/${installationId}/photos/${photoId}`, {responseType: 'arraybuffer'})

  
  public createInstallation = (data: Record<string, unknown>): Promise<string> => this.instance.post('', data)
  public createInstallationItem = (installationId: string, data: Record<string, unknown>): Promise<string> => this.instance.post(`/${installationId}/item`, data)
  public createInstallationPhoto = (installationId: string, data: Record<string, unknown>): Promise<string> => this.instance.post(`/${installationId}/photo`, data)
  public createInstallationFile = (installationId: string, data: Record<string, unknown>): Promise<string> => this.instance.post(`/${installationId}/file`, data)

  public updateInstallation = (installationId: string, data: Record<string, unknown>): Promise<string> => this.instance.patch(`/${installationId}`, data)
  public updateInstallationItem = (installationId: string, itemId: string, data: Record<string, unknown>): Promise<string> => this.instance.patch(`/${installationId}/item/${itemId}`, data)
  public updateInstallationPhoto = (installationId: string, photoId: string, data: Record<string, unknown>): Promise<string> => this.instance.patch(`/${installationId}/photo/${photoId}`, data)
  public updateInstallationFile = (installationId: string, fileId: string, data: Record<string, unknown>): Promise<string> => this.instance.patch(`${installationId}/files/${fileId}`, data)

  public deleteInstallation = (installationId: string): Promise<string> => this.instance.delete(`/${installationId}`)
  public deleteInstallationItem = (installationId: string, itemId: string): Promise<string> => this.instance.delete(`/${installationId}/item/${itemId}`)
  public deleteInstallationPhoto = (installationId: string, photoId: string): Promise<string> => this.instance.delete(`/${installationId}/photo/${photoId}`)
  public deleteInstallationFile = (installationId: string, fileId: string): Promise<string> => this.instance.delete(`/${installationId}/file/${fileId}`)

}
