<!--
 Copyright 2022 by domX Private Company

 All rights reserved,
 also regarding any disposal, exploitation, reproduction,
 editing, distribution, as well as in the event of
 applications for industrial property rights.

 This software contains proprietary and confidential
 information of domX Private Company.
 The disclosure of confidential information without
 express authorization is prohibited.

 This software may include Open Source Software.
 As far as Open Source Software is included,
 your rights for the respective Open Source Software
 components are governed by the applicable
 Open Source Software licenses.
-->

<template class="fill-height">
<v-card
      flat
      class="secondary fill-height pa-12"
      height="25%">
        <v-form
          ref="form"
          lazy-validation
        >
        <v-subheader class="display-1 grayBold--text">
            {{$t("addPhoto")}}
        </v-subheader>
        <v-list class="secondary card-content" max-height="80vh" max-width="1000px">
        <v-list-item>
                <v-list-item-content class="grayBold--text">
                <v-list-item-title class="subtitle-2 grayBold--text">

                </v-list-item-title>
                <v-flex xs12 sm12 md12>
                    <v-file-input
                    @change="PreviewPhoto"
                    v-model="photo"
                    accept="image/jpeg, image/jpg, image/png"
                    :placeholder="$t('addPhotoPlaceHolder')"
                    prepend-icon="mdi-camera"
                    :label="$t('photo')"
                    light
                    :rules="rules"
                    :loading="isUploading"
                    :disabled="disabled"
                    ></v-file-input>
                </v-flex>
                <v-flex xs12 sm12 md12>
                    <v-textarea
                      light
                      v-model="notes"
                      :label="$t('photoNotes')"
                      auto-grow
                      append-icon="mdi-text-box-edit-outline"
                      rows="1"
                      :counter="50"
                      :rules="notesRule"
                    ></v-textarea>
                  </v-flex>
            <v-container fluid class="d-flex algin-center justify-center">
               <div style="width: 500px;">
                <v-img aspect-ratio="4.5" contain v-if="url" fill-height :src="url"></v-img>
            </div>
            </v-container>
            </v-list-item-content>
        </v-list-item>
        </v-list>
        <v-card-actions>
        <v-spacer/>
        <v-btn
            depressed
            right
            light
            color="grayBold--text secondary"
            @click="hideModal()"
        >
            {{$t("cancel")}} 
        </v-btn>
        <v-btn
            color="primary"
            depressed
            right
            large
            class="modal-update"
            @click="doAddInstallationPhoto"
            :loading="isUploading"
            ><v-icon left>mdi-plus</v-icon>{{$t("add")}}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator'
import { commonModule, installationsModule, partnersModule } from '@/store/modules/store-accessor'

@Component
export default class AddPhotoToInstallationModal extends Vue {
    @Prop()
    payload;
    tab;

    photo = null;
    url = null;
    notes = '';

    isUploading = false;
    disabled = false;

    async hideModal() {
        commonModule.hideModal();
    }

    PreviewPhoto() {
       if(this.photo !== undefined && this.photo !== null) {
        this.url = URL.createObjectURL(this.photo);
       } else {
        // Clear photo preview
        this.photo = null;
        this.url = '';
       }
    }

    rules = [
        value => !!value || this.$t('photoRequired'),
    ]
    notesRule= [
        (val) => val.length <= 50 || this.$i18n.t("photoNotesRule"),
    ]

    async mounted() {
        this.tab = this.payload && this.payload.tab ? this.payload.tab : 'photos'
    }

    async doAddInstallationPhoto() {
        if (this.$refs.form.validate()) {
            this.isUploading = true;
            this.disabled = true;

            let formData = new FormData();
            formData.append("image", this.photo);
            formData.append("notes", this.notes);

            switch(this.tab) {
                case 'photos':
                    await installationsModule.addInstallationPhoto({installationId: this.payload.installation.info.id, photo: formData});
                    await installationsModule.getInstallationInfo(this.payload.installation.info.id);
                    break;
                case 'jobs':
                    formData.append("job_id", this.payload.installation.actions[0].job_id);
                    await installationsModule.addInstallationPhoto({installationId: this.payload.installation.installation_id, photo: formData});
                    if(this.$route.name === 'My Company' && partnersModule.currentPartner.info.partner_id) {
                        partnersModule.getPartnersInfo(partnersModule.currentPartner.info.partner_id);
                    } else {
                        await installationsModule.getInstallationInfo(this.payload.installation.installation_id);
                    }
                    break;
            }

            this.hideModal();
        }
    }
};
</script>
<style scoped lang="scss">
.card-content {
  overflow: auto;
}
</style>