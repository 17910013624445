import {
  VuexModule,
  Module,
  Action,
  Mutation,
} from "vuex-module-decorators";
import StatsApi from "@/api/stats-api";
import { formatDate } from "../../helpers/data";

@Module({ namespaced: true, name: "stats" })
export default class StatsModule extends VuexModule {
  public drawerState = false as boolean;
  public isLoadingStats = true;
  public isLoadingLastMonths = true;
  public isLoadingActionStats = true;
  public isLoadingTodaysAppointmentsTable = true;
  public filters = "";

  public stats: StatsDto =
  {} as StatsDto;
  public lastMonths: StatsLastMonthsDto =
  {} as StatsLastMonthsDto;
  public statsActions: StatsActionsDto =
  {} as StatsActionsDto;
  public statsTasks: any = {};

@Action({ commit: "setStats" })
async getStatsAction(query: string): Promise<StatsDto> {

  const statsApi = new StatsApi();
  const stats = await statsApi.getStats(query);

  return stats;
}

@Action({ commit: "setStatsLastMonths" })
async getStatsLastMonthsAction(): Promise<StatsLastMonthsDto> {

  const statsApi = new StatsApi();
  const lastMonths = await statsApi.getStatsLastMonths();

  return lastMonths;
}

@Action({ commit: "setStatsActions" })
async getStatsActionsAction(query: string): Promise<StatsActionsDto> {

  const statsApi = new StatsApi();
  const statsActions = await statsApi.getStatsActions(query);

  return statsActions;
}

@Action
changeDrawerState() {
  this.setNewDrawerState(!this.drawerState);
}

@Action({ commit: "setStatsTasks" })
async getStatsTasksAction(): Promise<any> {

  const statsApi = new StatsApi();
  const stats = await statsApi.getStatsTasks();

  return stats;
}

@Mutation
setNewDrawerState(newState: boolean) {
  this.drawerState = newState;
}

@Mutation
public setStats(stats: StatsDto): void {
  try {
    for (let i = 0; i < stats.todays_appointments.length; i++) {
      stats.todays_appointments[i].created_at = formatDate(
        stats.todays_appointments[i].created_at
      );
      stats.todays_appointments[i].updated_at = formatDate(
        stats.todays_appointments[i].updated_at
      );
      stats.todays_appointments[i].scheduled_end = formatDate(
        stats.todays_appointments[i].scheduled_end
      );
      stats.todays_appointments[i].scheduled_start = formatDate(
        stats.todays_appointments[i].scheduled_start
      );
    }

    this.stats = stats;
    this.isLoadingStats = false;
    this.isLoadingTodaysAppointmentsTable = false;
  } catch (error) {
    this.isLoadingStats = false;
    this.isLoadingTodaysAppointmentsTable = false;
  }
}

  @Mutation
  public setStatsLastMonths(lastMonths: StatsLastMonthsDto): void {
    this.lastMonths = lastMonths;
  }

  @Mutation
  public setStatsActions(statsActions: StatsActionsDto): void {
    this.statsActions = statsActions;
  }

  @Mutation
  public SetisLoadingLastMonths(isLoadingLastMonths: boolean): void {
    this.isLoadingLastMonths = isLoadingLastMonths
  }

  @Mutation
  public SetisLoadingActionStats(isLoadingActionStats: boolean): void {
    this.isLoadingActionStats = isLoadingActionStats
  }

  @Mutation
  public setIsLoadingTodayAppointmentsTable(isLoadingTodaysAppointmentsTable: boolean): void {
    this.isLoadingTodaysAppointmentsTable = isLoadingTodaysAppointmentsTable;
  }

  @Mutation
  public setFilters(filters: string): void {
    this.filters = filters
  }

  @Mutation
  public setStatsTasks(statsTasks: any): void {
    this.statsTasks = statsTasks;
  }
}
