
import { Component, Vue, Prop } from 'vue-property-decorator'
import { commonModule, paymentsModule } from '@/store/modules/store-accessor'

@Component
export default class DeletePaymentModal extends Vue {
  @Prop()
  payload!: any;

  isDeletingPayment = false;

  async hideModal () {
    commonModule.hideModal();
  }

  async mounted() {
    commonModule.initSnackbar({});
  }

  doDeletePayment () {
        this.isDeletingPayment = true;
        paymentsModule.deletePayment(this.payload.payment);
        this.hideModal();
  }
};
