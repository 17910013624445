import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators'
import ClientsApi from '@/api/clients-api'
import axios from 'axios'

@Module({ namespaced: true, name: 'clients' })
export default class ClientsModule extends VuexModule {
  public clients: ClientDto[] = [];
  public currentClient: ClientDetailsDto = {} as ClientDetailsDto;
  public clientsCount = 0;
  public isLoadingSideView = true;
  public isLoadingTable = true;
  public filters = '';
  public queryObject: any = {};
  public search = '';

  @Action({ commit: 'setClients'})
  async getClientsAction (query: string): Promise<ClientDto[]> {
    const clientsApi = new ClientsApi()
    this.setIsLoadingTable(true);
    const clients = await clientsApi.getAllClients(query)
    this.setIsLoadingTable(false);
    return clients
  }

  @Action({ commit: 'setClientsCount'})
  async getClientsCount (): Promise<CountDto[]> {

    const clientsApi = new ClientsApi()
    const clientsCount = await clientsApi.getClientCount();
    return clientsCount
  }

  @Action({ commit: 'setCurrentClient'})
  async getClientsInfo (currentClientId: string): Promise<ClientDetailsDto> {

    const clientsApi = new ClientsApi()
    const clientDetails = await clientsApi.getClient(currentClientId)
    return clientDetails
  }

  @Action
  async getClientInstallations (currentClientId: string): Promise<any> {
    const clientsApi = new ClientsApi()
    const clientInstallations = await clientsApi.getClientInstallations(currentClientId)
    return clientInstallations
  }

  @Action
  async addClient(data: Record<string, any>) {

    const clientsApi = new ClientsApi();
    const returning_data = await clientsApi.createClient(data);
    await this.getClientsAction(this.filters);

    return returning_data;
  }

  @Action
  async deleteClient(data: Record<string, any>) {

    const clientsApi = new ClientsApi()
    await clientsApi.deleteClient(data.id)
    await this.getClientsAction(this.filters);
  }

  @Action
  async editClient(data: Record<string, any>) {
    const clientsApi = new ClientsApi()
    await clientsApi.updateClient(data.id, data)
    if (data.view == "clients") {
      await this.getClientsAction(this.filters);
    } else {
      await this.getClientsInfo(data.id);
      await this.getClientsAction(this.filters);
    }
  }

  @Mutation
  public setClients (clients: ClientDto[]): void {
    try {
      clients.map(item => {
        item.fullname = `${item.lastname} ${item.firstname}`
      })
      clients.map( item => {
        item.contacts_length = item.contacts.length;
        item.contacts = item.contacts.map((item: any) => {return `${item.phone}${item.description ? ` - ${item.description}` : ''}`;}).join(', ');
      })
      this.clients = clients
      this.isLoadingTable = false;
    } catch (error) {
      this.isLoadingTable = false;
    }
  }

  @Mutation
  public clearClients() :void {
    this.clients = [];
  }

  @Mutation
  public setClientsCount (clientsCount: CountDto): void { 
    this.clientsCount = clientsCount.count;
  }

  @Mutation
  public unsetCurrentClient(): void {
    this.currentClient = {} as ClientDetailsDto;
  }

  @Mutation
  public setCurrentClient (client: ClientDetailsDto): void {
    try {
      this.currentClient = client;
      this.isLoadingTable = false;
      this.isLoadingSideView = false;
    } catch (error) {
      this.isLoadingSideView = false;
      this.isLoadingSideView = false;
    }
  }

  @Mutation
  public setIsLoadingSideView(isLoadingSideView: boolean): void {
    this.isLoadingSideView = isLoadingSideView
  }

  @Mutation
  public setIsLoadingTable(isLoadingTable: boolean): void {
    this.isLoadingTable = isLoadingTable
  }

  @Mutation
  public setFilters(filters: any): void {
    this.filters = filters;
  }

  @Mutation
  public setQueryObject(queryObject: any): void {
    this.queryObject = queryObject
  }

  @Mutation
  public setSearch(search: string): void {
    this.search = search
  }

}