
import { filterModule, installationsModule } from "@/store/modules/store-accessor";
import Vue from "vue";
import Component from "vue-class-component";
import FilterList from "./FilterList.vue";

@Component({
  components: {
    FilterList,
  },
})
export default class InstallationFilter extends Vue {
get installationFilters() {
   return filterModule.installationFilters;
}

premiumFilters() {
  let filters: any = (filterModule.installationFilters as any).is_premium ? [(filterModule.installationFilters as any).is_premium] : [];
  let options: any = [];

  for (let key in filters) { 
    let value = filters[key];
    for(let i = 0; i < value.length; i++) {
      options.push({text: this.$t(value[i].toString()), value: value[i]})
    }
  }

 return options;
}

  owesOptions = [{text: [this.$t('false')], value: ['false']}, {text: [this.$t('true')], value: ['true']}];

  selectedAreas: string[];
  selectedMakes: string[];
  selectedModels: string[];
  selectedIsPremium: string;
  selectedOwes: string;
  selectedItemTypes: string[];

  updateAreas(data: string[]) {
    this.selectedAreas = data;
    this.submit();
  }

  updateMakes(data: string[]) {
    this.selectedMakes = data;
    this.submit();
  }

   updateModels(data: string[]) {
    this.selectedModels = data;
    this.submit();
  }

  updateIsPremium(data: any) {
    if (data != undefined) {
      this.selectedIsPremium = data.value.toString();
    } else {
      this.selectedIsPremium = '';
    }

    this.submit();
  }

  updateOwes(data: any) {
    if (data != undefined) {
      this.selectedOwes = data.value.toString();
    } else {
      this.selectedOwes = ''; // clear filter
    }
    this.submit();
  }

  updateItemTypes(data: string[]) {
    this.selectedItemTypes = data;
    this.submit();
  }

  async submit() {
    installationsModule.setIsLoadingTable(true);
    let queryObj: any = {};

    if (this.selectedAreas != undefined && this.selectedAreas.length > 0) {
      queryObj["area[]"] = this.selectedAreas;
    }

    if (this.selectedMakes != undefined && this.selectedMakes.length > 0) {
      queryObj["make[]"] = this.selectedMakes;
    }

    if (this.selectedModels != undefined && this.selectedModels.length > 0) {
      queryObj["model[]"] = this.selectedModels;
    }

    if (this.selectedIsPremium != undefined && this.selectedIsPremium.length > 0) {
      queryObj["is_premium[]"] = this.selectedIsPremium;
    }

    if (this.selectedOwes != undefined && this.selectedOwes.length > 0) {
      queryObj["owes"] = this.selectedOwes;
    }

    if (installationsModule.search != '') {
      installationsModule.setSearch(installationsModule.search);
      queryObj["search"] = installationsModule.search;
    }

    if (this.selectedItemTypes != undefined && this.selectedItemTypes.length > 0) {
      queryObj['itemTypes[]'] = this.selectedItemTypes;
    }

    this.$router.replace({ query: queryObj }).catch(err => {/* */ });

    // Check if user selected any of the queries
    const queryIndex = this.$route.fullPath.indexOf('?');
    if(queryIndex >= 0) {
      const query = this.$route.fullPath.substring(queryIndex);
      installationsModule.setQueryObject(queryObj);
      installationsModule.setFilters(query);
      installationsModule.setQueryObject(queryObj);
    } else {
      installationsModule.setFilters("");
      installationsModule.setQueryObject(queryObj); // Will be empty ({})
    }
    await installationsModule.getInstallationsAction(installationsModule.filters)
    installationsModule.setIsLoadingTable(false);
  }
}
