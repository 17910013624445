
    import { sortDateRange } from "@/helpers/data";
    import { companyModule, filterModule, itemsModule, tasksModule } from "@/store/modules/store-accessor";
    import Vue from "vue";
    import { Component } from "vue-property-decorator";
    import FilterList from "./FilterList.vue";
    import DatePickerFilter from "./DatePickerFilter.vue";
    import dayjs from "dayjs";
    
    @Component({
      components: {
        FilterList,
        DatePickerFilter,
      },
    })
    export default class TasksFilter extends Vue {

      get tasksFilters() {
        return filterModule.tasksFilters;
      }
    
      async mounted() {
        filterModule.getTasksFilters();
        itemsModule.getAllCompanyPrimaryInventoryItems();
      }

      selectedUsers: string[];
      selectedActionTypes: string[];
      selectedDates: any = [dayjs().startOf('day').toISOString(), dayjs().endOf('day').toISOString()] // Set default date range to "today"
      selectedStatuses: any[];
      selectedAreas: string[];
      selectedItems: string[];
      selectedHasInstallationOption: string;

      get taskFiltersUsers() {
        const users = (this.tasksFilters as any)?.users;
        if (!users) {
          return [];
        }

        return users.map((obj: any) => ({...obj, icon: obj.is_partner ? 'mdi-account-multiple' : 'mdi-account'}));
      }

      get assigneeDropdownLabel() {
        if(companyModule.company?.modules?.includes('DFB_PORTAL_PARTNERS')) {
          return 'userPartner';
        }
        else {
          return 'user';
        }
      }

      get allCompanyPrimaryInventoryItems() {
        return itemsModule.allCompanyPrimaryItems;
      }

      hasInstallationsOptions = [{text: [this.$t('false')], value: ['false']}, {text: [this.$t('true')], value: ['true']}];
    
      updateUsers(data: string[]) {
        this.selectedUsers = data;
        this.submit();
      }

      updatActionTypes(data: string[]) {
        this.selectedActionTypes = data;
        this.submit();
      }

      updateDates(data: any[]) {
        this.selectedDates = data;
        if(!this.selectedDates || this.selectedDates.length == 2) {
          this.submit();
        }
      }
    
      updateActionTypes(data: string[]) {
        this.selectedActionTypes = data;
        this.submit();
      }

      updateStatus(data: any[]) {
        this.selectedStatuses = data;
        this.submit();
      }

      updateItems(data: string[]) {
        this.selectedItems = data;
        this.submit();
      }

      updateHasInstallation(data: any) {
        if (data) {
          this.selectedHasInstallationOption = data.value.toString();
        } else {
          this.selectedHasInstallationOption = ''; // clear filter
        }
        this.submit();
      }
      
      async submit() {
        let queryObj: any = {};
        tasksModule.setIsLoadingTable(true);
        let userIds = [];
        let tempId;
        let actionsIds: any = [];
        let actionsTempId;
        let statusTempId;
        let statusIds = [];
        let itemsIds = [];
    
        if (this.selectedUsers != undefined) {
          for (let i = 0; i < this.selectedUsers.length; i++) {
            tempId = (this.selectedUsers[i] as any).id
            userIds.push(tempId);
          }
        }
    
        if (userIds != undefined && userIds.length > 0) {
          queryObj["assignee_id[]"] = userIds;
        }

      if (this.selectedActionTypes != undefined) {
        for (let i = 0; i < this.selectedActionTypes.length; i++) {
          actionsTempId = (this.selectedActionTypes[i] as any).id
          actionsIds.push(actionsTempId);
        }
      }

      if (actionsIds != undefined && actionsIds.length > 0) {
        queryObj["actions[]"] = actionsIds;
      }

      if (this.selectedStatuses != undefined) {
        for (let i = 0; i < this.selectedStatuses.length; i++) {
          statusTempId = (this.selectedStatuses[i] as any).id
          statusIds.push(statusTempId);
        }
      }

      if (statusIds != undefined && statusIds.length > 0) {
        queryObj["taskStatus[]"] = statusIds;
      }

      if (this.selectedDates != null && this.selectedDates.length === 2) {
        sortDateRange(this.selectedDates);

        queryObj["date_from"] = dayjs(this.selectedDates[0]).startOf('day').toISOString();
        queryObj["date_to"] = dayjs(this.selectedDates[1]).endOf('day').toISOString();
      }

      if (tasksModule.search != '') {
        tasksModule.setSearch(tasksModule.search);
        queryObj["search"] = tasksModule.search;
      }

      if (this.selectedItems != undefined) {
        for (let i = 0; i < this.selectedItems.length; i++) {
        const selectedItem = this.selectedItems[i] as any;
        if (Array.isArray(selectedItem.id)) {
          itemsIds.push(...selectedItem.id);
        } else {
          itemsIds.push(selectedItem.id);
        }
      }
    }
    if (itemsIds != undefined && itemsIds.length > 0) {
      queryObj["items[]"] = itemsIds;
    }

    if (this.selectedHasInstallationOption != undefined && this.selectedHasInstallationOption.length > 0) {
      queryObj["hasInstallationId"] = this.selectedHasInstallationOption;
    }
    
        this.$router.replace({ query: queryObj }).catch(err => {/* */ });
    
        // Check if user selected any of the queries
        const queryIndex = this.$route.fullPath.indexOf('?');
        if (queryIndex >= 0) {
          tasksModule.setQueryObject(queryObj);
          const query = this.$route.fullPath.substring(queryIndex);
          tasksModule.setFilters(query);
          tasksModule.setQueryObject(queryObj);
        } else {
          tasksModule.setFilters('');
          tasksModule.setQueryObject(queryObj); // Will be empty ({})
        }
        await tasksModule.getTasks(tasksModule.filters)
        tasksModule.setIsLoadingTable(false);
        }
    }
    