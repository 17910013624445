
  import { sortDateRange } from "@/helpers/data";
  import { filterModule, appointmentsModule, itemsModule } from "@/store/modules/store-accessor";
  import Vue from "vue";
  import { Component } from "vue-property-decorator";
  import FilterList from "./FilterList.vue";
  import DatePickerFilter from "./DatePickerFilter.vue";
  import dayjs from "dayjs";
  
  @Component({
    components: {
      FilterList,
      DatePickerFilter
    },
  })
  export default class AppointmentsFilter extends Vue {
  
    get appointmentsFilters() {
      return filterModule.appointmentsFilters;
    }

    get allCompanyPrimaryInventoryItems() {
      return itemsModule.allCompanyPrimaryItems;
    }
  
    get appointmentsFiltersUsers() {
      return (filterModule.appointmentsFilters.users)?.map((obj: any) => ({...obj, icon: obj.is_partner ? 'mdi-account-multiple' : 'mdi-account'}));
    }

    async mounted() {
      filterModule.getAppointmentsFilters();
      itemsModule.getAllCompanyPrimaryInventoryItems();

    }
  
    selectedUsers: string[];
    selectedActionTypes: string[];
    selectedDates: any = [dayjs().startOf('day').toISOString(), dayjs().endOf('day').toISOString()]; // Set default date range to "today"
    selectedStatuses: any[];
    selectedAreas: string[];
    selectedItems: string[];
  
    updateUsers(data: string[]) {
      this.selectedUsers = data;
      this.submit();
    }
  
    updatActionTypes(data: string[]) {
      this.selectedActionTypes = data;
      this.submit();
    }
  
    updateDates(data: any[]) {
      this.selectedDates = data;
      if(!this.selectedDates || this.selectedDates.length == 2) {
      this.submit();
      }
    }

    updateStatus(data: any[]) {
      this.selectedStatuses = data;
      this.submit();
    }

    updateAreas(data: string[]) {
      this.selectedAreas = data;
      this.submit();
    }

    updateItems(data: string[]) {
      this.selectedItems = data;
      this.submit();
    }
    
    async submit() {
      appointmentsModule.setIsLoadingTable(true);
      let userIds = [];
      let tempId;
      let queryObj: any = {};
  
      let actionsTempId;
      let actionsIds = [];

      let statusTempId;
      let statusIds = [];

      let itemTempId;
      let itemsIds = [];
  
      if (this.selectedUsers != undefined) {
        for (let i = 0; i < this.selectedUsers.length; i++) {
          tempId = (this.selectedUsers[i] as any).id
          userIds.push(tempId);
        }
      }
  
      if (userIds != undefined && userIds.length > 0) {
        queryObj["updated_by[]"] = userIds;
      }

      if (userIds != undefined && userIds.length > 0) {
        queryObj["updated_by[]"] = userIds;
      }
  
      if (this.selectedActionTypes != undefined) {
        for (let i = 0; i < this.selectedActionTypes.length; i++) {
          actionsTempId = (this.selectedActionTypes[i] as any).id
          actionsIds.push(actionsTempId);
        }
      }
  
      if (actionsIds != undefined && actionsIds.length > 0) {
        queryObj["actions[]"] = actionsIds;
      }

      if (this.selectedItems != undefined) {
        for (let i = 0; i < this.selectedItems.length; i++) {
          const selectedItem = this.selectedItems[i] as any;
          if (Array.isArray(selectedItem.id)) {
            itemsIds.push(...selectedItem.id);
          } else {
            itemsIds.push(selectedItem.id);
          }
        }
      }
  
      if (itemsIds != undefined && itemsIds.length > 0) {
        queryObj["items[]"] = itemsIds;
      }

      if (this.selectedItems != undefined) {
        for (let i = 0; i < this.selectedItems.length; i++) {
          const selectedItem = this.selectedItems[i] as any;
          if (Array.isArray(selectedItem.id)) {
            itemsIds.push(...selectedItem.id);
          } else {
            itemsIds.push(selectedItem.id);
          }
        }
      }

      if (this.selectedStatuses != undefined) {
        for (let i = 0; i < this.selectedStatuses.length; i++) {
          statusTempId = (this.selectedStatuses[i] as any).id
          statusIds.push(statusTempId);
        }
      }

      if (statusIds != undefined && statusIds.length > 0) {
        queryObj["appointmentStatus[]"] = statusIds;
      }

      if (this.selectedDates != null && this.selectedDates.length === 2) {
        sortDateRange(this.selectedDates);

        queryObj["date_from"] = dayjs(this.selectedDates[0]).startOf('day').toISOString();
        queryObj["date_to"] = dayjs(this.selectedDates[1]).endOf('day').toISOString();
      }

      if(this.selectedAreas != undefined && this.selectedAreas.length > 0) {
        queryObj['areas[]'] = this.selectedAreas;
      }

      if (appointmentsModule.search != '') {
        appointmentsModule.setSearch(appointmentsModule.search);
        queryObj["search"] = appointmentsModule.search;
      }

      this.$router.replace({ query: queryObj }).catch(err => {/* */ });
  
      // Check if user selected any of the queries
      const queryIndex = this.$route.fullPath.indexOf('?');
      if (queryIndex >= 0) {
        const query = this.$route.fullPath.substring(queryIndex);
        appointmentsModule.setAppointmentsFilter(query);
        appointmentsModule.setQueryObject(queryObj);
      } else {
        appointmentsModule.setAppointmentsFilter('');
        appointmentsModule.setQueryObject(queryObj); // Will be empty ({})
      }
  
        await appointmentsModule.getAppointmentsAction(appointmentsModule.appointmentsFilters);
        appointmentsModule.setIsLoadingTable(false);
      }
  }
  