
import { Component, Vue, Prop } from 'vue-property-decorator'
import { commonModule, jobsModule } from '@/store/modules/store-accessor'

@Component
export default class DeleteJobModal extends Vue {
  @Prop()
  payload!: any;

  isDeletingJob = false;

  async hideModal () {
    commonModule.hideModal();
  }

  async mounted() {
    commonModule.initSnackbar({});
  }

  async doDeleteJob () {
    this.isDeletingJob = true;
    await jobsModule.deleteJob(this.payload.job);
    await jobsModule.getJobs(jobsModule.filters);
    this.hideModal();
  }
};
