
import { Component, Vue, Prop } from 'vue-property-decorator'
import { commonModule, installationsModule } from '@/store/modules/store-accessor'

@Component
export default class DeleteInstallationItemModal extends Vue {
  @Prop()
  payload!: any;

  isDeletingItem = false;

  async hideModal () {
    commonModule.hideModal();
  }

  async mounted() {
    commonModule.initSnackbar({});
  }

  doDelete () {
    this.isDeletingItem = true;
    installationsModule.deleteInstallationItem(this.payload.installation_item);
    this.hideModal()
  }
};
