
  import { Component, Vue, Prop } from 'vue-property-decorator'
  import { commonModule, itemTypesModule } from '@/store/modules/store-accessor'
  
  @Component
  export default class DeleteItemTypeModal extends Vue {
    @Prop()
    payload!: any;

    deletingItemType = false;
  
    async hideModal () {
      commonModule.hideModal();
    }
  
    async mounted() {
      commonModule.initSnackbar({});
    }
  
    async doDelete () {
        this.deletingItemType = true;
        await itemTypesModule.deleteActionType(this.payload.itemType);
        await itemTypesModule.getItemTypes();

        this.hideModal()
    }
  };
