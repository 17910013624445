import { BaseApi } from '@/helpers/base-api'

export default class ActionsApi extends BaseApi {
  constructor () {
    super('/api/v1/actions')
    this.errorMessage = 'There was an issue when trying to fetch the actions. If this error persists, please contact our support.'
  }

  public getAllActions = (): Promise<ActionDto[]> => this.instance.get('')
  
  public createAction = (data: Record<string, unknown>): Promise<JSON> => this.instance.post('', data)

  public updateAction = (actionId: string, data: Record<string, unknown>): Promise<JSON> => this.instance.patch(`/${actionId}`, data)

  public deleteAction = (actionId: string): Promise<JSON> => this.instance.delete(`/${actionId}`)

}
