
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'

@Component
export default class DropdownItem extends Vue {
  @Prop()
  title!: string;

  @Prop({ default: false }) disabled!: boolean
  @Prop({ default: true }) tooltipDisabled!: boolean
  @Prop({ default: ""}) tooltipText!: string
  @Prop({ default: '' }) icon!: boolean

  get titleTranslated() {
      return this.$t(this.title)
  }

  clickAction () {
    this.$emit('clicked')
  }
}
