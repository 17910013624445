
import { Component, Vue, Prop } from 'vue-property-decorator'
import InstallationsSideview from '@/components/sideviews/InstallationsSideview.vue'
import ClientsSideview from '@/components/sideviews/ClientsSideview.vue'
import PartnersSideview from '@/components/sideviews/PartnersSideview.vue'

import { commonModule } from '@/store/modules/store-accessor'

@Component({
  components: {
    InstallationsSideview,
    ClientsSideview,
    PartnersSideview,
  }
})
export default class TheSideview extends Vue {
  get sideviewName () {
    return commonModule.sideview.name
  }

  get sideviewPayload () {
    return commonModule.sideview.payload
  }

  closeSideview() {
    commonModule.hideSideview();
  }

};
