import { render, staticRenderFns } from "./DeleteTaskModal.vue?vue&type=template&id=0b28f7b8&scoped=true&class=fill-height&"
import script from "./DeleteTaskModal.vue?vue&type=script&lang=ts&"
export * from "./DeleteTaskModal.vue?vue&type=script&lang=ts&"
import style0 from "./DeleteTaskModal.vue?vue&type=style&index=0&id=0b28f7b8&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b28f7b8",
  null
  
)

export default component.exports