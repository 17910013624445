
  import { Component, Vue, Prop } from 'vue-property-decorator'
  import { TimeUtil } from '@/helpers/TimeUtil'
  
  @Component
  export default class PartnersSideviewGeneralTab extends Vue {
   @Prop() partner: Record<string, any>;
  
  
    formatDate (date: Date): string {
      return TimeUtil.formatDateTime(date)
    }
  
  };
  