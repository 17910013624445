
  import { Component, Vue, Prop } from 'vue-property-decorator'
  import { commonModule, installationsModule, partnersModule } from '@/store/modules/store-accessor'
  import { TimeUtil } from '@/helpers/TimeUtil';
  import FileSaver from 'file-saver';
  import dayjs from 'dayjs';

  @Component
  export default class JobPhotosModal extends Vue {
      @Prop()
      payload!: any;

      isDowloadingPhoto = false;

      async hideModal() {
          commonModule.hideModal();
      }

      async mounted() {
          commonModule.initSnackbar({});
      }

      get photos() {
        let moduleToUse = {} as any;
          if (Object.keys(partnersModule.currentPartner).length > 0) {
              moduleToUse = partnersModule.currentPartner;
          } else {
              moduleToUse = installationsModule.currentInstallation;
          }

          const jobIndex = (moduleToUse as any).jobs.findIndex((obj: any) => obj.id === this.payload.job.id);
          if ((moduleToUse as any).jobs[jobIndex].images_src) {
              return ((moduleToUse as any).jobs[jobIndex].images_src as any).map((images_src: any, index: any) => {
                  return {
                      id: (moduleToUse.jobs[jobIndex] as any).image_id[index],
                      src: images_src,
                      base_url: (moduleToUse.jobs[jobIndex] as any).base_url[index],
                      notes: (moduleToUse.jobs[jobIndex] as any).image_notes[index],
                      installation_id: (moduleToUse.jobs[jobIndex] as any).installation_id,
                  }
              })
          }

        // if this point is reached, it will be because user deleted all of their photos
        this.hideModal();
        return [];
      }

      photoPath(photo: any) {
          return `${photo.base_url}/${photo.src}`
      }

      async deletePhoto(photo: any) {
          commonModule.showSecondModal({ name: 'delete-job-photo-modal', payload: { photo: photo } })
      }

      async downloadPhoto(photo: Record<string, any>) {
          const installationTagId = this.$route.name === 'My Company' ? this.payload.job.installation_tag_id : installationsModule.currentInstallation.info.tag_id
          this.isDowloadingPhoto = true;
          let downloadPhoto = await installationsModule.getInstallationPhoto({ installationId: photo.installation_id, photoId: photo.id });
          const photoName = `${installationTagId}_${dayjs(photo.created_at).format('DD_MM_YYYY_HH_mm_ss')}.jpeg`;

          downloadPhoto = new File([downloadPhoto], photoName);
          FileSaver.saveAs(downloadPhoto);

          this.isDowloadingPhoto = false;
      }

      editPhoto(photo: any) {
        commonModule.showSecondModal({ name: 'edit-job-photo-modal', payload: { photo: photo } });
      }

      formatDate(date: Date): string {
          return TimeUtil.formatDateTime(date)
      }

      doClose () {
          this.hideModal()
      }
  };
  