
  import { commonModule, companyModule } from '@/store/modules/store-accessor'
  import Vue from "vue";
  import { Component, Prop } from "vue-property-decorator";
  
  @Component
  export default class AssigneeCombo extends Vue {
    selectedAssignee = null as any;
    @Prop() defaultAssignee!: any; // for edit modals & add appointment in the calendar page
    @Prop({ default: true }) rightMargin!: boolean; // will set it to false in the add job modal
    @Prop({ default: true }) assigneeRequired!: boolean; // will set it to false in the add job modal

    loading = true;

    selectEntry() {
      this.$emit('update-filter', this.selectedAssignee);
    }

    async mounted() {
      if(companyModule.company?.modules?.includes('DFB_PORTAL_PARTNERS')) {
        await commonModule.getPersonnelPartnersCombo(); 
      }
      else {
        await commonModule.getPersonnelCombo();
      }

      if(this.defaultAssignee) {
        const foundItem = this.assigneeIdCombo.find((item: any) => item.name === this.defaultAssignee.name);
        if(!foundItem) {
          this.defaultAssignee.id ? this.assigneeIdCombo.push({name: this.defaultAssignee.name, id: this.defaultAssignee.id, disabled: true}) :
            this.assigneeIdCombo.push({name: this.defaultAssignee.name, disabled: true})
        }

        this.selectedAssignee = this.defaultAssignee;
        this.$emit('update-filter', this.selectedAssignee);
      }

      if(!this.defaultAssignee) {
        const combo = commonModule.personnelCombo;
        if(combo.length === 1) {
          this.defaultAssignee = { name: combo[0].name, id: combo[0].id};
          this.selectedAssignee = this.defaultAssignee;
          this.$emit('update-filter', this.selectedAssignee);
        }
      }
      this.loading = false;
    }

    get assigneeDropdownLabel() {
      if(companyModule.company?.modules?.includes('DFB_PORTAL_PARTNERS')) {
        return this.$i18n.t('userPartner');
      }
      else {
        return this.$i18n.t('user');
      }
    }

    get assigneeIdCombo() {
      if(companyModule.company?.modules?.includes('DFB_PORTAL_PARTNERS')) {
        return commonModule.personnelPartnersCombo;
      }
      else {
        return commonModule.personnelCombo;
      }
    }

    entryIcon(is_partner: boolean) {
      if(is_partner !== undefined) {
        return is_partner ? 'mdi-account-multiple' : 'mdi-account'
      }
      else {
        return 'mdi-account-off-outline'
      }
    }

    rules = [
      (val: any) => !!val || this.$i18n.t("userPartnerRequired"),
    ]

    setAssigneeRules() {
      return this.assigneeRequired ? this.rules : [];
    }

  }
