import { BaseApi } from '@/helpers/base-api'

export default class ActionsApi extends BaseApi {
  constructor () {
    super('/api/v1/materials')
    this.errorMessage = 'There was an issue when trying to fetch the materials. If this error persists, please contact our support.'
  }

  public getAllMaterials = (): Promise<MaterialDto[]> => this.instance.get('')
  
  public createMaterial = (data: Record<string, unknown>): Promise<JSON> => this.instance.post('', data)

  public updateMaterial = (materialId: string, data: Record<string, unknown>): Promise<JSON> => this.instance.patch(`/${materialId}`, data)

  public deleteMaterial = (materialId: string): Promise<JSON> => this.instance.delete(`/${materialId}`)

}
