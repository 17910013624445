
import Vue from "vue";

export default Vue.extend({
  name: "App",
  data: function () {
    const lang = localStorage.getItem("lang") || "el";
    const calendar_type = localStorage.getItem("calendar_type") || "category";
    return { lang: lang, calendar_type: calendar_type };
  },
  methods: {
    handleChange(event: any) {
      localStorage.setItem("lang", event.target.value);
      window.location.reload();
    },
    handleCalendarTypeChange(event: any) {
      localStorage.setItem("calendar_type", event.target.value);
    },
  },
});
