
import { Component, Vue, Prop } from 'vue-property-decorator'
import { commonModule, installationsModule } from '@/store/modules/store-accessor'

@Component
export default class EditInstallationPhotoModal extends Vue {
      @Prop()
      payload!: any;
      photoUrl = `${this.payload.photo.base_url}${this.payload.photo.src}`;

      isUpdating = false;

      editPhotoModel = {
        photoId: this.payload.photo.id,
        notes : this.payload.photo.notes || '',
        installationId: this.payload.photo.installation_id
      }

      notesRule= [
        (val: any) => {
            if(val != null) return val.length <= 50 || this.$i18n.t("photoNotesRule");
            else return true;
        },
      ]

      async mounted() {
          commonModule.initSnackbar({});
      }

  
      async hideModal() {
          commonModule.hideModal();
      }

      async doEditiInstallationPhoto() {
        if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
            this.isUpdating = true;
            await installationsModule.editInstallationPhoto(this.editPhotoModel);
            await installationsModule.getInstallationInfo(this.payload.photo.installation_id);
            this.hideModal();
        }
    }
};
