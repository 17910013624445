
import { Component, Vue, Prop } from 'vue-property-decorator'
import { commonModule, expensesModule, jobsModule, installationsModule } from '@/store/modules/store-accessor'
import { isDecimalNumber, getOptimalTextColor } from '@/helpers/data';
import AssigneeCombo from '@/components/Combobox/AssigneeCombo.vue';
import _ from 'lodash';
import dayjs from 'dayjs';

@Component({
  components: {
    AssigneeCombo,
  },
})
export default class AddExpenseToJobModal extends Vue {
  @Prop()
  payload!: any;

  createdAtMenu = false;
  createAtTimeMenu = false;
  isAddingExpense = false;
  
  jobExpenseModel = {
    cost: '',
    notes: '',
    assignee_id: this.getAssigneeId(this.payload.job.partner_uuid ? this.payload.job.partner_uuid : commonModule.user.uuid),
    installation_id: this.payload.job.installation_id,
    id: this.payload.job.id,
    cost_disabled: this.payload.job.cost_disabled,
    payment_type: this.paymentTypesCombo[1],
    created_at_date: dayjs().format('YYYY-MM-DD'),
    created_at_time: dayjs().format('HH:mm'),
    partner_id: this.payload.job.partner_id || '',
  }

  fileModel = {
    add_file: false,
    file: null,
    url: null,
  }

  userRule= [
    (val: any) => !!val || this.$i18n.t("user_required"),
  ]
  notesRule = [
    (val: any) => {
      if(val != null) return val.length <= 30 || this.$i18n.t("notesLengthRule");
      else return true;
    },
  ]
  costRule:any = [
    (val: any) => val !== '' && val > 0 || this.$i18n.t("nonZeroCost")
  ]

  fileRules = [
    (val: any) => !!val || this.$t('fileRequired'),
    (val: any) => !val || val.size <= 5000000 || this.$i18n.t("fileInputSizeRule"), // 5mb rule
  ]

  paymentTypeRule = [
    (val: any) => (val || '').id > 0 || this.$i18n.t("payment_type_required"),
  ]

  get assigneeIdCombo () {
      return commonModule.personnelCombo;
  }

  get paymentTypesCombo () {
    for (let i = 0; i < commonModule.paymentTypeCombo.length; i++) {
      (commonModule.paymentTypeCombo[i]["payment_type"] as any) = this.$t(commonModule.paymentTypeCombo[i]["payment_type"]);
    }
    return commonModule.paymentTypeCombo;
  }

  async hideModal () {
    commonModule.hideModal();
  }

  async mounted() {
    commonModule.initSnackbar({});
    await commonModule.getPersonnelCombo();
    await commonModule.getPaymentTypeCombo();
  }

  updateAssignee(assignee: any) {
    if(assignee) {
      this.jobExpenseModel.assignee_id = assignee;
    }
  }

  optimalTextColor(color: string) {
    return getOptimalTextColor(color);
  }

  async doAddJobExpenses () {
    this.isAddingExpense = true;
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      const expenseId: any = await jobsModule.createExpensesToJob(this.jobExpenseModel);

        if (this.fileModel.add_file) {
          let formData = new FormData();
          formData.append("file", (this.fileModel.file as any));
          formData.append("expenseId", expenseId);
          await expensesModule.addExpenseFile({expenseId: expenseId, file: formData});
        }
        await installationsModule.getInstallationInfo(this.jobExpenseModel.installation_id);
        this.hideModal();
    }
    this.isAddingExpense = false;
  }

  onlyDecimalNumbers(evt: any) {
    isDecimalNumber(evt);
  }

  getAssigneeId(uuid: string) {
      const combo: any = commonModule.personnelPartnersCombo;
      const foundItem = combo.find((item: any) => item.id === uuid);
      if(foundItem) {
        return { name: foundItem.name, id: foundItem.id };
      } else {
        return ''
      }
    }

};
