
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import ApexChart from "vue-apexcharts";
import config from "@/config";
import { commonModule, companyModule } from '@/store/modules/store-accessor'
import DashboardTodaysAppointmentsTable from "../components/Tables/DashboardTodaysAppointmentsTable.vue";
import DashboardPendingTasksTable from "../components/Tables/DashboardPendingTasksTable.vue";
import DashboardCardBarChart from "../components/Cards/DashboardCardBarChart.vue";
import DashboardCardPieChart from "../components/Cards/DashboardCardPieChart.vue";
import { statsModule } from '@/store/modules/store-accessor'
import TheLoadingIndicator from '@/components/TheLoadingIndicator.vue'
import FileSaver from 'file-saver';
import dayjs from "dayjs";
import KeycloakSession from "@/helpers/keycloak-session";

@Component({
  components: {
    ApexChart,
    DashboardCardBarChart,
    DashboardCardPieChart,
    DashboardTodaysAppointmentsTable,
    TheLoadingIndicator,
    DashboardPendingTasksTable,
  },
})
export default class Dashboard extends Vue {

  @Prop()
  companyInfo: any
  isDowloandingBackUp = false;

  select = [
    this.$i18n.t("weekly"),
    this.$i18n.t("monthly"),
    this.$i18n.t("yearly"),
  ];
  actionsApexArea: any = {
    weekly_options: {
      chart: {
        width: "100%",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      markers: {
        size: [5],
      },
      stroke: {
        width: [3],
        curve: ["smooth"],
      },
      fill: {
        type: "linear",
      },
      legend: {
        show: false,
      },
      colors: [config.light.error],
      yaxis: {
        axisBorder: {
          show: true,
          color: "#B9B9B980",
        },
        labels: {
          formatter: function (val: any) {
            return val.toFixed(0);
          },
          style: {
            fontSize: "12px",
          },
        },
      },
      xaxis: {
        categories: [],
        labels: {
          style: {
            fontSize: "12px",
          },
        },
        tickAmount: 10,
      },
    },
    monthly_options: {
      chart: {
        width: "100%",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      markers: {
        size: [5],
      },
      stroke: {
        width: [3],
        curve: ["smooth"],
      },
      fill: {
        type: "linear",
      },
      legend: {
        show: false,
      },
      colors: [config.light.error],
      yaxis: {
        axisBorder: {
          show: true,
          color: "#B9B9B980",
        },
        labels: {
          formatter: function (val: any) {
            return val.toFixed(0);
          },
          style: {
            fontSize: "12px",
          },
        },
      },
      xaxis: {
        categories: [],
        labels: {
          style: {
            fontSize: "12px",
          },
        },
        tickAmount: 10,
      },
    },
    yearly_options: {
      chart: {
        width: "100%",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      markers: {
        size: [5],
      },
      stroke: {
        width: [3],
        curve: ["smooth"],
      },
      fill: {
        type: "linear",
      },
      legend: {
        show: false,
      },
      colors: [config.light.error],
      yaxis: {
        axisBorder: {
          show: true,
          color: "#B9B9B980",
        },
        labels: {
          formatter: function (val: any) {
            return val.toFixed(0);
          },
          style: {
            fontSize: "12px",
          },
        },
        tickAmount: 5,
      },
      xaxis: {
        categories: [],
        labels: {
          style: {
            fontSize: "12px",
          },
        },
        tickAmount: 10,
      },
    },
    weekly: [
      {
        name: this.$i18n.t("appointments"),
        type: "line",
        data: [],
      },
    ],
    monthly: [
      {
        name: this.$i18n.t("appointments"),
        type: "line",
        data: [],
      },
    ],
    yearly: [
      {
        name: this.$i18n.t("appointments"),
        type: "line",
        data: [],
      },
    ],
  };

  clientsBarChart: any = {
    options: {
      chart: {
        id: "clients",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
          distributed: true,
          endingShape: "rounded",
          startingShape: "rounded",
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      stroke: {
        width: 3,
        curve: ["smooth"],
      },
      colors: [config.light.primary],
      dataLabels: {
        enabled: false,
        dropShadow: {
          enable: false,
        },
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: [],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          formatter: function (val: any) {
            return val.toFixed(0);
          },
          show: false,
        },
      },
    },
    series: [
      {
        name: this.$i18n.t("new_clients"),
        data: [],
      },
    ],
  };
  installationsBarChart: any = {
    options: {
      chart: {
        id: "installations",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
          distributed: true,
          endingShape: "rounded",
          startingShape: "rounded",
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      stroke: {
        width: 3,
        curve: ["smooth"],
      },
      colors: [config.light.info],
      dataLabels: {
        enabled: false,
        dropShadow: {
          enable: false,
        },
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: [],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          formatter: function (val: any) {
            return val.toFixed(0);
          },
          show: false,
        },
      },
    },
    series: [
      {
        name: this.$i18n.t("new_installations"),
        data: [],
      },
    ],
  };
  jobsBarChart: any = {
    options: {
      chart: {
        id: "jobs",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
          distributed: true,
          endingShape: "rounded",
          startingShape: "rounded",
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      stroke: {
        width: 3,
        curve: ["smooth"],
      },
      colors: [config.light.error],
      dataLabels: {
        enabled: false,
        dropShadow: {
          enable: false,
        },
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: [],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          formatter: function (val: any) {
            return val.toFixed(0);
          },
          show: false,
        },
      },
    },
    series: [
      {
        name: this.$i18n.t("jobs"),
        data: [],
      },
    ],
  };

  actionsBreakdownPie: any = {
    options: {
      dataLabels: {
        enabled: true,
      },
      colors: [],
      labels: [],
      series: [],
      plotOptions: {
        pie: {
          customScale: 1.05,
        }
      },
      legend: {
        position: 'left',
        offsetY: 0
      }
    },
  };

  tasksBreakdownPie: any = {
    options: {
      colors: [],
      labels: [ this.$i18n.t("onTime"),  this.$i18n.t("overDue")],
      series: [],
      plotOptions: {
        pie: {
          customScale: 1.05,
        }
      },
      legend: {
        position: 'left',
        offsetY: 0
      },
    },
  };

  apexLoading = false;
  actionsSelect = this.$i18n.t("weekly");

  get stats(){
    return statsModule.stats
  }

  get lastMonths(){
    return statsModule.lastMonths
  }

  get statsActions(){
    return statsModule.statsActions as any
  }

  get isLoadingStats() {
    return statsModule.isLoadingStats;
  }

  get isLoadingActionStats() {
    return statsModule.isLoadingActionStats;
  }

  get isLoadingTodaysAppointmentTable() {
    return statsModule.isLoadingTodaysAppointmentsTable;
  }

  get isLoadingLastMonths() {
    return statsModule.isLoadingLastMonths;
  }

  get statsTasks() {
    return statsModule.statsTasks;
  }

  async mounted () {
    setTimeout(() => {
      this.apexLoading = true;
    });

    commonModule.initSnackbar({});

    /*
     TODO: Handle installations properly when UX is clear.
     */
    statsModule.getStatsAction('');
    await statsModule.getStatsLastMonthsAction();
    statsModule.SetisLoadingLastMonths(false);
    await statsModule.getStatsActionsAction('');
    statsModule.SetisLoadingActionStats(false);
    await statsModule.getStatsTasksAction();

    this.clientsBarChart.options.xaxis.categories = [];
    this.clientsBarChart.series[0].data = [];
    this.installationsBarChart.options.xaxis.categories = [];
    this.installationsBarChart.series[0].data = [];
    this.jobsBarChart.options.xaxis.categories = [];
    this.jobsBarChart.series[0].data = [];
    
    const clientsBarChartDates = [];
    for (let i = 0; i < this.lastMonths.clients_last_months.length; i++) {
      clientsBarChartDates.push(
        this.lastMonths.clients_last_months[i].date
      );
      this.clientsBarChart.series[0].data.push(
        this.lastMonths.clients_last_months[i].count
      );
    }
    this.clientsBarChart.options = {
      ...this.clientsBarChart.options,
      ...{
        xaxis: {
          categories: clientsBarChartDates,
        },
      },
    };

    const installationsBarChartDates = [];
    for (
      let i = 0;
      i < this.lastMonths.installations_last_months.length;
      i++
    ) {
      installationsBarChartDates.push(
        this.lastMonths.installations_last_months[i].date
      );
      this.installationsBarChart.series[0].data.push(
        this.lastMonths.installations_last_months[i].count
      );
    }
    this.installationsBarChart.options = {
      ...this.installationsBarChart.options,
      ...{
        xaxis: {
          categories: installationsBarChartDates,
        },
      },
    };

    const jobsBarChartDates = [];
    for (let i = 0; i < this.lastMonths.jobs_last_months.length; i++) {
      jobsBarChartDates.push(this.lastMonths.jobs_last_months[i].date);
      this.jobsBarChart.series[0].data.push(
        this.lastMonths.jobs_last_months[i].count
      );
    }
    this.jobsBarChart.options = {
      ...this.jobsBarChart.options,
      ...{
        xaxis: {
          categories: jobsBarChartDates,
        },
      },
    };
    
    let data_series = [];
    let data_labels = [];
    let data_colors = [];

    this.actionsApexArea.weekly_options.xaxis.categories = [];
    this.actionsApexArea.weekly[0].data = [];
    this.actionsApexArea.monthly_options.xaxis.categories = [];
    this.actionsApexArea.monthly[0].data = [];
    this.actionsApexArea.yearly_options.xaxis.categories = [];
    this.actionsApexArea.yearly[0].data = [];
    const weekly_dates = [];
    for (let i = 0; i < this.statsActions.actions_weekly.length; i++) {
      weekly_dates.push(this.statsActions.actions_weekly[i].date);
      this.actionsApexArea.weekly[0].data.push(
        this.statsActions.actions_weekly[i].count
      );
    }

    this.actionsApexArea.weekly_options = {
      ...this.actionsApexArea.weekly_options,
      ...{
        xaxis: {
          categories: weekly_dates,
        },
      },
    };

    for (let i = 0; i < this.statsActions.actions_monthly.length; i++) {
      this.actionsApexArea.monthly_options.xaxis.categories.push(
        this.statsActions.actions_monthly[i].date 
      );
      this.actionsApexArea.monthly[0].data.push(
        this.statsActions.actions_monthly[i].count
      );
    }
    for (let i = 0; i < this.statsActions.actions_yearly.length; i++) {
      this.actionsApexArea.yearly_options.xaxis.categories.push(
        this.statsActions.actions_yearly[i].date
      );
      this.actionsApexArea.yearly[0].data.push(
        this.statsActions.actions_yearly[i].count
      );
    }

    for (let i = 0; i < this.statsActions.actions_per_type.length; i++) {
      data_colors.push(
          this.statsActions.actions_per_type[i].color
      );
      data_labels.push(
          this.statsActions.actions_per_type[i].text
      );
      data_series.push(parseInt(this.statsActions.actions_per_type[i].count));
    }

    this.actionsBreakdownPie.options = {
      ...this.actionsBreakdownPie.options,
      ...{
        colors: data_colors,
        labels: data_labels,
        series: data_series,
      },
    };

    this.tasksBreakdownPie.options = {
      ...this.tasksBreakdownPie.options,
      ...{
        series: [parseInt(this.statsTasks.tasks[0].on_time_tasks), parseInt(this.statsTasks.tasks[0].overdue_tasks)],
      },
    };

  }

  async fetchBackupFile() {
    this.isDowloandingBackUp = true;
    const zip = await companyModule.getBackUpFile();
    const file = new File([zip], `domX_for_business_backup_${dayjs().format('DD_MM_YYYY')}.zip`);
    FileSaver.saveAs(file);
    this.isDowloandingBackUp = false;
  }

  isAdmin() {
    const kc = new KeycloakSession()
    return kc.userHasRole('admin');
  }

  openEditCompanyInfoModal() {
    commonModule.showModal({ name: 'edit-company-info-modal', payload: { companyInfo: this.companyInfo }});
  }
}
