
import { Component, Vue, Watch } from 'vue-property-decorator'
import { commonModule, companyModule } from '@/store/modules/store-accessor'
import FilterDropdown from '../components/dropdown/filter/FilterDropdown.vue'

@Component({
  components: { FilterDropdown }
})
export default class Personnel extends Vue {
  tableheaders: any [] = [
    { text: this.$t("firstname"), sortable: true, class: 'row-style', value: 'firstName' },
    { text: this.$t("lastname"), sortable: true, class: 'row-style', value: 'lastName' },
    { text: this.$t("username"), sortable: true, class: 'row-style', value: 'username' },
    { text: this.$t("verified"), sortable: true, class: 'row-style', value: 'emailVerified' },
    { text: this.$t("created"), sortable: true, class: 'row-style', value: 'created_at' },
    { text: this.$t("role"), sortable: true, class: 'row-style', value: 'roles' },
    { text: this.$t("active_sessions"), sortable: true, class: 'row-style', value: 'sessions' },
    { text: '', value: 'data-table-expand' }, // Move expand arrow to the right
  ]

  sessionHeaders: any [] = [
    { text: this.$t("ip_address"), sortable: true, class: 'row-style', value: 'ipAddress' },
    { text: this.$t("first_access"), sortable: true, class: 'row-style', value: 'start' },
    { text: this.$t("last_access"), sortable: true, class: 'row-style', value: 'lastAccess' },
  ]

  model: any = {
    search: '',
    selected: [] as string[]
  }

  get personnelLength() {
    return companyModule.personnel.length
  }

  get searchLabel() {
    return this.$t("personnelSearch")
  }

  get personnel(){
    return companyModule.personnel
  }

  async mounted () {
    // companyModule.getCompanyInfo();
    companyModule.getCompanyPersonnel();
    commonModule.initSnackbar({});
  }

  get isLoadingTable() {
    return companyModule.isLoadingTable;
  }
}
