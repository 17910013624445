
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import {
  commonModule,
  companyModule,
  installationsModule,
  itemsModule,
  tasksModule,
} from "@/store/modules/store-accessor";
import {
  taskStatusToColor,
  taskStatusToName,
  formatOnlyDate,
  taskColor,
  getOptimalTextColor,
  templatesFilenameLanguage,
  formatDateWithoutSeconds,
} from "@/helpers/data";
import { TimeUtil } from "@/helpers/TimeUtil";
import dayjs from "dayjs";
import FileSaver from "file-saver";

@Component
export default class InstallationsSideviewTasksTab extends Vue {
  @Prop() installation: Record<string, any>;
  @Prop() taskId: number;

  expansionTaskIdIndex = null as number | null;
  expansionPendingTaskIdIndex = null as number | null;

  isDownloadingTaskPdf = false;

  tableOptions = {sortBy: ['task_status_id', 'due_at'], sortDesc: [false, true]};
  expandedItems: any[] = [];

  tableheaders: any [] = [
    { class:'font-weight-black', text: this.$t("title"), sortable: false, value: 'title'},
    { class:'font-weight-black', text: this.$t("item"), sortable: false, value: 'installation_item_id' },
    { class:'font-weight-black', text: this.$t("action"), sortable: false, value: 'action_type_text' },
    { class:'font-weight-black', text: this.$t("userPartner"), sortable: false, value: 'assignee_id' },
    { class:'font-weight-black', text: this.$t("due_at"), sortable: true, value: 'due_at' },
    { class:'font-weight-black', text: this.$t("status"), sortable: true, value: 'task_status_id' },
    { class:'', text: '', value: 'is_periodic', width: '85px', sortable: false, align: 'right' }, // Move expand arrow to the right
    { class:'', text: '', value: 'data-table-expand' }, // Move expand arrow to the right
  ]

  mounted() {
    commonModule.getPersonnelCombo();
    commonModule.getPersonnelPartnersCombo();
    commonModule.getActionCombo();
    commonModule.getDurationCombo();
    itemsModule.getAllCompanyPrimaryInventoryItems();

    let expansionTask = installationsModule.currentInstallation.tasks.find(i => i.id == this.taskId); //This is needed to find the index in the expansion panel
    if (expansionTask) {
      this.expandedItems = [expansionTask]
    }
  }

  @Watch("installation")
  updateTasks() {
    commonModule.getPersonnelCombo();
    commonModule.getActionCombo();
    commonModule.getDurationCombo();
  }

  get tasks() {
    return installationsModule.currentInstallation.tasks;
  }

  formatTaskDate(date: Date, all_day: string) {
    if (!date) {
      return "-";
    }
    else {
      if (all_day) {
        return formatOnlyDate(date);
      }
      return formatDateWithoutSeconds(date);
    }
  }

  formatDateTime(date: Date): string {
    return TimeUtil.formatDateTime(date);
  }

  taskIsComplete(task: any) {
    if (task.task_status_id == 1) return true;
    else return false;
  }

  dateToColor(date: any) {
    return taskColor(date);
  }

  optimalTextColor(color: string) {
    return getOptimalTextColor(color);
  }

  statusToName(status: number) {
    return taskStatusToName(status);
  }

  statusToColor(status: number) {
    return taskStatusToColor(status);
  }

  addTask() {
    commonModule.showModal({
      name: "add-task-modal",
      payload: { installation: this.installation },
    });
  }

  editTask(task: any) {
    commonModule.showModal({
      name: "edit-task-modal",
      payload: { task: task },
    });
  }

  completeTask(task: any) {
    commonModule.showModal({
      name: "complete-task-modal",
      payload: { task: task },
    });
  }

  completeTaskWithAppointment(task: any) {
    commonModule.showModal({
      name: "add-appointment-modal",
      payload: {
        task_id: task.id,
        installation: this.installation,
        action_type_id: task.action_type_id,
        assignee_id: task.assignee_id,
        notes: task.notes,
        job_item: task.task_item,
        installation_item_id: task.installation_item_id,
        item_type_text: task.item_type_text,
        item_make: task.item_make,
        item_model: task.item_model,
        item_serial_no: task.item_serial_no,
      },
    });
  }

  completeTaskWithJob(task: any) {
    commonModule.showModal({
      name: "add-job-modal",
      payload: {
        task_id: task.id,
        installation: this.installation,
        action_type_id: task.action_type_id,
        assignee: task.assignee_id,
        notes: task.notes,
        job_item: task.task_item,
        installation_item_id: task.installation_item_id,
        item_type_text: task.item_type_text,
        item_make: task.item_make,
        item_model: task.item_model,
        item_serial_no: task.item_serial_no,
      },
    });
  }

  deleteTask(task: any) {
    commonModule.showModal({
      name: "delete-task-modal",
      payload: { task: task },
    });
  }

  async donwloadTaskPdf(task: Record<string, unknown>) {
    this.isDownloadingTaskPdf = true;
    const utcOffset = dayjs().utcOffset();

    const data = {
      taskId: task.id,
      utcOffset: utcOffset,
      language: localStorage.getItem("lang"),
    };

    if (task.id) {
      const company = await companyModule.getCompanyInfo();
      const filename = `${templatesFilenameLanguage()}_${company.entity_name}_${
        this.installation.info.tag_id
      }_task_${task.id}.pdf`.replace(/ /g, "_");
      const pdf = await tasksModule.getTaskPdfExportAction(data);
      const file = new File([pdf], filename);
      FileSaver.saveAs(file);
    }
    this.isDownloadingTaskPdf = false;
  }

  showReminderLabel(task: Record<string, any>) {
    return task.title && task.title.length > 0
      ? task.title
      : task.category && task.category.includes(1)
      ? this.$i18n.t("reminder_label")
      : "-";
  }
}
