
import { Component, Vue, Prop } from 'vue-property-decorator'
import { commonModule, installationsModule } from '@/store/modules/store-accessor'

@Component
export default class LogOutModal extends Vue {

  async hideModal () {
    commonModule.hideModal();
  }

  async mounted() {
    commonModule.initSnackbar({});
  }

  doLogout () {
    (window as any).sharedKeycloak.logout();
    this.hideModal()
  }
};
