
import { Component, Vue, Prop } from 'vue-property-decorator'
import { commonModule, companyModule, installationsModule, itemsModule } from '@/store/modules/store-accessor'
import { TimeUtil } from '@/helpers/TimeUtil'
import { isDecimalNumber, isGasCompany } from "@/helpers/data";

@Component
export default class EditInstallationItemModal extends Vue {
  @Prop()
  payload!: any;

  isEditingItem = false;

  menu= false;

  itemModel = {
   selected_item: {id: this.payload.installation_item.id, make: this.payload.installation_item.make, model: this.payload.installation_item.model, name: `${this.payload.installation_item.type_name} | ${this.payload.installation_item.make} | ${this.payload.installation_item.model ?? '-'}`, item_type_id: this.payload.installation_item.item_type_id},
   installed_at: this.formatDate(this.payload.installation_item.installation_date),
   is_primary: this.payload.installation_item.is_primary,
   serial_number: this.payload.installation_item.serial_no,
   is_active: this.payload.installation_item.is_active,
   cost: this.payload.installation_item.cost,
   installation_placement_type_id: {id: this.payload.installation_item.installation_placement_type_id, installation_type: this.payload.installation_item.installation_type},
   fuel_type_id: {id: this.payload.installation_item.fuel_type_id, fuel_type: this.payload.installation_item.fuel_type},
   notes:this.payload.installation_item.notes || '',
   warranty: this.payload.installation_item.warranty,
   installation_id: this.payload.installation.info.id,
   id: this.payload.installation_item.id
  }

  get installationItemsCombo () {
    let combo = itemsModule.installationItems;
    combo.push({id: this.payload.installation_item.id, make: this.payload.installation_item.make, model: this.payload.installation_item.model, name: `${this.payload.installation_item.type_name} | ${this.payload.installation_item.make} | ${this.payload.installation_item.model ?? '-'}`, item_type_id: this.payload.installation_item.item_type_id})
    return combo;
  }

  get fuelTypeCombo () {
    return commonModule.fuelTypeCombo;
  }

  get placementTypeCombo () {
    return commonModule.placementTypeCombo;
  }

  installationItemRule= [
    (val: any) => (val || '').name.length > 0 || this.$i18n.t("installation_item_required"),
  ]
  notesRule= [
    (val: any) => {
      if(val != null) return val.length <= 1000 || this.$i18n.t("notesLengthRule");
      else return true;
    },
  ]
  serialNoRule= [
    (val: any) => {
      if(val != null) return val.length <= 50 || this.$i18n.t("form_serialNumber_length_rule");
      else return true;
    },
  ]
  costRule = [
    (val: any) => val >= 0 ||this.$i18n.t('nonNegativeCostRule'),
  ]

  formatDate (date: Date): string {
    return TimeUtil.formatDateOnly(date)
  }

  async hideModal () {
    commonModule.hideModal();
  }

  async mounted() {
    commonModule.initSnackbar({});
    await commonModule.getPersonnelCombo();
    await commonModule.getActionCombo();
    await itemsModule.getAllInventoryItems('');
    await commonModule.getFuelTypeCombo();
    await commonModule.getPlacementTypeCombo();
  }

  onlyDecimalNumbers(evt: any) {
    isDecimalNumber(evt);
  }

  doEdit () {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      this.isEditingItem = true;
      this.itemModel.selected_item.model = this.itemModel.selected_item.model ?? '-'

      installationsModule.updateInstallationItem(this.itemModel)
      itemsModule.getInstallationPrimaryInventoryItems(this.itemModel.installation_id);
      this.hideModal()
    }
  }

  isNumber(evt: any) {
      evt = evt || window.event;
      let charCode = evt.which || evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
      return false;
  }

  isGasCompany() {
    return isGasCompany(companyModule.company)
  }

};
