
import { Component, Vue } from 'vue-property-decorator'
import { commonModule, installationsModule } from '@/store/modules/store-accessor'
import QuickAddCalendarClient from '../QuickAdd/Calendar/QuickAddCalendarClient.vue';
import QuickAddCalendarInstallation from '../QuickAdd/Calendar/QuickAddCalendarInstallation.vue';

@Component({
  components: { QuickAddCalendarClient, QuickAddCalendarInstallation },
})
export default class QuickAddCalendarModal extends Vue {

  wizardStep = 1;
  client = { id: -1, firstname: '', lastname: '', entity_name: '', is_business: false };
  installation = { id: -1, address: '', area: '', zip_code: '', floor: 0 };

  get clientName() {
    return this.client.is_business ? `${this.client.entity_name} (${this.client.firstname} ${this.client.lastname})`
      : `${this.client.firstname} ${this.client.lastname}`
  }

  get installationAddress() {
    let addres_arr = [this.installation.address, this.installation.area, this.installation.zip_code, this.installation.floor]

    return addres_arr.filter(Boolean).join(', ')
  }

  async hideModal() {
    commonModule.hideSecondModal();
  }

  async mounted() {
    commonModule.initSnackbar({});
  }

  doClose() {
    this.hideModal()
    installationsModule.clearNewInstallationCoordinates();
  }

  doNextStep(val: any) {
    if (this.wizardStep == 1) {
      this.client = val
    }
    else if (this.wizardStep == 2) {
      this.installation = val
    }

    this.wizardStep++;
  }


  doPrevStep() {
    if (this.wizardStep > 1) {
      this.wizardStep--;

      if (this.wizardStep == 1) {
        this.client = { id: -1, firstname: '', lastname: '', entity_name: '', is_business: false };
      }

      if (this.wizardStep == 2) {
        this.installation = { id: -1, address: '', area: '', zip_code: '', floor: 0 };
      }
    }
  }
};
