import { BaseApi } from '@/helpers/base-api'

export default class ActionsApi extends BaseApi {
  constructor () {
    super('/api/v1/expenses')
    this.errorMessage = 'There was an issue when trying to fetch the expenses. If this error persists, please contact our support.'
  }

  public getAllExpenses = (): Promise<ExpenseDto[]> => this.instance.get('')
  public getExpenseDetails = (expenseId: string): Promise<ExpenseDto> => this.instance.get(`/${expenseId}`);
  public getExpenseFile = (expenseId: string): Promise<Blob> => this.instance.get(`/${expenseId}/file`, {responseType: 'arraybuffer'})


  public createExpense = (data: Record<string, unknown>): Promise<JSON> => this.instance.post('', data)
  public createInstallationFile = (expenseId: string, data: Record<string, unknown>): Promise<string> => this.instance.post(`/${expenseId}/file`, data)

  public updateExpense = (expenseId: string, data: Record<string, unknown>): Promise<JSON> => this.instance.patch(`/${expenseId}`, data)

  public deleteExpense = (expenseId: string): Promise<JSON> => this.instance.delete(`/${expenseId}`)
  public deleteExpenseFile = (expenseId: string): Promise<JSON> => this.instance.delete(`/${expenseId}/file`)

}