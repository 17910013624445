import store from '@/store/store'
import { getModule } from 'vuex-module-decorators'

import CommonModule from '@/store/modules/common-module'
import ClientsModule from "@/store/modules/clients-module";
import InstallationsModule from "@/store/modules/installations-module";
import ActionsModule from "@/store/modules/actions-module";
import MaterialsModule from '@/store/modules/materials-module';
import PaymentsModule from "@/store/modules/payments-module";
import ItemsModule from "@/store/modules/items-module";
import AppointmentsModule from "@/store/modules/appointments-module";
import StatsModule from "@/store/modules/stats-module";
import MapModule from "@/store/modules/map-module";
import CompanyModule from '@/store/modules/company-module';
import JobsModule from '@/store/modules/jobs-module';
import FilterModule from '@/store/modules/filter-module';
import TaskModule from '@/store/modules/tasks-module';
import ActionTypesModule from '@/store/modules/actionTypes-module';
import ItemTypesModule from '@/store/modules/itemTypes-module';
import DashboardModule from '@/store/modules/dashboard-module';
import ExpensesModule from '@/store/modules/expenses-module';
import PartnersModule from '@/store/modules/partners-module';

export const commonModule = getModule(CommonModule, store)
export const clientsModule = getModule(ClientsModule, store)
export const installationsModule = getModule(InstallationsModule, store)
export const actionsModule = getModule(ActionsModule, store)
export const materialsModule = getModule(MaterialsModule, store)
export const paymentsModule = getModule(PaymentsModule, store)
export const itemsModule = getModule(ItemsModule, store)
export const appointmentsModule = getModule(AppointmentsModule, store)
export const statsModule = getModule(StatsModule, store)
export const companyModule = getModule(CompanyModule, store)
export const mapModule = getModule(MapModule, store)
export const jobsModule = getModule(JobsModule, store)
export const filterModule = getModule(FilterModule, store)
export const tasksModule = getModule(TaskModule, store)
export const actionTypesModule = getModule(ActionTypesModule, store)
export const itemTypesModule = getModule(ItemTypesModule, store)
export const dashboardModule = getModule(DashboardModule, store)
export const expensesModule = getModule(ExpensesModule, store)
export const partnersModule = getModule(PartnersModule, store)