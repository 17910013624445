
import Vue from 'vue'
import Vuex from 'vuex'
import common from '@/store/modules/common-module'
import clients from "@/store/modules/clients-module";
import installations from "@/store/modules/installations-module";
import actions from "@/store/modules/actions-module";
import materials from "@/store/modules/materials-module";
import payments from "@/store/modules/payments-module";
import items from "@/store/modules/items-module";
import appointments from "@/store/modules/appointments-module";
import stats from "@/store/modules/stats-module";
import company from "@/store/modules/company-module";
import map from "@/store/modules/map-module";
import jobs from "@/store/modules/jobs-module";
import filters from "@/store/modules/filter-module";
import tasks from "@/store/modules/tasks-module";
import actionTypes from "@/store/modules/actionTypes-module";
import itemTypes from "@/store/modules/itemTypes-module";
import dashboard from "@/store/modules/dashboard-module";
import expenses from "@/store/modules/expenses-module";
import partners from "@/store/modules/partners-module";

Vue.use(Vuex)

// imports have to match the name in @Module (e.g. @Module({ namespaced: true, name: 'apps' }))
export default new Vuex.Store({
  modules: {
    common,
    clients,
    installations,
    actions,
    materials,
    payments,
    items,
    appointments,
    stats,
    company,
    map,
    jobs,
    filters,
    tasks,
    actionTypes,
    itemTypes,
    dashboard,
    expenses,
    partners,
  }
})
