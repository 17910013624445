
  import { Component, Vue, Prop } from 'vue-property-decorator'
  import { commonModule, actionTypesModule } from '@/store/modules/store-accessor'
  
  @Component
  export default class DeleteActionTypeModal extends Vue {
    @Prop()
    payload!: any;

    isDeletingActionType = false;
  
    async hideModal () {
      commonModule.hideModal();
    }
  
    async mounted() {
      commonModule.initSnackbar({});
    }
  
    async doDelete () {
        this.isDeletingActionType = true;
        await actionTypesModule.deleteActionType(this.payload.actionType);
        await actionTypesModule.getAllActionTypes();
        this.hideModal()
    }
  };
