
  import { Component, Vue, Prop } from 'vue-property-decorator'
  import { commonModule, expensesModule, installationsModule, itemTypesModule } from '@/store/modules/store-accessor'
  import _ from 'lodash'
  import { TimeUtil } from '@/helpers/TimeUtil'
  import { isDecimalNumber } from "@/helpers/data";
  import dayjs from 'dayjs';
  import AssigneeCombo from '@/components/Combobox/AssigneeCombo.vue';
  
  @Component({
    components: {
      AssigneeCombo,
    },
  })
  export default class EditExpenseModal extends Vue {
    @Prop()
    payload!: any;
    
    expenseModel = {} as any;
    payloadExpenseModel = {} as any;
    createdAtMenu = false;
    createAtTimeMenu = false;
    isEditingExpense = false;
  
    costRule:any =[(val: any) => val !== '' && val > 0 || this.$i18n.t("nonZeroCost"),]
  
    notesRule= [
      (val: any) => {
        if(val != null) return val.length <= 30 || this.$i18n.t("notesLengthRule");
        else return true;
      },
    ]

    fileRules = [
      (val: any) => !val || val.size <= 5000000 || this.$i18n.t("fileInputSizeRule"), // 5mb rule
    ]

    fileModel = {
      delete_file: false,
      file: null,
      url: null,
      file_name: this.payload.expense.file_name,
    }
  
    async mounted() {
      this.payloadExpenseModel = _.cloneDeep(this.payload.expense)
      
      this.payloadExpenseModel.payment_type = this.findPaymentType(this.payload.expense.payment_type_id);
      this.payloadExpenseModel.assignee_id = this.getAssigneeId(this.payload.expense.assignee_id);
      this.payloadExpenseModel.created_at_date = dayjs(this.payload.expense.created_at).format('YYYY-MM-DD');
      this.payloadExpenseModel.created_at_time = dayjs(this.payload.expense.created_at).format('HH:mm');

      this.expenseModel = _.cloneDeep(this.payloadExpenseModel)
      commonModule.initSnackbar({});
      await itemTypesModule.getItemTypesCombo();
    }
    
    updateAssignee(assignee: any) {
      if(assignee) {
        this.expenseModel.assignee_id = assignee;
      }
    }

    async hideModal () {
      if(this.payload.expense.secondModal) {
        commonModule.hideSecondModal();
      } else {
        commonModule.hideModal();
      }
    }
  
    get expense() {
      return this.expenseModel
    }

    get assigneeIdCombo () {
      return commonModule.personnelCombo;
    }

    get editDisabled() {
      return _.isEqual(this.expenseModel, this.payloadExpenseModel) && !this.fileModel.file && !this.fileModel.delete_file;
    }

    get paymentTypesCombo () {
      for (let i = 0; i < commonModule.paymentTypeCombo.length; i++) {
        (commonModule.paymentTypeCombo[i]["payment_type"] as any) = this.$t(commonModule.paymentTypeCombo[i]["payment_type"]);
      }
      return commonModule.paymentTypeCombo;
    }

    getAssigneeId(name: string) {
      const combo: any = commonModule.personnelPartnersCombo;
      const foundItem = combo.find((item: any) => item.name === name);
      if(foundItem) {
        return { name: foundItem.name, id: foundItem.id };
      } else if(!foundItem && this.payload.expense.assignee_id) {
        return { name: this.payload.expense.assignee_id }
      } else {
        return ''
      }
    }
    
    findPaymentType(expense: any) {
      let combo: any = commonModule.paymentTypeCombo;
      const foundType = combo.find((item: any) => item.id === expense);
      if(foundType) {
        return { id: foundType.id, payment_type: foundType.payment_type };
      }
    }

    get fileToBedeleted() {
      return this.fileModel.file;
    }

    fileInputPlaceHolder() {
      return this.fileModel.file_name ? this.fileModel.file_name : this.$i18n.t('newFile');
    }

    fileExists() {
      return this.payload.expense.file_name && !this.fileToBedeleted;
    }

    deleteFile() {
      this.fileModel.delete_file = true;
      this.fileModel.file_name = '';
    }
  
    async doEditExpense () {
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        this.isEditingExpense = true;
        let expensesObj = _.cloneDeep(this.expenseModel);

        if(this.fileModel.delete_file) {
          await expensesModule.deleteExpenseFile(expensesObj);
        }

        if (this.fileModel.file) {
          let formData = new FormData();
          formData.append("file", (this.fileModel.file as any));
          formData.append("expenseId", this.payload.expense.id);

          // If there is already a file, delete if before adding the new one
          if(this.payload.expense.file_name && !this.fileModel.delete_file) {
            await expensesModule.deleteExpenseFile(this.payload.expense);
          }
          await expensesModule.addExpenseFile({expenseId: this.payload.expense.id, file: formData});
        }
  
        await expensesModule.editExpense(expensesObj);

        if(this.payload.expense.secondModal) {
          await expensesModule.getExpenseDetails(this.payload.expense.id);
        }
        this.isEditingExpense = false;
        this.hideModal();
      }
    }
  
    formatDate (date: Date): string {
      return TimeUtil.formatDateTime(date)
    }
  
    onlyDecimalNumbers(evt: any) {
      isDecimalNumber(evt);
    }
  };
  