
import { Component, Vue, Prop } from 'vue-property-decorator'
import { commonModule, expensesModule } from '@/store/modules/store-accessor'
import { TimeUtil } from '@/helpers/TimeUtil'
import _ from 'lodash';
import FileSaver from 'file-saver';

@Component({
  components: {
  },
})
export default class CompanyExpenseModal extends Vue {
  isDownloadingFile = false;

  get expense() {
    expensesModule.currentExpense.secondModal = true;
    return expensesModule.currentExpense;
  }

  async hideModal() {
    commonModule.hideModal();
  }

  formatDateTime (date: Date): string {
    return TimeUtil.formatDateTime(date)
  }

  editExpense() {
    commonModule.showSecondModal({ name: 'edit-expense-modal', payload: { expense: this.expense }});
  }

  deleteExpense() {
    commonModule.showSecondModal({ name: 'delete-expense-modal', payload: { expense: this.expense }});
  }

  async downloadExpenseFile(expense: Record<string, any>) {
    this.isDownloadingFile = true;
    let downloadFile = await expensesModule.getExpensionFile(expense.id);
    downloadFile = new File([downloadFile], expense.file_name);
    FileSaver.saveAs(downloadFile);
    this.isDownloadingFile = false;
  }
};
