
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import {
  commonModule,
  tasksModule,
  actionTypesModule,
  itemsModule,
  dashboardModule,
} from "@/store/modules/store-accessor";
import { getOptimalTextColor } from "@/helpers/data";
import AssigneeCombo from "@/components/Combobox/AssigneeCombo.vue";
import _ from "lodash";

@Component({
  components: {
    AssigneeCombo,
  },
})
export default class AddTaskModal extends Vue {
  @Prop()
  payload!: any;

  isAddingTask = false;

  dateMenu = false;
  timeMenu = false;

  isCompleted = false;
  loadingInstallationPrimaryItems = true;

  taskModel = {
    due_at: "",
    assignee_id: "",
    installation_id: "",
    task_status_id: -1,
    title: "",
    notes: "",
    action_type_id: null,
    installation_item_id: { primary_items: "", id: 0 },
    scheduled_start_time: "",
    all_day: false,
  };

  updateAssignee(assignee: any) {
    this.taskModel.assignee_id = assignee;
  }

  clearDatetime() {
    this.taskModel.scheduled_start_time = "";
    this.taskModel.all_day = false;
  }

  get actionsCombo() {
    return actionTypesModule.actionTypesCombo;
  }

  get isLoadingInstallationPrimaryItems() {
    return this.loadingInstallationPrimaryItems;
  }

  get disablePrimaryItems() {
    return (
      this.loadingInstallationPrimaryItems ||
      this.installationPrimaryItems.length === 0
    );
  }

  get installationPrimaryItems() {
    return itemsModule.installationPrimaryItems;
  }

  optimalTextColor(color: string) {
    return getOptimalTextColor(color);
  }

  defaultInstallationItem() {
    if (
      this.installationPrimaryItems &&
      this.installationPrimaryItems.length === 1
    ) {
      return this.installationPrimaryItems[0];
    }
    return { primary_items: "", id: 0 };
  }

  notesRule = [
    (val: any) => val.length <= 1000 || this.$i18n.t("notesLengthRule"),
  ];
  titleRule = [
    (val: any) => !!val || this.$i18n.t("title_required"),
    (val: any) => val.length <= 300 || this.$i18n.t("form_title_length_rule"),
  ];
  scheduledTimeRules() {
    if (this.taskModel.due_at) {
      return [(val: any) => !!val || this.$i18n.t("timeRequired")];
    }
    return [];
  }

  async hideModal() {
    commonModule.hideModal();
  }

  async mounted() {
    commonModule.initSnackbar({});
    commonModule.getPersonnelCombo();
    await actionTypesModule.getActionTypesCombo();

    this.loadingInstallationPrimaryItems = true;
    if (this.payload.installation && this.payload.installation.info.id) {
      await itemsModule.getInstallationPrimaryInventoryItems(
        this.payload.installation.info.id
      );
    }
    (this.taskModel.installation_item_id as any) =
      this.defaultInstallationItem();
    this.loadingInstallationPrimaryItems = false;
  }

  get showTimeInput() {
    return !this.taskModel.all_day;
  }

  async doAddTask() {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      let taskModelCopy = _.cloneDeep(this.taskModel);
      this.isAddingTask = true;
      taskModelCopy.task_status_id = this.isCompleted ? 3 : 2; // -> 3 completed -> 2 pending
      taskModelCopy.installation_id =
        this.payload.installation && this.payload.installation.info.id
          ? this.payload.installation.info.id
          : null;
      await tasksModule.addTask(taskModelCopy);

      if (this.$route.name === "Tasks") {
        await tasksModule.getTasks(tasksModule.filters);
        await tasksModule.getPendingTasks();
      } else if (this.$route.name === "Dashboard") {
        dashboardModule.getDashboardPendingTasks(dashboardModule.taskFilters);
      }

      this.isAddingTask = false;
      this.hideModal();
    }
  }
}
