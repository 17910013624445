
  import { commonModule } from '@/store/modules/store-accessor'
  import Vue from "vue";
  import { Component, Prop } from "vue-property-decorator";
  
  @Component
  export default class AssigneeUserCombo extends Vue {
    selectedAssignee = null as any;
    @Prop() defaultAssignee!: any; // for edit modals & add appointment in the calendar page
    @Prop({ default: true }) rightMargin!: boolean; // will set it to false in the add job modal

    loading = true;

    selectEntry() {
      this.$emit('update-filter', this.selectedAssignee);
    }

    async mounted() {
      await commonModule.getPersonnelCombo();
      if(this.defaultAssignee) {
        const foundItem = this.assigneeIdCombo.find((item: any) => item.name === this.defaultAssignee.name);
        if(!foundItem) {
          this.defaultAssignee.id ? this.assigneeIdCombo.push({name: this.defaultAssignee.name, id: this.defaultAssignee.id}) :
            this.assigneeIdCombo.push({name: this.defaultAssignee.name})
        }

        this.selectedAssignee = this.defaultAssignee;
        this.$emit('update-filter', this.selectedAssignee);
      }
      this.loading = false;
    }

    get assigneeIdCombo() {
      return commonModule.personnelCombo;
    }

    rules = [
      (val: any) => !!val || this.$i18n.t("user_required"),
    ]
  }
