
    import Dropdown from '@/components/dropdown/Dropdown.vue'
    import DropdownItem from '@/components/dropdown/DropdownItem.vue'
    import { Component, Vue, Prop } from 'vue-property-decorator'
    import { TimeUtil } from '@/helpers/TimeUtil'
    import { commonModule, installationsModule } from '@/store/modules/store-accessor'
    import FileSaver from 'file-saver';

    @Component({components: { Dropdown, DropdownItem } })
    export default class InstallationsSideviewFilesTab extends Vue {
     @Prop() installation: Record<string, any>;

    isDowloadingFile = false;

      tableheaders: any [] = [
        { text: this.$t("filename"), align: 'left', sortable: true, class: 'row-style', value: 'original_name' },
        { text: this.$t("notes"), sortable: true, align: 'left', class: 'row-style', value: 'notes' },
        { text: this.$t("uploadedBy"), sortable: true, align: 'left', class: 'row-style', value: 'created_by' },
        { text: this.$t("uploadedAt"), sortable: true, align: 'left', class: 'row-style', value: 'created_at' },
        { text: this.$t("actions"), align: 'right', sortable: false, class: 'row-style', value: 'id' } 
      ]

      addFileToInstallation() {
        commonModule.showModal({ name: 'add-file-to-installation-modal', payload: { installation: this.installation } })
      }

      async downloadFile(file: Record<string, any>) {
        this.isDowloadingFile = true;
        let downloadFile = await installationsModule.getInstallationFile({installationId: file.installation_id, fileId: file.id} );
        downloadFile = new File([downloadFile], file.original_name);
        FileSaver.saveAs(downloadFile);
        this.isDowloadingFile = false;
      }

      async editFile(file: Record<string, any>) {
        commonModule.showModal({ name: 'edit-installation-file-modal', payload: { file: file } })
      }
      async deleteFile(file: Record<string, any>) {
       commonModule.showModal({ name: 'delete-installation-file-modal', payload: { file: file } })
      }
    
      formatDate (date: Date): string {
        return TimeUtil.formatDateTime(date)
      }
    };
    