
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import config from "@/config";
@Component
export default class Sidebar extends Vue {
  
  name = "Error"

  get config() {
    return config;
  }

}
