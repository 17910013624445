
import { Component, Vue, Prop } from "vue-property-decorator";
import {
  commonModule,
  appointmentsModule,
  actionTypesModule,
  itemsModule,
  companyModule,
} from "@/store/modules/store-accessor";
import { TimeUtil } from "@/helpers/TimeUtil";
import AssigneeMultiUserCombo from "@/components/Combobox/AssigneeMultiUserCombo.vue";
import _ from "lodash";
import { calcDuration, getOptimalTextColor } from "@/helpers/data";

@Component({
  components: {
    AssigneeMultiUserCombo,
  },
})
export default class EditAppointmentModal extends Vue {
  @Prop()
  payload!: any;

  isEditingAppoinment = false;
  loadingInstallationPrimaryItems = true;

  menu3 = false;
  menu4 = false;

  payloadAppointmentModel = {} as any;
  primaryInstallationItemsCombo = [] as any;

  appointmentModel = {} as any;

  scheduledDateRule = [
    (val: any) => !!val || this.$i18n.t("scheduled_day_required"),
  ];

  userRule = [(val: any) => !!val || this.$i18n.t("user_required")];

  typeRule = [
    (val: any) => this.typeFound(val) || this.$i18n.t("action_required"),
  ];
  scheduledTimeRule = [
    (val: any) => !!val || this.$i18n.t("scheduled_time_required"),
  ];
  durationRule = [
    (val: any) =>
      (val || "")?.text?.length > 0 || this.$i18n.t("duration_required"),
  ];
  notesRule = [
    (val: any) => {
      if (val != null)
        return val.length <= 1000 || this.$i18n.t("notesLengthRule");
      else return true;
    },
  ];
  descriptionRule = [
    (val: any) => val?.length <= 80 || this.$i18n.t("jobDescriptionLengthRule"),
  ];

  textFound(duration: string) {
    const found = commonModule.durationCombo?.find((item: any) => {
      return item.text === duration;
    });
    return found == undefined ? false : true;
  }

  durationFound(duration: string) {
    const found: any = commonModule.durationCombo?.find((item: any) => {
      return item.text === duration;
    });
    return found == undefined ? "" : found.dur;
  }

  get durationCombo() {
    return commonModule.durationCombo;
  }

  get actionsCombo() {
    return actionTypesModule.actionTypesCombo;
  }

  get assigneeIdCombo() {
    return commonModule.personnelCombo;
  }

  optimalTextColor(color: string) {
    return getOptimalTextColor(color);
  }

  getAssignee(name: string) {
    const combo: any = commonModule.personnelCombo;
    const foundItem = combo.find((item: any) => item.name === name);
    if (foundItem) {
      return { name: foundItem.name, id: foundItem.id };
    } else {
      return "";
    }
  }

  itemName(obj: any) {
    this.primaryInstallationItemsCombo = this.installationPrimaryItems;
    const foundItem = this.primaryInstallationItemsCombo.find(
      (item: any) =>
        item.primary_items[0] == obj.primary_items && item.id === obj.id
    );
    if (foundItem) {
      return foundItem;
    } else {
      if (obj.id) {
        let item_hidden_id = (Math.random() + 1).toString(36).substring(7);
        this.primaryInstallationItemsCombo.push({
          ...obj,
          disabled: true,
          hidden_id: item_hidden_id,
        });
        return {
          ...obj,
          disabled: true,
          hidden_id: item_hidden_id,
        };
      } else {
        return { primary_items: "", id: 0 };
      }
    }
  }

  async hideModal() {
    commonModule.hideModal();
  }

  get editDisabled() {
    return _.isEqual(this.appointmentModel, this.payloadAppointmentModel);
  }

  async beforeMount() {
    await itemsModule.getInstallationPrimaryInventoryItems(
      this.payload.appointment.installation_id
    );
    commonModule.getPersonnelCombo();
    commonModule.getActionCombo();
    commonModule.getDurationCombo();
    actionTypesModule.getActionTypesCombo();
    this.loadingInstallationPrimaryItems = true;
    this.loadingInstallationPrimaryItems = false;

    this.payloadAppointmentModel.type_id = {
      text: this.payload.appointment.type_name,
      color: "",
      cost: 0,
      id: this.payload.appointment.action_type_id,
    };
    this.payloadAppointmentModel.assignee = this.getAssignee(
      this.payload.appointment.assignee_id
    );
    this.payloadAppointmentModel.assignee_id =
      this.payloadAppointmentModel.assignee?.id;
    this.payloadAppointmentModel.assignee_ids =
      this.payload.appointment.assignee_ids;
    this.payloadAppointmentModel.assignees = this.payload.appointment.assignees;
    this.payloadAppointmentModel.appointment_status_id = 2;
    this.payloadAppointmentModel.scheduled_start = this.formatDate(
      this.payload.appointment.scheduled_start
    );
    this.payloadAppointmentModel.scheduled_start_time = this.formatTime(
      this.payload.appointment.scheduled_start
    );
    this.payloadAppointmentModel.duration = {
      dur: this.durationFound(
        calcDuration(
          this.payload.appointment.scheduled_start,
          this.payload.appointment.scheduled_end
        )
      ),
      text: this.textFound(
        calcDuration(
          this.payload.appointment.scheduled_start,
          this.payload.appointment.scheduled_end
        )
      )
        ? calcDuration(
            this.payload.appointment.scheduled_start,
            this.payload.appointment.scheduled_end
          )
        : "",
    };
    this.payloadAppointmentModel.appointment_notes =
      this.payload.appointment.notes || "";
    this.payloadAppointmentModel.installation_id =
      this.payload.appointment.installation_id;
    this.payloadAppointmentModel.id = this.payload.appointment.id;
    this.payloadAppointmentModel.job_id = this.payload.appointment.job_id || "";
    this.payloadAppointmentModel.installation_item_id = this.itemName({
      primary_items: this.payload.appointment.job_item,
      id: this.payload.appointment.installation_item_id,
      item_type_text: this.payload.appointment.item_type_text,
      item_make: this.payload.appointment.item_make,
      item_model: this.payload.appointment.item_model,
      item_serial_no: this.payload.appointment.item_serial_no,
    });
    this.payloadAppointmentModel.description =
      this.payload.appointment.description || "";

    this.appointmentModel = _.cloneDeep(this.payloadAppointmentModel);
  }

  async mounted() {
    commonModule.initSnackbar({});
  }

  async updateAssignee(assignees: any) {
    this.appointmentModel.assignee_id = assignees?.[0]?.id || null;
    this.appointmentModel.assignee = assignees?.[0];
    this.appointmentModel.assignee_ids = assignees;
    await commonModule.getPersonnelCombo();
    await commonModule.getActionCombo();
    await commonModule.getDurationCombo();
    await actionTypesModule.getActionTypesCombo();
    this.loadingInstallationPrimaryItems = true;
    this.loadingInstallationPrimaryItems = false;
  }

  formatDate(date: Date): string {
    return TimeUtil.formatDateOnly(date);
  }

  formatTime(date: Date): string {
    return TimeUtil.formatTimeOnly(date);
  }

  typeFound(type: any) {
    if (type) {
      const found = this.actionsCombo?.find((item: any) => {
        return item["text"] === type.text;
      });
      return found == undefined ? false : true;
    }
    return false;
  }

  get installationPrimaryItems() {
    return itemsModule.installationPrimaryItems;
  }

  get isLoadingInstallationPrimaryItems() {
    return this.loadingInstallationPrimaryItems;
  }

  get disablePrimaryItems() {
    return (
      this.loadingInstallationPrimaryItems ||
      this.installationPrimaryItems.length === 0
    );
  }

  async doEdit() {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      this.isEditingAppoinment = true;
      appointmentsModule.unsetCalendarAppointments();
      appointmentsModule.editAppointment(this.appointmentModel);
      appointmentsModule.getCalendarAppointmentsAction(
        appointmentsModule.filters
      );
      this.hideModal();
    }
  }

  get jobDescriptionIsEnabled() {
    return (companyModule.company?.preferences as any)?.job_description;
  }
}
