import { BaseApi } from '@/helpers/base-api'

export default class PaymentsApi extends BaseApi {
  constructor () {
    super('/api/v1/payments')
    this.errorMessage = 'There was an issue when trying to fetch the payments. If this error persists, please contact our support.'
  }

  public getAllPayments = (query: string): Promise<PaymentDto[]> => this.instance.get(`${query}`)

  public getPaymentsTotals = (query: string): Promise<PaymentDto> => this.instance.get(`/totals${query}`)

  public createPayments = (data: Record<string, unknown>): Promise<JSON> => this.instance.post('', data)
  public getPaymentsPdfExport = (query: string, data: Record<string, unknown>): Promise<Blob> => this.instance.post(`/export${query}`, data, { responseType: 'blob' })

  public updatePayment = (paymentId: string, data: Record<string, unknown>): Promise<JSON> => this.instance.patch(`/${paymentId}`, data)

  public deletePayments = (paymentId: string): Promise<JSON> => this.instance.delete(`/${paymentId}`)

}
