
import { Component, Vue, Prop } from 'vue-property-decorator'
import { commonModule, actionsModule } from '@/store/modules/store-accessor'

@Component
export default class DeleteActionModal extends Vue {
  @Prop()
  payload!: any;

  isDeletingAction = false;

  async hideModal () {
    commonModule.hideModal();
  }

  async mounted() {
    commonModule.initSnackbar({});
  }

  doDeleteAction () {
        this.isDeletingAction = true;
        actionsModule.deleteAction(this.payload.action);
        this.hideModal();
  }
};
