import {
    VuexModule,
    Module,
    Action,
    Mutation,
  } from "vuex-module-decorators";
  import ItemTypesApi from "@/api/itemTypes-api";
  
  
  @Module({ namespaced: true, name: "itemTypes" })
  export default class ItemTypesModule extends VuexModule {
    public itemTypesCombo = [];
    public itemTypeById: ItemsTypeDto[] = [];
    public itemTypes = [];
    public isLoadingTable = true;

    @Action({ commit: 'setItemTypes'})
    async getItemTypes (): Promise<any[]> {
      const itemTypesApi = new ItemTypesApi()
      const itemTypes = await itemTypesApi.getAllItemTypes();
      return itemTypes
    }
  
    @Action
    async addItemType(data: Record<string, any>) {
      const itemTypesApi = new ItemTypesApi()
      const response = await itemTypesApi.createAItemType(data)
      return response;
    }
  
    @Action({ commit: 'setItemTypesCombo'})
    async getItemTypesCombo (): Promise<any[]> {
      const itemTypesApi = new ItemTypesApi()
      const itemTypesCombo = await itemTypesApi.itemTypesCombo();
      return itemTypesCombo
    }
  
    @Action({ commit: "setItemTypeById" })
    async getItemTypeById(actionTypeId: string): Promise<ItemsTypeDto[]> {
      const itemTypesApi = new ItemTypesApi();
  
      const itemType = await itemTypesApi.getItemTypeById(actionTypeId);
  
      return itemType;
    }
  
    @Action
    async editItemType(data: Record<string, any>) {
      const itemTypesApi = new ItemTypesApi()
      await itemTypesApi.updateItemType(data.id, data);
    }
  
    @Action
    async deleteActionType(data: Record<string, any>) {
  
      const itemTypesApi = new ItemTypesApi()
      await itemTypesApi.deleteItemType(data.id)
    }
  
    @Mutation
    public setItemTypesCombo (itemTypesCombo: []): void {
      this.itemTypesCombo = itemTypesCombo;
    }
  
    @Mutation
    public setItemTypeById (itemTypeById: ItemsTypeDto[]): void {
      this.itemTypeById = itemTypeById;
    }

    @Mutation
    public setItemTypes(itemTypes: []): void {
      this.itemTypes = itemTypes;
    }

    @Mutation
    public setIsLoadingTable(isLoadingTable: boolean): void {
      this.isLoadingTable = isLoadingTable
    }
  }
  