import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";

import { ActionContext } from "vuex";
import FilterApi from '@/api/filter-api'


@Module({ namespaced: true, name: 'filters' })
export default class FiltersModule extends VuexModule {
public itemFilters: ItemFilterDto[] = [];
public installationFilters: InstallationsFilterDto[] = [];
public jobFilters: JobsFilterDto = {} as JobsFilterDto;
public mapFilters: InstallationsFilterDto[] = [];
public appointmentsFilters: any = {};
public dashboardTasksFilters: any[] = [];
public calendarFilters: any[] = [];
public tasksFilters: any[] = [];
public isLoading = true;

@Action({ commit: 'setFilters'})
async getItemFilters (): Promise<ItemFilterDto[]> {
  const filterApi = new FilterApi()
  const itemFilters = await filterApi.getItemFilters();
  this.setIsLoading(false);
  return itemFilters
}

@Mutation
public setFilters (itemFilters: ItemFilterDto[]): void {
  this.itemFilters = itemFilters
}

@Action({ commit: 'setInstallationFilters'})
async getInstallationsFilters (): Promise<InstallationsFilterDto[]> {
  const filterApi = new FilterApi()
  const installationFilters = await filterApi.getInstallationsFilters();
  this.setIsLoading(false);
  return installationFilters
}

@Mutation
public setInstallationFilters (installationFilters: InstallationsFilterDto[]): void {
  this.installationFilters = installationFilters
}

@Action({ commit: 'setJobFilters'})
async getJobFilters (): Promise<JobsFilterDto> {
  const filterApi = new FilterApi()
  const jobFilters = await filterApi.getJobsFilters();
  return jobFilters
}

@Mutation
public setJobFilters (jobFilters: JobsFilterDto): void {
  (jobFilters as any).clients = (jobFilters as any).clients.map((obj: { icon: string; is_business: any; }) => {
    obj.icon = obj.is_business ? 'mdi-domain' : 'mdi-account';
    return obj;
  });
  this.jobFilters = jobFilters
}

@Action({ commit: 'setMapFilters'})
async getMapFilters (): Promise<InstallationsFilterDto[]> {
  const filterApi = new FilterApi()
  const mapFilters = await filterApi.getMapFilters();
  this.setIsLoading(false);
  return mapFilters
}

@Mutation
public setMapFilters (mapFilters: InstallationsFilterDto[]): void {
  this.mapFilters = mapFilters
}

@Action({ commit: 'setAppointmentsFilters'})
async getAppointmentsFilters (): Promise<InstallationsFilterDto[]> {
  const filterApi = new FilterApi()
  const appointmentsFilters = await filterApi.getAppointmentsFilters();
  return appointmentsFilters
}

@Mutation
public setAppointmentsFilters (appointmentsFilters: any[]): void {
  this.appointmentsFilters = appointmentsFilters
}

@Action({ commit: 'setDashboardTasksFilters'})
async getDashboardTasksFilters (): Promise<any[]> {
  const filterApi = new FilterApi()
  const dashboardTasksFilters = await filterApi.getDashboardTasksFilter();
  return dashboardTasksFilters
}

@Mutation
public setDashboardTasksFilters (dashboardTasksFilters: any[]): void {
  this.dashboardTasksFilters = dashboardTasksFilters
}

@Action({ commit: 'setCalendarFilters'})
async getCalendarFilters (): Promise<any[]> {
  const filterApi = new FilterApi()
  const calendarFilters = await filterApi.getCalendarFilters();
  return calendarFilters
}

@Mutation
public setCalendarFilters (calendarFilters: any[]): void {
  this.calendarFilters = calendarFilters
}

@Action({ commit: 'setTaskFilters'})
async getTasksFilters (): Promise<TaskFilterDto[]> {
  const filterApi = new FilterApi()
  const tasksFilters = await filterApi.getTasksFilter();
  this.setIsLoading(false);
  return tasksFilters
}

@Mutation
public setTaskFilters (tasksFilters: TaskFilterDto[]): void {
  this.tasksFilters = tasksFilters
}

@Mutation
public setIsLoading(isLoading: boolean): void {
  this.isLoading = isLoading
}

}