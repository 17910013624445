
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { commonModule, itemsModule } from '@/store/modules/store-accessor'
import { TimeUtil } from '@/helpers/TimeUtil'

@Component
export default class InstallationsItems extends Vue {
  @Prop() itemsExist!: boolean;
  @Prop() itemsList!: any[];
  @Prop() installation!: any;
  @Prop() item!: any;
  @Prop() subHeaderMsg!: string;
  @Prop() emptyMessage!: string;

  tableOptions = {sortBy: ['is_primary', 'is_active'], sortDesc: [true, true]}
  
  tableheaders: any [] = [
    { class:'font-weight-black', text: this.$t("type"), sortable: false, value: 'type_name'},
    { class:'font-weight-black', text: this.$t("make"), sortable: false, value: 'make' },
    { class:'font-weight-black', text: this.$t("model"), sortable: false, value: 'model' },
    { class:'font-weight-black', text: this.$t("serial_number"), sortable: false, value: 'serial_no' },
    { class:'font-weight-black', text: this.$t("is_active"), sortable: false, value: 'is_active' },
    { class:'font-weight-black', text: this.$t("primaryItem"), value: 'is_primary', width: '85px', sortable: false },
    { class:'', text: '', value: 'data-table-expand' }, // Move expand arrow to the right
  ]

  formatDate (date: Date): string {
    return TimeUtil.formatDate(date)
  }

  formatDateTime (date: Date): string {
    return TimeUtil.formatDateTime(date)
  }

  addInstallationItem(){
    commonModule.showModal({ name: 'add-installation-item-modal', payload: { installation: this.installation }})
  }


  editInstallationItem (item: any){
    commonModule.showModal({ name: 'edit-installation-item-modal', payload: { installation_item: item, installation: this.installation }})
  }

  deleteInstallationItem (item: any){
    commonModule.showModal({ name: 'delete-installation-item-modal', payload: { installation_item: item, installation: this.installation }})
  }
}
