
import { Component, Vue, Prop } from 'vue-property-decorator'
import { commonModule, materialsModule } from '@/store/modules/store-accessor'

@Component
export default class DeleteMaterialModal extends Vue {
  @Prop()
  payload!: any;

  isDeletingMaterial = false;

  async hideModal () {
    commonModule.hideModal();
  }

  async mounted() {
    commonModule.initSnackbar({});
  }

  doDeleteMaterial () {
        this.isDeletingMaterial = true;
        materialsModule.deleteMaterial(this.payload.material);
        this.hideModal();
  }
};
