import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators'
import MapApi from '@/api/map-api'

@Module({ namespaced: true, name: 'map' })
export default class MapModule extends VuexModule {
  public markers: MapDto[] = [];
  public markersCount = 0;
  public isLoading = true;
  public filters = "";

  @Action({ commit: 'setMarkers'})
  async getMarkersAction (query: string): Promise<MapDto[]> {
    this.setMarkers([]);
    const mapApi = new MapApi()
    const markers = await mapApi.getAllMapMarkers(query)
    if (markers.length == 0) {
      this.setIsLoading(false);
    }
    return markers
  }

  @Mutation
  public setMarkers (markers: MapDto[]): void {
    markers.map(item => {
      item.address = `${item.address}`
      item.coord = [item.latitude, item.longitude]
    })
    this.markers = markers
  }

  @Mutation
  public clearMarkers (): void {
    this.markers = []
  }

  @Mutation
  public setIsLoading(isLoading: boolean): void {
    if (this.isLoading !== isLoading) {
      this.isLoading = isLoading
    }
  }

  @Mutation
  public setFilters(filters: string): void {
    this.filters = filters
  }
}