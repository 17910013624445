import { BaseApi } from '@/helpers/base-api'

export default class ActionTypesApi extends BaseApi {
  constructor () {
    super('/api/v1/actionTypes')
    this.errorMessage = 'There was an issue when trying to fetch the actions types. If this error persists, please contact our support.'
  }

  public getAllActionTypesCombo = (): Promise<any[]> => this.instance.get('')

  public createActionType = (data: Record<string, unknown>): Promise<JSON> => this.instance.post('', data)

  public updateActionType = (actionTypeId: string, data: Record<string, unknown>): Promise<JSON> => this.instance.patch(`/${actionTypeId}`, data)

  public deleteActionType = (actionTypeId: string): Promise<JSON> => this.instance.delete(`/${actionTypeId}`)

  public actionTypesCombo = (): Promise<any[]> => this.instance.get('combo')

  public getActionTypeById = (actionTypeId: string): Promise<ActionsTypeDto[]> => this.instance.get(`/${actionTypeId}`)
}
