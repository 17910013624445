
    import { Component, Vue, Prop } from 'vue-property-decorator'
    import { commonModule, appointmentsModule } from '@/store/modules/store-accessor'
    import FileSaver from 'file-saver';
    import dayjs from 'dayjs';
    import utc from 'dayjs/plugin/utc';

    dayjs.extend(utc);  

    @Component
    export default class ExportCalendarModal extends Vue {
      @Prop()
      payload!: any;
      isDownloadingZip = false;

      selectedAssignees: any[] = [];
      assignees: any = [];

      tableheaders: any [] = [
        { text: this.$t("selectAll"), sortable: true, class: 'row-style secondary', value: 'name' },
      ]

      dateExportRange() {
        return dayjs(this.payload.day.date_from).format('DD-MM-YYYY');
      }

      async hideModal () {
        commonModule.hideModal();
      }
  
      async mounted () {
        this.assignees = localStorage.getItem('calendar_type') == 'category' ? commonModule.techniciansCombo : commonModule.personnelCombo;
        commonModule.initSnackbar({});
      }

    async doDownloadZip () {
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        const utcOffset = dayjs().utcOffset();

        const fromDate = dayjs(this.payload.day.date_from).startOf('day');
        const toDate = dayjs(this.payload.day.date_from).endOf('day');
        const start = fromDate.toISOString();
        const end = toDate.toISOString();

        const assigneesIds = this.selectedAssignees.map(assignee => assignee.id);

        const exportData = {
          'date_from': start,
          'date_to': end,
          'utc_offset': utcOffset,
          'assignee_id': assigneesIds,
          'language': localStorage.getItem('lang'),
        };

        this.isDownloadingZip = true;
        const zip = await appointmentsModule.getCalendarAppointmentsExportAction(exportData);
        const file = new File([zip], `domX_for_business_calendar_export_${dayjs(this.payload.day.date_from).format('DD_MM_YYYY')}.zip`);
        FileSaver.saveAs(file);
        this.isDownloadingZip = false;
        this.hideModal();
      }
    }
  };
  