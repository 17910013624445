
import { Component, Vue, Prop } from 'vue-property-decorator'
import { commonModule, installationsModule } from '@/store/modules/store-accessor'

@Component
export default class EditInstallationFileModal extends Vue {
      @Prop()
      payload!: any;

      isUpdating = false;

      editFileModel = {
        fileId: this.payload.file.id,
        installationId: this.payload.file.installation_id,
        notes : this.payload.file.notes || '',
      }

      notesRule= [
        (val: any) => {
            if(val != null) return val.length <= 50 || this.$i18n.t("fileNotesRule");
            else return true;
        },
      ]

      async mounted() {
          commonModule.initSnackbar({});
      }
  
      async hideModal() {
          commonModule.hideModal();
      }

      async doEditInstallationFile() {
        if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
            this.isUpdating = true;
            await installationsModule.editInstallationFile(this.editFileModel);
            await installationsModule.getInstallationInfo(this.editFileModel.installationId);
            this.hideModal();
            this.isUpdating = false;
        }
    }
};
  