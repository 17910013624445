
import { Component, Vue, Prop } from 'vue-property-decorator'
import { commonModule, installationsModule } from '@/store/modules/store-accessor'

@Component
export default class DeleteInstallationModal extends Vue {
  @Prop()
  payload!: any;

  isDeletingInstallation = false;

  async mounted() {
    commonModule.initSnackbar({});
  }

  async hideModal () {
    commonModule.hideModal();
  }

  async hideSideView() {
    commonModule.hideSideview();
  }

  async doDeleteInstallation () {
    this.isDeletingInstallation = true;
    await installationsModule.deleteInstallation(this.payload.installation);
    this.hideModal();
    this.hideSideView();
  }
};
