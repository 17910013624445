
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import ApexChart from "vue-apexcharts";

@Component({
  components: { ApexChart },
})
export default class DashboardCardPieChart extends Vue {
  @Prop() readonly title: string;
  @Prop() readonly chart_options: any;
  @Prop() readonly chart_series: any;
  @Prop() readonly isLoading: boolean;
  @Prop() readonly colWidth: any;
  @Prop() readonly chartHeight: any;
}
