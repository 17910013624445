import { BaseApi } from '@/helpers/base-api'

export default class ClientApi extends BaseApi {
  constructor () {
    super('/api/v1/clients')
    this.errorMessage = 'There was an issue when trying to fetch the clients. If this error persists, please contact our support.'
  }

  public getAllClients = (query: string): Promise<ClientDto[]> => this.instance.get(`${query}`)
  public getClientCount = (): Promise<CountDto[]> => this.instance.get('/count')
  public getClient = (clientId: string): Promise<ClientDetailsDto> => this.instance.get(`/${clientId}`)
  public getClientInstallations = (clientId: string): Promise<any> => this.instance.get(`/${clientId}/installations`)
  
  public createClient = (data: Record<string, unknown>): Promise<any> => this.instance.post('', data)
  public createClientContact = (clientId: string, data: Record<string, unknown>): Promise<string> => this.instance.post(`/${clientId}/contacts`, data)

  public updateClient = (clientId: string, data: Record<string, unknown>): Promise<string> => this.instance.patch(`/${clientId}`, data)
  public updateClientContact = (clientId: string, clientContactId: string, data: Record<string, unknown>): Promise<string> => this.instance.patch(`/${clientId}/contacts/${clientContactId}`, data)

  public deleteClient = (clientId: string): Promise<string> => this.instance.delete(`/${clientId}`)
  public deleteClientContact = (clientId: string, clientContactId: string): Promise<string> => this.instance.delete(`/${clientId}/contacts/${clientContactId}`)

}
