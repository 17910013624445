
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { commonModule, clientsModule } from '@/store/modules/store-accessor'
import _ from 'lodash';
import { primaryContactExists } from '@/helpers/data';

@Component
export default class EditClientModal extends Vue {
  @Prop()
  payload!: any;

  isEditingClient = false;

  get currClient() {
    let client = _.cloneDeep(clientsModule.currentClient);
    return client;
  }

  copyCurrClient(currentClientContacts: any) {
    if(currentClientContacts !== undefined){
      for (let i = 0; i < currentClientContacts.length; i++) {
        currentClientContacts[i].contact_type = this.$t(currentClientContacts[i].contact_type);
      }
      return [...currentClientContacts]
    }
    else{
      return []
    }
  }

  clientModel = {
    firstname: this.currClient.info.firstname, 
    lastname:this.currClient.info.lastname,
    email: this.currClient.info.email,
    id: this.currClient.info.id,
    contacts: this.copyCurrClient(this.currClient.contacts),
    view: this.payload.view,
    description: this.payload.description,
    notes: this.payload.client.notes,
    entity_name: this.payload.client.entity_name || '',
    vat: this.payload.client.vat || '',
    tax_office: this.payload.client.tax_office || '',
    address: this.payload.client.address || '',
    profession: this.payload.client.profession || '',
    is_business: this.payload.client.is_business || false,
  }

  get contactTypeCombo() {
    for (let i = 0; i < commonModule.contactTypeCombo.length; i++) {
      (commonModule.contactTypeCombo[i]["contact_info_type"] as any) = this.$t(commonModule.contactTypeCombo[i]["contact_info_type"]);
    }
    return commonModule.contactTypeCombo;
  }

  firstnameRule= [
    (val: any) => !!val || this.$i18n.t("firstname_required"),
    (val: any) => val.length <= 45 || this.$i18n.t("client_form_name_length_rule"),
  ]
  lastnameRule= [
    (val: any) => !!val || this.$i18n.t("lastname_required"),
    (val: any) => val.length <= 45 || this.$i18n.t("client_form_lastname_length_rule"),
  ]
  emailRule:any =[
    (val: any) => {
      if(val != null) return val.length <= 70 || this.$i18n.t("client_form_email_length_rule");
      else return true;
    },
    (val: any) => {
      //eslint-disable-next-line
      const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
      if(val != null && val.length > 0) return reg.test(val) || this.$i18n.t("form_email_rule");
      else return true;
    },
  ]
  contactTypeRule= [
    (val: any) => !!val && val.contact_info_type != "" || this.$i18n.t("contact_type_required")
  ]
  phoneRule= [
    (val: any) => !!val || this.$i18n.t("phone_required"),
    (val: any) => val.replace(/ /g, '').replace(/-/g, '').length <= 20 || this.$i18n.t("client_form_phone_length_rule"),
    (val: any) => {
      //eslint-disable-next-line
      let reg = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,14}$/im;
      if(val.length > 0) return reg.test(val.replace(/ /g, '').replace(/-/g, '')) || this.$i18n.t("form_phone_rule");
      else return true;
    },
  ]
  descriptionRule= [
    (val: any) => {
      if(val != null) return val.length <= 80 || this.$i18n.t("client_form_description_length_rule");
      else return true;
    },
  ]
  notesRule= [
    (val: any) => {
      if(val != null) return val.length <= 1000 || this.$i18n.t("notesLengthRule");
      else return true;
    },
  ]
  entityNameRule = [
    (val: any) => !!val || this.$i18n.t("entityNameRequired"),
    (val: any) => val.length <= 100 || this.$i18n.t("entityNameRule"),
  ]
  vatRule = [
    (val: any) => !!val || this.$i18n.t("vatRequired"),
    (val: any) => val.length <= 14 || this.$i18n.t("vatRule"),
  ]

  onCheckboxChange(ev: any, item: any) {
    if(ev === true) {
        this.clientModel.contacts.forEach((element: any) => 
        {
          if (element.id === item.id) {
            element.is_primary = true;
          }
          else {
            element.is_primary = false;
          }
        });
    }

  }

  async hideModal () {

    commonModule.hideModal();
  }

  async mounted() {
    commonModule.initSnackbar({});
    await commonModule.getContactTypeCombo();
    if(this.payload.contacts.length === 0) {
      this.addPhone();
    }
  }

  addPhone() {
    let newContact = {
        id: Math.random() * -1, // Hack...Need Random and non-integer and also negative, for this to work with the backend properly
        contact_type: (this.contactTypeCombo[1] as any),
        contact_type_id: 0,
        is_primary: false,
        description: '',
        phone: ''
    } as any

    if (this.clientModel.contacts.length == 0) {
      newContact.is_primary = true;
    }
    this.clientModel.contacts.push(newContact);
  }

  deletePhone(index: number) {
    if (this.clientModel.contacts.length > 1) {
      this.clientModel.contacts.splice(index, 1);
      if (!primaryContactExists(this.clientModel)) {
        this.clientModel.contacts[0].is_primary = true;
      }
    } else if (this.clientModel.contacts.length === 1) {
      this.clientModel.contacts[0].is_primary = true;
    }
  }

  doEditClient () {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      this.isEditingClient = true;
      this.clientModel.contacts.map((item) => {
        if(item.contact_type.id !== undefined) {
          item.contact_type_id = item.contact_type.id
        } 
        item.phone = item.phone.replace(/ /g, '').replace(/-/g, '')
      })
      
      clientsModule.editClient(this.clientModel);
      this.hideModal();
    } 
  }
};
