
import { Component, Vue, Prop } from 'vue-property-decorator'
import { commonModule, expensesModule } from '@/store/modules/store-accessor'

@Component
export default class DeleteExpenseModal extends Vue {
  @Prop()
  payload!: any;

  isDeletingExpense = false;

  async hideModal () {
    if(this.payload.expense.secondModal) {
      commonModule.hideSecondModal();
      commonModule.hideModal();
    } else {
      commonModule.hideModal();
    }
  }
  
  async hideModalOnCancel() {
      this.payload.expense.secondModal ? commonModule.hideSecondModal() : commonModule.hideModal();
    }

  async mounted() {
    commonModule.initSnackbar({});
  }

  async doDeleteExpense () {
    this.isDeletingExpense = true;
    await expensesModule.deleteExpense(this.payload.expense);
    this.hideModal();
  }
};
