import dayjs from "dayjs";

export abstract class TimeUtil {
  public static formatDateTime (date: Date): string {
    return dayjs(date).format('MMM DD, YYYY HH:mm')
  }

  public static formatDate (date: Date): string {
    return dayjs(date).format('MMM DD, YYYY')
  }

  public static formatDateOnly (date: Date): string {
    if (date) {
      return dayjs(date).format('YYYY-MM-DD') 
    } else {
      return ""
    }
  }

  public static formatTimeOnly (date: Date): string {
    if (date) {
      return dayjs(date).format('HH:mm') 
    } else {
      return ""
    }
  }

  public static formatUnixTS (unixTs: number): string {
    return dayjs.unix(unixTs).format('MMM DD, YYYY HH:mm')
  }
}
