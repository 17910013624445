<!--
 Copyright 2022 by domX Private Company

 All rights reserved,
 also regarding any disposal, exploitation, reproduction,
 editing, distribution, as well as in the event of
 applications for industrial property rights.

 This software contains proprietary and confidential
 information of domX Private Company.
 The disclosure of confidential information without
 express authorization is prohibited.

 This software may include Open Source Software.
 As far as Open Source Software is included,
 your rights for the respective Open Source Software
 components are governed by the applicable
 Open Source Software licenses.
-->

<template class="fill-height">
    <v-card
          flat
          class="secondary fill-height pa-12"
          height="25%">
            <v-form
              ref="form"
              lazy-validation
            >
            <v-list class="secondary" max-width="1000px">
            <v-subheader class="display-1 grayBold--text">
                {{$t("addFile")}}
            </v-subheader>
            <v-list-item>
                    <v-list-item-content class="grayBold--text">
                    <v-list-item-title class="subtitle-2 grayBold--text">
                    </v-list-item-title>
                    <v-flex xs12 sm12 md12 style="width: 700px;">
                        <v-file-input
                        v-model="file"
                        prepend-inner-icon="mdi-paperclip"
                        prepend-icon=""
                        show-size
                        accept="application/zip, application/pdf, application/msword, text/csv"
                        :label="$t('file')"
                        :placeholder="$t('file')"
                        light
                        :rules="fileRule"
                        :loading="isUploading"
                        :disabled="disabled"
                        ></v-file-input>
                    </v-flex>
                    <v-flex xs12 sm12 md12 style="width: 700px;">
                        <v-textarea
                          light
                          v-model="notes"
                          :label="$t('fileNotes')"
                          auto-grow
                          append-icon="mdi-text-box-edit-outline"
                          rows="1"
                          :counter="50"
                          :rules="notesRule"
                        ></v-textarea>
                      </v-flex>
                </v-list-item-content>
            </v-list-item>
            </v-list>
            <v-card-actions>
            <v-spacer/>
            <v-btn
                depressed
                right
                light
                color="grayBold--text secondary"
                @click="hideModal()"
            >
                {{$t("cancel")}} 
            </v-btn>
            <v-btn
                color="primary"
                depressed
                right
                large
                class="modal-update"
                @click="doAddInstallationFile"
                :loading="isUploading"
                ><v-icon left>mdi-plus</v-icon>{{$t("add")}}</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
    </template>
    
    <script>
    import { Component, Vue, Prop } from 'vue-property-decorator'
    import { commonModule, installationsModule } from '@/store/modules/store-accessor'
    
    @Component
    export default class AddFileToInstallationModal extends Vue {
        @Prop()
        payload;
        tab;

        file = null;
        notes = '';

        isUploading = false;
        disabled = false;

        async mounted() {
            commonModule.initSnackbar({});
        }
    
        async hideModal() {
            commonModule.hideModal();
        }
    
        fileRule = [
            val => !!val || this.$t('fileRequired'),
            val => !val || val.size <= 5000000 || this.$i18n.t("fileInputSizeRule"), // 5mb rule
        ]
        notesRule = [
            (val) => val.length <= 50 || this.$i18n.t("fileNotesRule"),
        ]

        async doAddInstallationFile() {
            if (this.$refs.form.validate()) {
                this.isUploading = true;
                this.disabled = true;

                let formData = new FormData();
                formData.append('file', this.file);
                formData.append('notes', this.notes);

                await installationsModule.addInstallationFile({installationId: this.payload.installation.info.id, file: formData});
                await installationsModule.getInstallationInfo(this.payload.installation.info.id);
                this.hideModal();

                this.isUploading = false;
                this.disabled = true;
            }
        }
    };
    </script>