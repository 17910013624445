
  import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
  import { commonModule, itemTypesModule } from '@/store/modules/store-accessor'
  
  @Component
  export default class EditItemTypeModal extends Vue {
    @Prop()
    payload!: any;

    isEditingItemType = false;

    itemTypeModel = {
        id: this.payload.itemType.id,
        text: this.payload.itemType.text,
    }

    textRule = [
        (val: any) => !!val || this.$i18n.t("itemTextRequired"),
        (val: any) => val.length <= 30 || this.$i18n.t("itemTypeTextLengthRule"),
    ]

    async hideModal () {
      commonModule.hideModal();
    }
  
    async mounted () {
      commonModule.initSnackbar({});
      itemTypesModule.getItemTypes();
    }

    get allItemTypes() {
      return itemTypesModule.itemTypes;
    }

    typeFound(type: any) {
      let excludeCurrentType = this.allItemTypes.filter(type => type['text'] !== this.payload.itemType.text)
      for (let i = 0; i < excludeCurrentType.length; i++) {
        if (excludeCurrentType[i]['text'] === type) {
          return true;
        }
      }
      return false;
    };

    @Watch('itemTypeModel.text')
    onTextChange(val: string, oldVal: string) {
      // Remove typeFound (duplicate) rule if it already exists from a previous check
      if (this.textRule.length > 2) {
        this.textRule.pop();
      }
      if (this.typeFound(this.itemTypeModel.text)) {
        this.textRule.push((val: any) => this.typeFound(val.text) || this.$i18n.t("itemExists"))
      }
    }
  
    async doUpdateItemType() {
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        this.isEditingItemType = true;
        await itemTypesModule.editItemType(this.itemTypeModel);
        await itemTypesModule.getItemTypes();
        this.hideModal();
      }
    }

  };
