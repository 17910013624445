
import { Component, Vue, Prop } from 'vue-property-decorator'
import vClickOutside from 'v-click-outside'

@Component({
  directives: {
    clickOutside: vClickOutside.directive
  }
})
export default class FilterDropdown extends Vue {
  @Prop()
  filterName!: string;

  @Prop()
  filterType!: string;

  @Prop()
  selectedFilter!:string[];

  @Prop()
  filters!: { name: string; count: number }[];

  showDropdown = false;

  get selectedStatus (): string[] {
    if (typeof this.selectedFilter === 'string') {
      return [this.selectedFilter]
    }
    return this.selectedFilter
  }

  get readableFilter (): string {
    if (typeof this.selectedFilter === 'string') {
      return this.selectedFilter ? this.selectedFilter : 'All'
    }
    return this.selectedFilter && this.selectedFilter.length > 0 ? this.selectedFilter.join() : 'All'
  }

  changeStatus (event: { detail: { checked: boolean; value: string } }): void {
    let newValue: string[]
    if (event.detail.checked) {
      newValue = [event.detail.value]
    } else {
      newValue = []
    }

    this.$emit('update-filter', this.filterType, newValue)
  }

  changeFilter (event: { detail: { checked: boolean; value: string } }): void {
    let newValue: string[]
    if (event.detail.checked) {
      newValue = [...this.selectedFilter, event.detail.value]
    } else {
      newValue = this.selectedFilter.filter((filter) => filter !== event.detail.value)
    }

    this.$emit('update-filter', this.filterType, newValue)
  }

  clearFilter (): void {
    this.selectedFilter = []
    this.$emit('update-filter', this.filterType, [])
  }

  clickOutside (): void {
    this.showDropdown = false
  }
}
