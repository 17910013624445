import { BaseApi } from '@/helpers/base-api'

export default class DashboardApi extends BaseApi {
  constructor () {
    super('/api/v1/dashboard')
    this.errorMessage = 'There was an issue when trying to fetch the dashboard. If this error persists, please contact our support.'
  }

  public getDashboardAppointments = (query: string): Promise<any> => this.instance.get(`/stats/appointments${query}`)
  public getDashboardAppointmentsBars = (query: string): Promise<any> => this.instance.get(`/stats/appointments/bars${query}`)
  public getDashboardAppointmentsCompleted = (): Promise<any> => this.instance.get(`/appointments/completed`)
  public getDashboardTasksPending = (query: string): Promise<any> => this.instance.get(`/tasks/pending${query}`)
}