
import { Component, Vue, Prop } from 'vue-property-decorator'
import { commonModule, appointmentsModule } from '@/store/modules/store-accessor'

@Component
export default class DeleteAppointmentModal extends Vue {
  @Prop()
  payload!: any;

  isDeletingAppointment = false;

  async hideModal () {
    commonModule.hideModal();
  }

  async mounted() {
    commonModule.initSnackbar({});
  }

  doDelete () {
    this.isDeletingAppointment = true;
    appointmentsModule.deleteAppointment(this.payload.appointment);
    this.hideModal()
  }
};
