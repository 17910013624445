
  import { Component, Vue, Prop } from 'vue-property-decorator'
  import { commonModule, companyModule } from '@/store/modules/store-accessor'
  
  @Component
  export default class ToggleCompanyPreferencesModal extends Vue {
    @Prop()
    payload!: any;
    isRestoringType = false;
    preferencesFlag = false;

    preference = this.payload.key;
  
    async hideModal () {
      await companyModule.getCompanyInfo();
      commonModule.hideModal();
    }

    beforeMount() {
        this.preferencesFlag = (companyModule.company?.preferences as any)[this.preference];
    }

    async updatePreferences() {
      await companyModule.getCompanyInfo();
      this.hideModal()
    }
  
     async toggleInstallationDescription () {
        this.isRestoringType = true;
         await companyModule.editCompany({id: companyModule.company.id, [this.preference]: this.preferencesFlag});
        this.hideModal()
    }
  };
