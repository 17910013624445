
  import { Component, Vue, Prop } from 'vue-property-decorator'
  import { commonModule, dashboardModule, tasksModule } from '@/store/modules/store-accessor'
  
  @Component
  export default class DeleteTaskModal extends Vue {
    @Prop()
    payload!: any;

    isDeletingTask = false;
  
    async hideModalOnCancel() {
      this.payload.isOrphanTask ? commonModule.hideSecondModal() : commonModule.hideModal();
    }

    async hideModal () {
      if(this.payload.isOrphanTask) {
        commonModule.hideSecondModal();
        commonModule.hideModal();
      } else {
        commonModule.hideModal();
      }
    }
  
    async mounted() {
      commonModule.initSnackbar({});
    }
  
    doDelete () {
        this.isDeletingTask = true;
        tasksModule.deleteTask(this.payload.task);
        tasksModule.getTasks(tasksModule.filters);
        tasksModule.getPendingTasks();
        if(this.$route.name === 'Dashboard') {
          dashboardModule.getDashboardPendingTasks(dashboardModule.taskFilters);
        }
        this.hideModal()
    }
  };
