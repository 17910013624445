
  import { Component, Vue, Prop } from 'vue-property-decorator'
  import { commonModule, partnersModule } from '@/store/modules/store-accessor'
  
  @Component
  export default class ToggleIsActivePartnerModal extends Vue {
    @Prop()
    payload!: any;
    isArchivingType = false;
  
    async hideModal () {
      commonModule.hideModal();
    }

    async updateActionTypes() {
      await partnersModule.getAllPartners();
      this.hideModal()
    }
  
    async mounted() {
      commonModule.initSnackbar({});
    }
  
    async setArchiveFalse () {
        this.isArchivingType = true;
        await partnersModule.editPartner(this.payload.partner);
        await partnersModule.getAllPartners();
        this.hideModal()
    }
  };
