
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { commonModule, jobsModule, itemTypesModule } from '@/store/modules/store-accessor'
import { isDecimalNumber, getOptimalTextColor } from '@/helpers/data';
import AssigneeCombo from '@/components/Combobox/AssigneeCombo.vue';
import _ from 'lodash';
import dayjs from 'dayjs';

@Component({
  components: {
    AssigneeCombo,
  },
})
export default class AddMaterialsToJobModal extends Vue {
  @Prop()
  payload!: any;

  createdAtMenu = false;
  createAtTimeMenu = false;
  isAddingMaterial = false;
  
  jobMaterialsModel = {
    materials: [{
        id: Math.random() * -1, //Need Random and non-integer and also negative, for this to work with the backend properly
        cost: 0, //This needs to be initialized not null for the reduce to work
        description: '',
        notes: ''
    }] as any,
   assignee_id: this.getAssigneeId(this.payload.job.partner_uuid ? this.payload.job.partner_uuid : commonModule.user.uuid),
   installation_id: this.payload.job.installation_id,
   id: this.payload.job.id,
   cost_disabled: this.payload.job.cost_disabled,
   created_at_date: dayjs().format('YYYY-MM-DD'),
   created_at_time: dayjs().format('HH:mm'),
   partner_id: this.payload.job.partner_id || '',
  }

  userRule= [
    (val: any) => !!val || this.$i18n.t("user_required"),
  ]
  notesRule= [
    (val: any) => {
      if(val != null) return val.length <= 1000 || this.$i18n.t("notesLengthRule");
      else return true;
    },
  ]
  costRule:any =[
    (val: any) => val !== '' && val >= 0 || this.$i18n.t("cost_required")
  ]
  descriptionRule= [
      (val: any) => (val == null || ((val?.text?.length <= 80) || val?.length <=80 )) || this.$i18n.t("client_form_description_length_rule"),
  ]

  get itemTypesCombo () {
    return itemTypesModule.itemTypesCombo;
  }

  get assigneeIdCombo () {
      return commonModule.personnelCombo;
  }

  async hideModal () {
    commonModule.hideModal();
  }

  async mounted() {
    commonModule.initSnackbar({});
    await itemTypesModule.getItemTypesCombo();
  }

  updateAssignee(assignee: any) {
    if(assignee) {
      this.jobMaterialsModel.assignee_id = assignee;
    }
  }

  get jobTotalCost() {    
    let total = this.jobMaterialsModel.materials?.reduce((accumulator: number, item: any) => {return accumulator + (!isNaN(parseFloat(item.cost)) ? parseFloat(item.cost) : 0)}, 0);

    return total
  }

  optimalTextColor(color: string) {
    return getOptimalTextColor(color);
  }

  addMaterial() {
    let newMaterial = {
        id: Math.random() * -1,
        cost: 0, //This needs to be initialized not null for the reduce to work
        description: '',
        notes: ''
    } as any

    this.jobMaterialsModel.materials.push(newMaterial);
  }

  deleteMaterial(index: number) {
    if (this.jobMaterialsModel.materials.length > 1) {
      this.jobMaterialsModel.materials.splice(index, 1);
    }
  }

  async doAddJobMaterials () {
    this.isAddingMaterial = true;
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        
        let materialsObj = _.cloneDeep(this.jobMaterialsModel);

        materialsObj.materials = materialsObj.materials.map((material: any) => {
          if (typeof material.description === 'object' && material.description != null) {
            material.description = material.description.text
            return material
          }
          else {
            return material
          }
        })

        await jobsModule.createMaterialsToJob(materialsObj);
        this.hideModal()
    }  
    this.isAddingMaterial = false;
  }

  onlyDecimalNumbers(evt: any) {
    isDecimalNumber(evt);
  }

  getAssigneeId(uuid: string) {
      const combo: any = commonModule.personnelPartnersCombo;
      const foundItem = combo.find((item: any) => item.id === uuid);
      if(foundItem) {
        return { name: foundItem.name, id: foundItem.id };
      } else {
        return ''
      }
    }

};
