
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { commonModule, installationsModule } from '@/store/modules/store-accessor'
import { TimeUtil } from '@/helpers/TimeUtil'

@Component
export default class ClientsSideviewPaymentsTab extends Vue {
 @Prop() client: Record<string, any>;

  formatDate (date: Date): string {
    return TimeUtil.formatDateTime(date)
  }

  get paymentsExist() {
    return this.client.payments.length > 0
  }

  get clientPayments() {
    return this.client.payments;
  }

  installationTagFromId(installationId: number) {
    const found = this.client.installations.find((item:any) => {
      return item.id === installationId
    })
    return found == undefined ? found.tag_id : '1'
  }

};
