
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { TimeUtil } from '@/helpers/TimeUtil'
import { appointmentStatusToName, appointmentStatusToColor, calcDuration, getOptimalTextColor } from '@/helpers/data'
import { commonModule, installationsModule, actionTypesModule, itemsModule, companyModule } from '@/store/modules/store-accessor'

@Component
export default class InstallationsSideviewAppointmentsTab extends Vue {
  @Prop() installation: Record<string, any>;
  @Prop() appointmentId: number;


  tableOptions = {sortBy: ['appointment_status_id', 'actual_start'], sortDesc: [false, false]}

  tableheaders: any [] = [
    { class:'font-weight-black', text: this.$t("action"), sortable: false, value: 'type_name'},
    { class:'font-weight-black', text: this.$t("item"), sortable: false, value: 'job_item' },
    { class:'font-weight-black', text: this.$t("user"), sortable: false, value: 'assignee_names' },
    { class:'font-weight-black', text: this.$t("actual_start"), sortable: false, value: 'actual_start'},
    { class:'font-weight-black', text: this.$t("status"), sortable: true, value: 'appointment_status_id' },
    { class:'font-weight-black', text: this.$t("duration"), sortable: false, value: 'duration' },
    { class:'', text: '', value: 'data-table-expand' }, // Move expand arrow to the right
  ]

  expandedItems: any[] = [];

  mounted() {
    commonModule.getPersonnelCombo();
    commonModule.getPersonnelPartnersCombo();
    commonModule.getActionCombo();
    commonModule.getDurationCombo();
    actionTypesModule.getActionTypesCombo();

    let expansionAppointment = installationsModule.currentInstallation.appointments.find(i => i.id == this.appointmentId); //This is needed to find the index in the expansion panel
    if (expansionAppointment) {
      this.expandedItems = [expansionAppointment]
    }
  }

  @Watch("installation")
  updateAppointments() {
    commonModule.getPersonnelCombo();
    commonModule.getActionCombo();
    commonModule.getDurationCombo();
    commonModule.initSnackbar({});
  }

  //item.scheduled_start && item.scheduled_end ? setDuration(item.scheduled_start, item.scheduled_end) : "-"
  get appointments() {
    return this.installation.appointments
    //.map((appointment: Record<string, any>) => ({ ...appointment, duration: appointment.scheduled_start && appointment.scheduled_end ? calcDuration(appointment.scheduled_start, appointment.scheduled_end) : "-"  }))
    .sort((a: any, b: any) => {
      const c: any = new Date(a.scheduled_start);
      const d: any = new Date(b.scheduled_start);
      return d - c;
    });
  }

  get currInstallation () {
    return installationsModule.currentInstallation;
  }

  optimalTextColor(color: string) {
    return getOptimalTextColor(color);
  }

  getFormattedAppointmentDuration(appointment: any) {
    if (appointment.appointment_status_id == 4) {
      if (appointment.actual_start && appointment.actual_end) {
        return this.setDuration(appointment.actual_start, appointment.actual_end);
      }
      else {
        return '-';
      }
    }
    else {
      if (appointment.scheduled_start && appointment.scheduled_end) {
        return this.setDuration(appointment.scheduled_start, appointment.scheduled_end);
      }
      else {
        return '-';
      }
    }

  }

  appointmentIsComplete(appointment: any) {
    if(appointment.appointment_status_id == 4) 
      return true;
    else
      return false;
  }

  addAppointment () {
    commonModule.showModal({ name: 'add-appointment-modal', payload: { installation: this.installation}})
  }

  editAppointment (appointment: any){
    commonModule.showModal({ name: 'edit-appointment-modal', payload: { appointment: appointment }})
  }

  deleteAppointment (appointment: any){
    commonModule.showModal({ name: 'delete-appointment-modal', payload: { appointment: appointment }})
  }

  endAppointment (appointment: any){
    commonModule.showModal({ name: 'end-appointment-modal', payload: { appointment: appointment, installation: this.currInstallation }})
  }

  cancelAppointment (appointment: any){
    commonModule.showModal({ name: 'cancel-appointment-modal', payload: { appointment: appointment }})
  }

  formatDate (date: Date): any {
    if (date == undefined || date == null) {
      return '-'
    } else {
      return TimeUtil.formatDateTime(date)
    }
  }

  statusToName(status: string) {
    return appointmentStatusToName(status)
  }

  statusToColor(status: string){
    return appointmentStatusToColor(status)
  }

  setDuration(start: Date, end: Date) {
    return calcDuration(start, end);
  }

  get jobDescriptionIsEnabled() {
    return (companyModule.company?.preferences as any)?.job_description;
  }

};
