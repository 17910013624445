
import { formatDatesRangeText, sortDateRange } from "@/helpers/data";
import { commonModule, paymentsModule, companyModule } from "@/store/modules/store-accessor";
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import FilterList from "./FilterList.vue";
import DatePickerFilter from "./DatePickerFilter.vue";
import dayjs from "dayjs";
import KeycloakSession from "@/helpers/keycloak-session";

@Component({
  components: {
    FilterList,
    DatePickerFilter,
  },
})
export default class PaymentFilter extends Vue {

  pendingPaymentsOptions = [
    {text: this.$t("true"), value: true},
    {text: this.$t("false"), value: false}
  ];

  is_pending = [true, false];

  isAdmin () {
    const kc = new KeycloakSession()
    if(kc.userHasRole('admin')) {
      return true;
    }
    else if (kc.userHasRole('editor')) {
      return false;
    }
  }

  get assigneeDropdownLabel() {
    if(companyModule.company?.modules?.includes('DFB_PORTAL_PARTNERS')) {
      return 'userPartner';
    }
    else {
      return 'user';
    }
  }

  get personnelUserNameFilter() {
    if(companyModule.company?.modules?.includes('DFB_PORTAL_PARTNERS')) {
      return commonModule.personnelPartnersCombo?.map((obj) => ({...obj, icon: obj?.is_partner ? 'mdi-account-multiple' : 'mdi-account'}))
    }
    else {
      return commonModule.personnelCombo?.map((obj) => ({...obj, icon: obj?.is_partner ? 'mdi-account-multiple' : 'mdi-account'}));
    }
  }

  get paymentOptions() {
    for (let i = 0; i < commonModule.paymentTypeCombo.length; i++) {
        (commonModule.paymentTypeCombo[i]["payment_type"] as any) = this.$t(commonModule.paymentTypeCombo[i]["payment_type"]);
      }
    return commonModule.paymentTypeCombo;
  }

  fincTypesOptions = [
    {text: this.$t("payment"), value: 'PAYMENT'},
    {text: this.$t("expense"), value: 'EXPENSE'}
  ];

  selectedUsers: string[];
  selectedPaymenentsTypes: string[];
  selectedDates: any = [dayjs().startOf('day').toISOString(), dayjs().endOf('day').toISOString()] // Set default date range to "today"
  selectedPending: string;
  dateRangeLength = this.isAdmin() ? 2 : 1; 
  selectedFincType: string[];

  updateUsers(data: string[]) {
    this.selectedUsers = data;
    this.submit();
  }

  updateDates(data: any[]) {
    this.selectedDates = data;
    if(this.isAdmin() && (!this.selectedDates || this.selectedDates.length == this.dateRangeLength)) {
      this.selectedDates = data;
      this.submit();
    } else if (!this.isAdmin() && (!this.selectedDates || this.selectedDates.length == this.dateRangeLength)) {
      this.selectedDates = data ? [data, data] : data;
      this.submit();
    }
  }

  updatePaymentTypes(data: any) {
    this.selectedPaymenentsTypes = data;
    this.submit();
  }

  updatePendingPayments(data: any) {
    if (data != undefined || data != null) {
      data = data.value;
    }
    this.selectedPending = data; // for when pending filter is empty
    this.submit();
  }

  updateFincType(data: any) {
    this.selectedFincType = data;
    this.submit();
  }

   async submit() {
    paymentsModule.setIsLoadingTable(true);
    let queryObj: any = {};

    let userIds = [];
    let tempId;

    let paymentTypesIds = [];

     if (this.selectedUsers != undefined) {
       for (let i = 0; i < this.selectedUsers.length; i++) {
         tempId = (this.selectedUsers[i] as any).id
         userIds.push(tempId);
       }
     }

     if (this.selectedPaymenentsTypes !== undefined) {
      const paymentTypesIds = this.selectedPaymenentsTypes.map(paymentType => (paymentType as any).id);

      if (paymentTypesIds !== undefined && paymentTypesIds.length > 0) {
        queryObj["payment_type[]"] = paymentTypesIds;
      }
    }

    if (userIds != undefined && userIds.length > 0) {
      queryObj["assignee_id[]"] = userIds;
    }

    if (paymentsModule.search != '') {
      paymentsModule.setSearch(paymentsModule.search);
      queryObj["search"] = paymentsModule.search;
      }

    // admin role
    if (this.isAdmin() && this.selectedDates != null && this.selectedDates.length === 2) {
      sortDateRange(this.selectedDates);
      queryObj["date_from"] = dayjs(this.selectedDates[0]).startOf('day').toISOString();
      queryObj["date_to"] = dayjs(this.selectedDates[1]).endOf('day').toISOString();
    }

    // editor role
    if (!this.isAdmin() && this.selectedDates != null && this.selectedDates.length === 2) {
       sortDateRange(this.selectedDates);
       queryObj["date_from"] = dayjs(this.selectedDates[0]).startOf('day').toISOString();
       queryObj["date_to"] = dayjs(this.selectedDates[0]).endOf('day').toISOString();
    }

    if(this.selectedPending != undefined && this.selectedPending.length > 0) {
      queryObj["isPending"] = this.selectedPending;
    }

    if (this.selectedFincType != undefined) {
      queryObj["finc_type"] = (this.selectedFincType as any).value;
    }

    this.$router.replace({ query: queryObj }).catch(err => {/* */ });

    // Check if user selected any of the queries
    const queryIndex = this.$route.fullPath.indexOf('?');
    if (queryIndex >= 0) {
      const query = this.$route.fullPath.substring(queryIndex);
      paymentsModule.setFilters(query);
      paymentsModule.setQueryObject(queryObj);
    } else {
      paymentsModule.setFilters("");
      paymentsModule.setQueryObject(queryObj); // Will be empty ({}
    }

    await paymentsModule.getPaymentsAction(paymentsModule.filters);
    await paymentsModule.getPaymentsTotals(paymentsModule.filters);
    paymentsModule.setIsLoadingTable(false);
  }

}
