
  import { Component, Vue, Prop } from 'vue-property-decorator'
  import { commonModule, actionTypesModule } from '@/store/modules/store-accessor'
  
  @Component
  export default class ToggleArchiveActionTypeModal extends Vue {
    @Prop()
    payload!: any;
    isArchivingType = false;
  
    async hideModal () {
      commonModule.hideModal();
    }

    async updateActionTypes() {
      await actionTypesModule.getAllActionTypes();
      this.hideModal()
    }
  
    async mounted() {
      commonModule.initSnackbar({});
    }
  
    async setArchiveFalse () {
        this.isArchivingType = true;
        await actionTypesModule.editActionType(this.payload.actionType);
        await actionTypesModule.getAllActionTypes();
        this.hideModal()
    }
  };
