
  import { Component, Vue, Prop } from 'vue-property-decorator'
  import { commonModule, itemTypesModule } from '@/store/modules/store-accessor'
  
  @Component
  export default class ToggleArchiveItemTypeModal extends Vue {
    @Prop()
    payload!: any;
    isRestoringType = false;
  
    async hideModal () {
      commonModule.hideModal();
    }
  
    async mounted() {
      commonModule.initSnackbar({});
    }

    async updateItemTypes() {
      await itemTypesModule.getItemTypes();
      this.hideModal()
    }
  
    async setArchiveFalse () {
        this.isRestoringType = true;
        await itemTypesModule.editItemType(this.payload.itemType);
        await itemTypesModule.getItemTypes();
        this.hideModal()
    }
  };
