<!--
 Copyright 2022 by domX Private Company

 All rights reserved,
 also regarding any disposal, exploitation, reproduction,
 editing, distribution, as well as in the event of
 applications for industrial property rights.

 This software contains proprietary and confidential
 information of domX Private Company.
 The disclosure of confidential information without
 express authorization is prohibited.

 This software may include Open Source Software.
 As far as Open Source Software is included,
 your rights for the respective Open Source Software
 components are governed by the applicable
 Open Source Software licenses.
-->

<template class="fill-height">
  <v-card
      flat
      class="secondary fill-height pa-12"
      height="25%">
    <v-form
      ref="form"
      lazy-validation
    >
      <v-subheader class="display-1 grayBold--text">
        {{$t("add_installation")}}
      </v-subheader>
      <v-list class="secondary card-content" max-height="80vh" max-width="1000px">
        <v-list-item>
          <v-list-item-content class="grayBold--text">
            <v-list-item-title class="subtitle-2 grayBold--text">

            </v-list-item-title>
            <v-flex xs12 sm12 md12 v-if="descriptionIsEnabled">
                <v-textarea
                  light
                  v-model="installationModel.description"
                  :label="$t('description')"
                  auto-grow
                  rows="1"
                  :counter="80"
                  :rules="descriptionRule"
                ></v-textarea>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field prepend-inner-icon="mdi-qrcode" class="mr-5" min="1" type="number" required :rules="tagRule" light v-model="installationModel.tag" :label="$t('tag')" v-on:keypress="isNumber"/>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-autocomplete
                  light
                  class="grayBold--text"
                  prepend-inner-icon="mdi-account-search"
                  v-model="installationModel.client"
                  :items="clientsCombo"
                  item-text="name"
                  :label="$t('tenant')"
                  return-object
                  required 
                  :rules="clientRule"
                >
                <template v-slot:item="{ item }">
                  <v-icon class="mr-2"> {{ item.is_business ? 'mdi-domain' : 'mdi-account' }} </v-icon>
                  {{item.name}}
                </template>
                <template v-slot:no-data>
                          <div class="px-4">{{ $t('comboNoData') }}</div>
                        </template>
              </v-autocomplete>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-autocomplete
                  light
                  class="grayBold--text mr-5"
                  v-model="installationModel.owner"
                  :items="clientsCombo"
                  item-text="name"
                  :label="$t('owner')"
                  clearable
                  return-object
                >
                <template v-slot:item="{ item }">
                  <v-icon class="mr-2"> {{ item.is_business ? 'mdi-domain' : 'mdi-account' }} </v-icon>
                  {{item.name}}
                </template>
                <template v-slot:no-data>
                          <div class="px-4">{{ $t('comboNoData') }}</div>
                        </template>
              </v-autocomplete>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-layout align-center>
                  <vuetify-google-autocomplete
                  light
                  v-model="installationModel.address"
                  ref="google_address"
                  :label="$t('address')"
                  id="map"
                  classname="form-control"
                  v-on:placechanged="getAddressData"
                  country="gr"
                  types=""
                  clearable
                  :rules="addressRule"
                >
              </vuetify-google-autocomplete>
              <v-btn @click="openAddressModal" :ripple="false" icon color="grayBold">
                <v-icon>mdi-map-marker-outline</v-icon>
              </v-btn>
            </v-layout>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field class="mr-5" light required :rules="areaRule" v-model="installationModel.area" :label="$t('area')" />
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field light v-model="installationModel.zip_code" :rules="zipCodeRule" :label="$t('zip_code')" />
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field class="mr-5" min="-9" max="20" type="number" light v-model="installationModel.floor" :label="$t('floor')" v-on:keypress="isNumber"/>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-checkbox
                  light
                  dense
                  v-model="installationModel.is_premium"
                  label="Premium"
                ></v-checkbox>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-autocomplete light class="grayBold--text mr-5" :items="installationItemsCombo"
                item-text="name" :label="$t('installation_item')" return-object clearable v-model="installationModel.selected_item">
                <template v-slot:no-data>
                  <div class="px-4">{{ $t('comboNoData') }}</div>
                </template>
              </v-autocomplete>
            </v-flex>
            <v-flex xs12 sm6 md6>
              <v-text-field light append-icon="mdi-numeric" label="Serial No." v-model="installationModel.selected_item.serial_no" :rules="serialNoRule" />
            </v-flex>
              <v-flex xs12 sm6 md12>
                <v-textarea
                  light
                  v-model="installationModel.notes"
                  :label="$t('installationNotes')"
                  auto-grow
                  append-icon="mdi-text-box-edit-outline"
                  rows="1"
                  :counter="1000"
                  :rules="notesRule"
                ></v-textarea>
              </v-flex>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          depressed
          right
          light
          color="grayBold--text secondary"
          @click="hideModal()"
        >
          {{$t("cancel")}} 
        </v-btn>
        <v-btn
          color="primary"
          depressed
          right
          large
          class="modal-update"
          :loading="isAddingInstallation"
          @click="doAddInstallation"><v-icon left>mdi-plus</v-icon>{{$t("add")}}</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { commonModule, companyModule, installationsModule, itemsModule } from '@/store/modules/store-accessor'
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete';
import { setGoogleMapsInfoToModel } from "@/helpers/data";

@Component({})
export default class AddInstallationModal extends Vue {
  @Prop()
  payload

  isAddingInstallation = false;

  installationModel = {
   tag: this.installationNextTagId,
   client: {name: "", id: 0},
   owner: {name: "", id: 0},
   address: "",
   area: "",
   zip_code: "",
   floor: "",
   is_premium: false,
   notes: "",
   latitude: "",
   longitude: "",
   description: '',
   selected_item: {id: 0, name: '', item_type_id: 0, warranty: '', is_primary: false, is_active: false, serial_no: ''},
  }

  google_address

  tagRule= [
    (val) => !!val || this.$i18n.t("tag_required"),
  ]
  clientRule= [
    (val) => ((!!val || val == '') && val.name.length > 0) || this.$i18n.t("tenant_required"),
  ]

  areaRule= [
    (val) => !!val || this.$i18n.t("area_required"),
    (val) => val.length <= 30 || this.$i18n.t("form_area_length_rule"),
  ]

  addressRule= [
    (val) => val.length <= 100 || this.$i18n.t("form_address_length_rule"),
  ]

  zipCodeRule= [
    (val) => val.length <= 15 || this.$i18n.t("form_zipCode_length_rule"),
  ]

  notesRule= [
    (val) => val.length <= 1000 || this.$i18n.t("notesLengthRule"),
  ]

  descriptionRule = [
    (val) => val.length <= 80 || this.$i18n.t("installationDescriptionLengthRule"),
  ]

  serialNoRule = [
    (val) => {
      if (val === undefined || val === null) {
        return true;
      }
      return val.length <= 50 || this.$i18n.t("form_serialNumber_length_rule");
    },
  ];


  get clientsCombo () {
    return commonModule.clientsCombo.map((item) => {
      let client_arr = item.is_business
      ? [`${item.entity_name} (${item.name})`]
      : [`${item.name}`]
      return {...item, name: client_arr.filter(Boolean).join(' | ') }
    });
  }

  get installationNextTagId () {
      return installationsModule.installationNextTagId;
  }

  async hideModal () {
    commonModule.hideModal();
    installationsModule.clearNewInstallationCoordinates();
  }

  async mounted() {
    commonModule.initSnackbar({});
    await commonModule.getClientsCombo();
    await companyModule.getCompanyInfo();
    await itemsModule.getAllInventoryItems('');
  }

  doAddInstallation() {
    if (this.$refs.form.validate()) {
      this.isAddingInstallation = true;

      // Installation Item
      if(this.installationModel.selected_item.item_type_id) {
        this.installationModel.selected_item.is_primary = true;
        this.installationModel.selected_item.is_active = true;
      }

      installationsModule.addInstallation(this.installationModel);
      this.hideModal();
    }
  }

  get installationCooridnates() {
    return installationsModule.newInstallationCoordinates;
  }

  get installationItemsCombo () {
    return itemsModule.installationItems;
  }

  @Watch('installationCooridnates')
  updateCoordinates() {
    if(installationsModule.newInstallationCoordinates.length == 2) {
      setGoogleMapsInfoToModel(this.installationModel, installationsModule.newInstallationCoordinates[0], installationsModule.newInstallationCoordinates[1]);
    }
  }

  getAddressData(addressData) {
    this.google_address = addressData;
    this.installationModel.area = addressData.locality;
    this.installationModel.zip_code = addressData.postal_code ? addressData.postal_code.replace(/\s/g, "") : '';
    this.installationModel.latitude = addressData.latitude;
    this.installationModel.longitude = addressData.longitude;
  }

  isNumber(evt) {
      evt = evt || window.event;
      let charCode = evt.which || evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
      return false;
    }

  get descriptionIsEnabled() {
    return companyModule.company?.preferences?.installation_description;
  }

  async openAddressModal() {
    const coordinates = { latitude: this.installationModel.latitude, longitude: this.installationModel.longitude };
    commonModule.showSecondModal({ name: 'add-installation-address-modal', payload: { coordinates: coordinates } });
  }

};
</script>

<style scoped lang="scss">
.card-content {
  overflow: auto;
}
</style>
