
  import { Component, Vue } from 'vue-property-decorator'
  import { commonModule, installationsModule } from '@/store/modules/store-accessor'
  import QuickAddTask from './QuickAddTask/QuickAddTask.vue';
  import QuickAddTaskClient from './QuickAddTask/QuickAddTaskClient.vue';
  import QuickAddTaskInstallation from './QuickAddTask/QuickAddTaskInstallation.vue';
  
  @Component({
    components: { QuickAddTaskClient, QuickAddTaskInstallation, QuickAddTask },
  })
  export default class QuickAddTaskModal extends Vue {
  
    wizardStep = 1;
    task = {id: -1, title: '', assignee_id: '', task_status_id: -1, notes: '', action_type_id: null, due_at: '', installation_id: '' }; 
    client = { id: -1, firstname: '', lastname: '', entity_name: '', is_business: false};

    get clientName () {
      return this.client.is_business ? `${this.client.entity_name} (${this.client.firstname} ${this.client.lastname})` : `${this.client.firstname} ${this.client.lastname}`
    }

    get taskTitle () {
      return this.task.title
    }

    async hideModal () {
      commonModule.hideModal();
    }
  
    async mounted() {
      commonModule.initSnackbar({});
    }
  
    doClose () {
      this.hideModal()
      installationsModule.clearNewInstallationCoordinates();
    }
  
    doNextStep (val: any) {
      if (this.wizardStep == 1) {
        this.task = val;
      }
      else if (this.wizardStep == 2) {
        this.client = val;
      }
      
      this.wizardStep++;
    }
  
    doPrevStep () {
      if (this.wizardStep > 1) {
        this.wizardStep--;
  
        if (this.wizardStep == 1) {
          this.task = {id: -1, title: '', assignee_id: '', task_status_id: -1, notes: '', action_type_id: null, due_at: '', installation_id: '' };          
        }
  
        if (this.wizardStep == 2) {
          this.client = { id: -1, firstname: '', lastname: '', entity_name: '', is_business: false};
        }
      }
    }
  };
  