
import { Component, Vue, Prop } from 'vue-property-decorator'
import { commonModule, itemsModule, filterModule, itemTypesModule } from '@/store/modules/store-accessor'

@Component
export default class AddInventoryItemModal extends Vue {
  @Prop()
  payload!: any;

  isAddingItem = false;

  itemModel = {
   make:"",
   model: "",
   model_year: "",
   notes: "",
   warranty: "",
   item_type_id: {id: 0, text: ""}
  }

  get inventoryItemsCombo () {
    return itemTypesModule.itemTypesCombo;
  }

  get itemFilters() {
    return filterModule.itemFilters;
  }

  itemTypeRule= [
    (val: any) => (val || '').text.length > 0 || this.$i18n.t("item_type_required"),
  ]
  makeRule= [
    (val: any) => !!val || this.$i18n.t("make_required"),
  ]
  notesRule= [
    (val: any) => {
      if(val != null) return val.length <= 1000 || this.$i18n.t("notesLengthRule");
      else return true;
    },
  ]

  async mounted() {
    commonModule.initSnackbar({});
    await itemTypesModule.getItemTypesCombo();
    await filterModule.getItemFilters();
  }

  async hideModal () {
    commonModule.hideModal();
  }

  doAdd () {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      this.isAddingItem = true;
      itemsModule.createItem(this.itemModel);
      this.hideModal()
    }
  }

  isNumber(evt: any) {
      evt = evt || window.event;
      let charCode = evt.which || evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
      return false;
  }
};
