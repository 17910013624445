
import { Component, Vue, Prop } from 'vue-property-decorator'
import { commonModule, itemsModule } from '@/store/modules/store-accessor'

@Component
export default class DeleteInventoryItemModal extends Vue {
  @Prop()
  payload!: any;

  isDeletingItem = false;

async mounted() {
  commonModule.initSnackbar({});
}

async hideModal () {
    commonModule.hideModal();
  }

  doDelete () {
    this.isDeletingItem = true;
    itemsModule.deleteItem(this.payload.deletedItem);
    this.hideModal()
  }
};
