export default {
  light: {
    primary: "#284BAE",
    secondary: "#f6f7ff",
    error: "#FF4020",
    warning: "#FFC260",
    success: "#4caf50",
    info: "#0F68DB",
    textColor: "#B9B9B9",
    iconColor: "#FFFFFF",
    grayBold: "#4a4a4a",
    textColor2: "#6e6e6e",
    textGrey: "#b9b9b9",
    titleGrey: "#4a4a4a",
    buttonGrey: "#5973BB",
    whitesmoke: "#f5f5f5",
    appointmentInCompleteColor: "#db1a1a",
    gainsboro: "#DCDCDC",
    taskCompletedWithAppointmentColor: "#48C06C",
    taskCompletedWithJobColor: "#48C06C",
    appointmentStatusError: "FF4020",
    appointmentTypeError: "#FF9800",
    taskStatusError: "FF4020",
    taskTypeError: "#FF9800",
    black: "#1E1E1E",
    primaryInverse: "#4D68B6",
    lightBlueGrey: "#E4E4E0",
    alertOrangeCircle: "#FF9E39",
    lightCyan: "#6FB3BE",
    materialChipColor: "#6FB3BE",
    darkGreen: "#108746",
    expenseChipColor: "#c0424e",
    futureTask: "#7318f2",
    todaysTask: "#09a5ed",
    pastTask: "#c0424e",
  },
};
