
import { sortDateRange } from "@/helpers/data";
import { jobsModule, filterModule, itemsModule, companyModule } from "@/store/modules/store-accessor";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import FilterList from "./FilterList.vue";
import DatePickerFilter from "./DatePickerFilter.vue";
import dayjs from "dayjs";

@Component({
  components: {
    FilterList,
    DatePickerFilter
  },
})
export default class JobFilter extends Vue {

  get jobFilters() {
    return filterModule.jobFilters;
  }

  get jobFiltersUsers() {
    return (filterModule.jobFilters.users)?.map((obj) => ({...obj, icon: obj.is_partner ? 'mdi-account-multiple' : 'mdi-account'}));
  }

  get assigneeDropdownLabel() {
    if(companyModule.company?.modules?.includes('DFB_PORTAL_PARTNERS')) {
      return 'userPartner';
    }
    else {
      return 'user';
    }
  }  
  
  get allCompanyPrimaryInventoryItems() {
    return itemsModule.allCompanyPrimaryItems;
  }

  async mounted() {
    filterModule.getJobFilters();
    itemsModule.getAllCompanyPrimaryInventoryItems();
  }

  selectedUsers: string[];
  selectedActionTypes: string[];
  selectedDates: any = [dayjs().startOf('day').toISOString(), dayjs().endOf('day').toISOString()] // Set default date range to "today"
  selectedClients: string;
  selectedItems: string[];

  updateUsers(data: string[]) {
    this.selectedUsers = data;
    this.submit();
  }

  updateClients(data: any) {
    if (data != undefined) {
      this.selectedClients = data;
    } else {
      this.selectedClients = ''; // clear filter
    }
    this.submit();
  }

  updatActionTypes(data: string[]) {
    this.selectedActionTypes = data;
    this.submit();
  }

  updateDates(data: any[]) {
    this.selectedDates = data;
    if(!this.selectedDates || this.selectedDates.length == 2) {
    this.submit();
    }
  }

  updateItems(data: string[]) {
    this.selectedItems = data;
    this.submit();
  }

  async submit() {
    jobsModule.setIsLoadingTable(true);
    let queryObj: any = {};
    let userIds = [];
    let tempId;

    let actionsTempId;
    let actionsIds = [];

    let clientsIds = [];
    let itemTempId;
    let itemsIds = [];

    if (this.selectedUsers != undefined) {
      for (let i = 0; i < this.selectedUsers.length; i++) {
        tempId = (this.selectedUsers[i] as any).id
        userIds.push(tempId);
      }
    }

    if (userIds != undefined && userIds.length > 0) {
      queryObj["updated_by[]"] = userIds;
    }

    if (this.selectedActionTypes != undefined) {
      for (let i = 0; i < this.selectedActionTypes.length; i++) {
        actionsTempId = (this.selectedActionTypes[i] as any).id
        actionsIds.push(actionsTempId);
      }
    }

    if (actionsIds != undefined && actionsIds.length > 0) {
      queryObj["actions[]"] = actionsIds;
    }

    if (this.selectedDates != null && this.selectedDates.length === 2) {
      sortDateRange(this.selectedDates);

      queryObj["date_from"] = dayjs(this.selectedDates[0]).startOf('day').toISOString();
      queryObj["date_to"] = dayjs(this.selectedDates[1]).endOf('day').toISOString();
    }

    if (this.selectedItems != undefined) {
      for (let i = 0; i < this.selectedItems.length; i++) {
        const selectedItem = this.selectedItems[i] as any;
        if (Array.isArray(selectedItem.id)) {
          itemsIds.push(...selectedItem.id);
        } else {
          itemsIds.push(selectedItem.id);
        }
      }
    }
    if (itemsIds != undefined && itemsIds.length > 0) {
      queryObj["items[]"] = itemsIds;
    }

    if (this.selectedClients != undefined) {
      if (Array.isArray(this.selectedClients)) { // Need this check otherwise TypeScript and Vetur complain
        clientsIds = this.selectedClients.map(item => item.id);
      }
    }
    if (clientsIds != undefined && clientsIds.length > 0) {
      queryObj["clients[]"] = clientsIds;
    }
    
    if (jobsModule.search != '') {
      jobsModule.setSearch(jobsModule.search);
      queryObj["search"] = jobsModule.search;
    }

    this.$router.replace({ query: queryObj }).catch(err => {/* */ });

    // Check if user selected any of the queries
    const queryIndex = this.$route.fullPath.indexOf('?');
    if (queryIndex >= 0) {
      jobsModule.setQueryObject(queryObj);
      const query = this.$route.fullPath.substring(queryIndex);
      jobsModule.setFilters(query);
      jobsModule.setQueryObject(queryObj);
    } else {
      jobsModule.setFilters("");
      jobsModule.setQueryObject(queryObj); // Will be empty ({})
    }

      await jobsModule.getJobs(jobsModule.filters);
      await jobsModule.getJobTotals(jobsModule.filters);
      jobsModule.setIsLoadingTable(false);
    }
}
