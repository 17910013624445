
import { Component, Vue } from 'vue-property-decorator'
import { commonModule, partnersModule } from '@/store/modules/store-accessor'
import FilterDropdown from '../components/dropdown/filter/FilterDropdown.vue'
import { sortTableDates } from "@/helpers/data";
import { TimeUtil } from '@/helpers/TimeUtil';
import Dropdown from '../components/dropdown/Dropdown.vue'
import DropdownItem from '../components/dropdown/DropdownItem.vue'

@Component({
    components: { FilterDropdown, Dropdown, DropdownItem }
})
export default class Partners extends Vue {
    tableheaders: any [] = [
    { text: '', align: 'left', class: 'row-style', sortable: false, value: 'is_business' },
    { text: this.$t("name"), sortable: true, class: 'row-style', value: 'fullname' },
    { text: this.$t("vat"), sortable: false, class: 'row-style', value: 'vat' },
    { text: this.$t("phone"), align: 'left', sortable: true, class: 'row-style', value: 'phone' },
    { text: this.$t("notes"), align: 'left', sortable: true, class: 'row-style', value: 'notes' },
    { text: this.$t("created"), sortable: true, class: 'row-style', value: 'created_at', sort: (a: string, b: string) => sortTableDates(a, b) },
    { text: this.$t("email"), sortable: true, class: 'row-style', value: 'email' },
    { text: this.$t("activePartner"), sortable: true, class: 'row-style', value: 'is_active' },
    { text: '', align: 'right', sortable: false, class: 'row-style', value: 'id' } 
    ]

    model: any = {
        search: '',
        selected: [] as string[]
    }

    get partnersLength() {
        return partnersModule.allPartners.length;
    }

    get searchLabel() {
        return this.$t("partnerSearch")
    }

   get partners() {
    return partnersModule.allPartners;
   }

    async mounted() {
        partnersModule.getAllPartners();
        commonModule.getContactTypeCombo();
        commonModule.initSnackbar({});
    }

    get isLoadingTable() {
        return partnersModule.isLoadingTable;
    }

    formatDate (date: Date): string {
        return TimeUtil.formatDate(date);
    }

    async addPartner() {
        commonModule.showModal({ name: 'add-partner-modal' });
    }

    async editPartner (partner: any){
        await partnersModule.getPartnersInfo(partner.id);
        commonModule.showModal({ name: 'edit-partner-modal', payload: { partner: partner}});
    }

    async deletePartner (item: any) {
        commonModule.showModal({ name: 'delete-partner-modal', payload: { partner: item}});
    }

    toggleIsActive(partner: any) {
        commonModule.showModal({ name: 'toggle-is-active-partner-modal', payload: { partner: partner } })
    }

    async selectPartner (partner: any) {
    try {
      partnersModule.setIsLoadingTable(true);
      await partnersModule.getPartnersInfo(partner.id);
      commonModule.showSideview({ name: 'partners-sideview', payload: { partnerId: partner.id } });
    } catch (error) {
        partnersModule.setIsLoadingTable(false);
    }
  }

}
