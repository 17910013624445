
import { filterModule, mapModule } from "@/store/modules/store-accessor";
import Vue from "vue";
import Component from "vue-class-component";
import { Emit } from 'vue-property-decorator';
import FilterList from "./FilterList.vue";

@Component({
  components: {
    FilterList,
  },
})
export default class MapFilter extends Vue {

  get mapFilters() {
    return filterModule.mapFilters;
  }

  selectedAreas: string[];
  selectedMakes: string[];
  selectedModels: string[];

  updateAreas(data: string[]) {
    this.selectedAreas = data;
    this.submit();
  }

  updateMakes(data: string[]) {
    this.selectedMakes = data;
    this.submit();
  }

   updateModels(data: string[]) {
    this.selectedModels = data;
    this.submit();
  }

  async submit() {
    mapModule.setIsLoading(true);
    let query: any = {};

    if (this.selectedAreas != undefined && this.selectedAreas.length > 0) {
      query["area[]"] = this.selectedAreas;
    }

    if (this.selectedMakes != undefined && this.selectedMakes.length > 0) {
      query["make[]"] = this.selectedMakes;
    }

    if (this.selectedModels != undefined && this.selectedModels.length > 0) {
      query["model[]"] = this.selectedModels;
    }

    this.$router.replace({ query: query }).catch(err => {/* */ });

    // Check if user selected any of the queries
    const queryIndex = this.$route.fullPath.indexOf('?');
    if (queryIndex >= 0) {
      const query = this.$route.fullPath.substring(queryIndex);
      mapModule.setFilters(query);
    } else {
      mapModule.setFilters("");
    }
    await mapModule.getMarkersAction(mapModule.filters);
  }
}
