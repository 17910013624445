
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { commonModule, jobsModule } from '@/store/modules/store-accessor'
import { isDecimalNumber } from "@/helpers/data";
import dayjs from 'dayjs';
import AssigneeCombo from '@/components/Combobox/AssigneeCombo.vue';

@Component({
  components: {
    AssigneeCombo,
  },
})
export default class AddPaymentToJobModal extends Vue {
  @Prop()
  payload!: any;

  isAddingPayment = false;
  createdAtMenu = false;
  createAtTimeMenu = false;
  jobBalance = 0;


  clientsCombo = [
    {
      name:
      this.payload.installation.info.client_is_business ?
      this.$t("tenant") + " (" + this.payload.installation.info.client_entity_name + " - " + this.payload.installation.info.client_lastname + " " + this.payload.installation.info.client_firstname + ")"
      : this.$t("tenant") + " (" + this.payload.installation.info.client_lastname + " " + this.payload.installation.info.client_firstname + ")",
      is_business: this.payload.installation.info.client_is_business,
      id: this.payload.installation.info.user_id
    },
    this.payload.installation.info.owner_id ?
    {
      name: this.payload.installation.info.owner_is_business ?
      this.$t("owner") + " (" + this.payload.installation.info.owner_entity_name + " - " + this.payload.installation.info.owner_lastname + " " + this.payload.installation.info.owner_firstname + ")"
      : this.$t("owner") + " (" + this.payload.installation.info.owner_lastname + " " + this.payload.installation.info.owner_firstname + ")",
      is_business: this.payload.installation.info.owner_is_business,
      id: this.payload.installation.info.owner_id
  } : null
  ]

  jobPaymentModel = {
   assignee_id: this.getAssigneeId(this.payload.job.partner_uuid ? this.payload.job.partner_uuid : commonModule.user.uuid),
   installation_id: this.payload.job.installation_id,
   id: this.payload.job.id,
   payment_notes: "",
   amount: "",
   client: this.clientsCombo[0],  
   payment_type: this.paymentTypesCombo[1],
   created_at_date: dayjs().format('YYYY-MM-DD'),
   created_at_time: dayjs().format('HH:mm'),
   partner_id: this.payload.job.partner_id || '',
  }

  userRule= [
    (val: any) => !!val || this.$i18n.t("user_required"),
  ]
  clientRule= [
    (val: any) => ((!!val || val == '') && val.name.length > 0) || this.$i18n.t("client_required"),
  ]

 notesRule= [
    (val: any) => {
      if(val != null) return val.length <= 1000 || this.$i18n.t("notesLengthRule");
      else return true;
    },
  ]

  paymentTypeRule= [
    (val: any) => (val || '').id > 0 || this.$i18n.t("payment_type_required"),
  ]
  amountRule:any =[
    (val: number) => !!val || this.$i18n.t("nonZeroAmountRequired"),
    (val: number) => val > 0 || this.$i18n.t("nonZeroAmountRuleText"),
    (val: number) => {
      if(parseFloat(this.payload.job.amount_paid) > 0) return( val && val <= parseFloat(this.jobPendingBalance)) || this.$i18n.t("pendingBalanceRule");
      else return true;
    },
    (val: any) => {
      if(this.payload.job.amount_paid == 0) return( val && val <= this.payload.job.cost) || this.$i18n.t("pendingBalanceRule");
      else return true;
    }
  ]

  updateAssignee(assignee: any) {
    if(assignee) {
      this.jobPaymentModel.assignee_id = assignee;
    }
  }

  @Watch('jobPaymentModel.amount')
    onAmountChange(val: string, oldVal: string) {
      this.amountRule = [];
      if (!val) {
        this.amountRule.push((val: any) => !!val || this.$i18n.t("nonZeroAmountRequired"))
      }
      if(val) {
        this.amountRule.push((val: any) => val > 0 || this.$i18n.t("nonZeroAmountRuleText"))
      }
      this.amountRule.push(  (val: number) => {
      if(parseFloat(this.payload.job.amount_paid) > 0) return( val && val <= parseFloat(this.jobPendingBalance)) || this.$i18n.t("pendingBalanceRule");
      else return true;
    },
    (val: any) => {
      if(this.payload.job.amount_paid == 0) return( val && val <= this.payload.job.cost) || this.$i18n.t("pendingBalanceRule");
      else return true;
    });
  }

  get assigneeIdCombo () {
      return commonModule.personnelCombo;
  }

  get paymentTypesCombo() {
    for (let i = 0; i < commonModule.paymentTypeCombo.length; i++) {
      (commonModule.paymentTypeCombo[i]["payment_type"] as any) = this.$t(commonModule.paymentTypeCombo[i]["payment_type"]);
    }
    return commonModule.paymentTypeCombo;
  }

  get jobPendingBalance() {
    return this.jobBalance.toFixed(2)
  }

  async hideModal () {
    commonModule.hideModal();
  }

  async mounted() {
    commonModule.initSnackbar({});
    this.jobBalance = await jobsModule.getJobPendingBalance(this.payload.job.id);
    await commonModule.getActionCombo();
    await commonModule.getPersonnelCombo();
    await commonModule.getPaymentTypeCombo();
  }

  onlyDecimalNumbers(evt: any) {
    isDecimalNumber(evt);
  }

  repayment() {
    this.jobPaymentModel.amount = this.jobPendingBalance
  }

  getAssigneeId(uuid: string) {
      const combo: any = commonModule.personnelPartnersCombo;
      const foundItem = combo.find((item: any) => item.id === uuid);
      if(foundItem) {
        return { name: foundItem.name, id: foundItem.id };
      } else {
        return ''
      }
    }

  async doAddJob () {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        this.isAddingPayment = true;
        jobsModule.createPaymentToJob(this.jobPaymentModel);
        this.hideModal()
        await jobsModule.getJobs(jobsModule.filters);
    }  
  }
};
