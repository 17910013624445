
import { Component, Vue, Prop } from 'vue-property-decorator'
import { commonModule, materialsModule, itemTypesModule } from '@/store/modules/store-accessor'
import _ from 'lodash'
import { TimeUtil } from '@/helpers/TimeUtil'
import { isDecimalNumber } from "@/helpers/data";
import dayjs from 'dayjs';
import AssigneeCombo from '@/components/Combobox/AssigneeCombo.vue';

@Component({
  components: {
    AssigneeCombo,
  },
})
export default class EditMaterialModal extends Vue {
  @Prop()
  payload!: any;

  createdAtMenu = false;
  createAtTimeMenu = false;
  materialModel = {} as any;
  payloadMaterialModel = {} as any;
  isEditingMaterial = false;

  costRule:any =[(val: any) => val !== '' && val >= 0 || this.$i18n.t("cost_required"),
  (val: any) => val >= this.minActionAmount || this.$i18n.t("single_action_cost_rule")]
  
  descriptionRule= [(val: any) => (val == null || ((val?.text?.length <= 80) || val?.length <=80 )) || this.$i18n.t("client_form_description_length_rule")]

  notesRule= [
    (val: any) => {
      if(val != null) return val.length <= 1000 || this.$i18n.t("notesLengthRule");
      else return true;
    },
  ]

  async mounted() {
    this.payloadMaterialModel = _.cloneDeep(this.payload.material)

    this.payloadMaterialModel.assignee_id = this.getAssigneeId(this.payload.material.assignee_id);
    this.payloadMaterialModel.created_at_date = dayjs(this.payload.material.created_at).format('YYYY-MM-DD');
    this.payloadMaterialModel.created_at_time = dayjs(this.payload.material.created_at).format('HH:mm');
    
    this.materialModel = _.cloneDeep(this.payloadMaterialModel);

    commonModule.initSnackbar({});
    await itemTypesModule.getItemTypesCombo();
  }


  updateAssignee(assignee: any) {
    if(assignee) {
      this.materialModel.assignee_id = assignee;
    }
  }

  async hideModal () {
    commonModule.hideModal();
  }

  get material() {
    return this.materialModel
  }

  get materialName () {
    return this.material.description != '' ? this.material.description : this.$i18n.t('material')
  }

  get itemTypesCombo () {
    return itemTypesModule.itemTypesCombo;
  }

  get minActionAmount() {
    return this.payload.material.cost - this.payload.job.balance
  }

  get editDisabled() {
    return _.isEqual(this.materialModel, this.payloadMaterialModel)
  }

  get assigneeIdCombo () {
    return commonModule.personnelCombo;
  }

  getAssigneeId(name: string) {
    const combo: any = commonModule.personnelPartnersCombo;
    const foundItem = combo.find((item: any) => item.name === name);
    if(foundItem) {
      return { name: foundItem.name, id: foundItem.id };
    } else if(!foundItem && this.payload.material.assignee_id) {
      return { name: this.payload.material.assignee_id }
    } else {
      return ''
    }
  }

  async doEditMaterial () {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      this.isEditingMaterial = true;
      let materialsObj = _.cloneDeep(this.materialModel);

      if (typeof materialsObj.description === 'object' && materialsObj.description != null) {
        materialsObj.description = materialsObj.description.text
      }

      await materialsModule.editMaterial(materialsObj);
      this.isEditingMaterial = false;
      this.hideModal();
    }
  }

  formatDate (date: Date): string {
    return TimeUtil.formatDateTime(date)
  }

  onlyDecimalNumbers(evt: any) {
    isDecimalNumber(evt);
  }
};
