
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import ApexChart from "vue-apexcharts";

@Component({
  components: { ApexChart },
})
export default class DashboardCardBarChart extends Vue {
  @Prop() readonly title: string;
  @Prop() readonly title_number: string;
  @Prop() readonly title_percentage: string;
  @Prop() readonly sub_title_number: string;
  @Prop() readonly sub_title_percentage: string;
  @Prop() readonly chart_options: unknown;
  @Prop() readonly chart_series: unknown;
  @Prop() readonly sub_title_number_text: string;
  @Prop() readonly sub_title_percentage_text: string;
  @Prop() readonly isLoading: boolean;
}
