
import { Component, Vue, Prop } from 'vue-property-decorator'
import { commonModule, installationsModule } from '@/store/modules/store-accessor'

@Component
export default class DeleteInstallationFileModal extends Vue {
      @Prop()
      payload!: any;

      isDeleting = false;

      deleteFileModel = {
        fileId: this.payload.file.id,
        installationId: this.payload.file.installation_id,
        originaName: this.payload.file.original_name,
      }

      async mounted() {
          commonModule.initSnackbar({});
      }

  
      async hideModal() {
          commonModule.hideModal();
      }

      async doDeleteInstallationFile() {
          this.isDeleting = true;
          await installationsModule.deleteInstallationFile({installationId: this.deleteFileModel.installationId, fileId: this.deleteFileModel.fileId});
          await installationsModule.getInstallationInfo(this.deleteFileModel.installationId);
          this.isDeleting = false;
          this.hideModal();
      }
};
  