
import { Component, Vue, Prop } from 'vue-property-decorator'
import { commonModule, appointmentsModule } from '@/store/modules/store-accessor'

@Component
export default class CancelAppointmentModal extends Vue {
  @Prop()
  payload!: any;

  isCancelingAppointment = false;
 
  async hideModal () {
    commonModule.hideModal();
  }

  async mounted() {
    commonModule.initSnackbar({});
  }

  doDelete () {
    this.isCancelingAppointment = true;
    appointmentsModule.cancelAppointment(this.payload.appointment);
    this.hideModal()
  }
};
