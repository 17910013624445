import { BaseApi } from '@/helpers/base-api'

export default class JobsApi extends BaseApi {
  constructor () {
    super('/api/v1/jobs')
    this.errorMessage = 'There was an issue when trying to fetch the jobs. If this error persists, please contact our support.'
  }

  public getAllJobs = (query: string): Promise<JobDto[]> => this.instance.get(`${query}`)
  
  public getJobsTotals = (query: string): Promise<any> => this.instance.get(`/totals${query}`)

  public getJob = (jobId: string): Promise<JobDto> => this.instance.get(`/${jobId}`)
  
  public getJobPendingBalance = (jobId: string): Promise<JobBalanceDto> => this.instance.get(`/${jobId}/balance`)

  public getCompletedJobPdfExport = (data: Record<string, unknown>): Promise<Blob> => this.instance.post(`/${data.jobId}/export`, data, { responseType: 'blob' })
  public getJobsPdfExport = (query: string, data: Record<string, unknown>): Promise<Blob> => this.instance.post(`/export${query}`, data, { responseType: 'blob' })
  public createJob = (data: Record<string, any>): Promise<JSON> => this.instance.post('', data)

  public createJobWithActions = (data: Record<string, any>): Promise<JSON> => this.instance.post('/withactions', data)

  public updatejob = (jobId: string, data: Record<string, unknown>): Promise<JSON> => this.instance.patch(`/${jobId}`, data)

  public createJobAppointment = (jobId: string, data: Record<string, unknown>): Promise<JSON> => this.instance.post(`/${jobId}/appointments`, data)

  public createJobActions = (jobId: string, data: Record<string, unknown>): Promise<JSON> => this.instance.post(`/${jobId}/actions`, data)

  public createJobMaterials = (jobId: string, data: Record<string, unknown>): Promise<JSON> => this.instance.post(`/${jobId}/materials`, data)

  public createJobPayment = (jobId: string, data: Record<string, unknown>): Promise<JSON> => this.instance.post(`/${jobId}/payments`, data)

  public createJobExpenses = (jobId: string, data: Record<string, unknown>): Promise<JSON> => this.instance.post(`/${jobId}/expenses`, data)
  public deleteJob = (jobId: string): Promise<JSON> => this.instance.delete(`/${jobId}`)

}
