
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { primaryContactExists } from '@/helpers/data';
import { commonModule, clientsModule } from '@/store/modules/store-accessor'


@Component
export default class QuickAddCalendarClient extends Vue {
    @Prop() readonly step: number;
    @Prop() readonly currentStep: any;
    @Prop() readonly task: any;

    selectedClient = {};
    newClientFlag = false;
    addClientLoading = false;
    addInstallation = true;

    clientModel = {
        firstname: "",
        lastname: "",
        email: "",
        id: "",
        contacts: [{
            id: Math.random() * -1, //Need Random and non-integer and also negative, for this to work with the backend properly
            contact_type: (this.contactTypeCombo[1] as any),
            contact_type_id: 0,
            is_primary: true,
            description: '',
            phone: ''
        }],
        description: "",
        notes: '',
        entity_name: '',
        vat: '',
        tax_office: '',
        address: '',
        profession: '',
        is_business: false,
    }

    @Watch('currentStep')
    async stepUpdate() {
        return 0;
    }

    async mounted() {
        await clientsModule.getClientsAction('');
        await commonModule.getContactTypeCombo();
    }

    get clientsCombo() {

        return clientsModule.clients.map((item) => {
            let client_arr = item.is_business
                ? [`${item.entity_name} (${item.lastname} ${item.firstname})`, item.phone]
                : [`${item.lastname} ${item.firstname}`, item.phone]
            return { ...item, name: client_arr.filter(Boolean).join(' | ') }
        });
    }

    get isLoadingCombo() {
        return clientsModule.isLoadingTable;
    }

    get contactTypeCombo() {
        for (let i = 0; i < commonModule.contactTypeCombo.length; i++) {
            (commonModule.contactTypeCombo[i]["contact_info_type"] as any) = this.$t(commonModule.contactTypeCombo[i]["contact_info_type"]);
        }
        return commonModule.contactTypeCombo;
    }

    clientRule = [
        (val: any) => ((!!val || val == '') && val.id > 0) || this.$i18n.t("client_required"),
    ]

    firstnameRule = [
        (val: any) => !!val || this.$i18n.t("firstname_required"),
        (val: any) => val.length <= 45 || this.$i18n.t("client_form_name_length_rule"),
    ]
    lastnameRule = [
        (val: any) => !!val || this.$i18n.t("lastname_required"),
        (val: any) => val.length <= 45 || this.$i18n.t("client_form_lastname_length_rule"),
    ]
    emailRule: any = [
        (val: any) => val.length <= 70 || this.$i18n.t("client_form_email_length_rule"),
        (val: any) => {
            //eslint-disable-next-line
            const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
            if (val.length > 0) return reg.test(val) || this.$i18n.t("form_email_rule");
            else return true;
        },
    ]
    contactTypeRule = [
        (val: any) => !!val && val.contact_info_type != "" || this.$i18n.t("contact_type_required"),
    ]
    phoneRule = [
        (val: any) => !!val || this.$i18n.t("phone_required"),
        (val: any) => val.replace(/ /g, '').replace(/-/g, '').length <= 20 || this.$i18n.t("client_form_phone_length_rule"),
        (val: any) => {
            //eslint-disable-next-line
            let reg = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,14}$/im;
            if (val.length > 0) return reg.test(val.replace(/ /g, '').replace(/-/g, '')) || this.$i18n.t("form_phone_rule");
            else return true;
        },
    ]
    descriptionRule = [
        (val: any) => val.length <= 80 || this.$i18n.t("client_form_description_length_rule"),
    ]
    notesRule = [
        (val: any) => val.length <= 1000 || this.$i18n.t("notesLengthRule"),
    ]
    entityNameRule = [
        (val: any) => !!val || this.$i18n.t("entityNameRequired"),
        (val: any) => val.length <= 100 || this.$i18n.t("entityNameRule"),
    ]
    vatRule = [
        (val: any) => !!val || this.$i18n.t("vatRequired"),
        (val: any) => val.length <= 14 || this.$i18n.t("vatRule"),
    ]

    checkboxChanged() {
        this.clientModel = {
            firstname: "",
            lastname: "",
            email: "",
            id: "",
            contacts: [{
                id: Math.random() * -1, //Need Random and non-integer and also negative, for this to work with the backend properly
                contact_type: (this.contactTypeCombo[1] as any),
                contact_type_id: 0,
                is_primary: true,
                description: '',
                phone: ''
            }],
            description: "",
            notes: '',
            entity_name: '',
            vat: '',
            tax_office: '',
            address: '',
            profession: '',
            is_business: false,
        }
    }

    async nextStep() {
        if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {

            if (this.newClientFlag) {
                this.clientModel.contacts.map((item) => {
                    item.contact_type_id = item.contact_type.id
                    item.phone = item.phone.replace(/ /g, '').replace(/-/g, '')
                })

                this.addClientLoading = true;
                const clientCreated = await clientsModule.addClient(this.clientModel);
                clientsModule.getClientsAction('');
                this.selectedClient = clientCreated;
                this.addClientLoading = false;
            }

            if ((this.addInstallation && this.newClientFlag) || (!this.newClientFlag)) {
                this.$emit('next-step', this.selectedClient)
            }
            else {
                this.$emit('close-modal')
            }

            this.newClientFlag = false;
            this.checkboxChanged();
        }
    }

    addPhone() {
        let newContact = {
            id: Math.random(),
            contact_type: (this.contactTypeCombo[1] as any),
            contact_type_id: 0,
            is_primary: false,
            description: '',
            phone: ''
        } as any

        this.clientModel.contacts.push(newContact);
    }

    deletePhone(index: number) {
        if (this.clientModel.contacts.length > 1) {
            this.clientModel.contacts.splice(index, 1);
            if (!primaryContactExists(this.clientModel)) {
                this.clientModel.contacts[0].is_primary = true;
            }
        } else if (this.clientModel.contacts.length === 1) {
            this.clientModel.contacts[0].is_primary = true;
        }
    }

    doAddClient() {
        if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
            this.clientModel.contacts.map((item) => {
                item.contact_type_id = item.contact_type.id
            })

            clientsModule.addClient(this.clientModel);
        }
    }

    onPrimaryContactCheckboxChange(ev: any, item: any) {
        if (ev === true) {
            this.clientModel.contacts.forEach((element: any) => {
                if (element.id === item.id) {
                    element.is_primary = true;
                }
                else {
                    element.is_primary = false;
                }
            });
        }
    }
}
