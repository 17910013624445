
  import Vue from "vue";
  import { Component, Prop } from "vue-property-decorator";
  import { formatDatesRangeText, sortDateRange } from "@/helpers/data";
  import KeycloakSession from "@/helpers/keycloak-session";
  import dayjs from "dayjs";
  import customParseFormat from 'dayjs/plugin/customParseFormat';

  dayjs.extend(customParseFormat);
  
  @Component
  export default class DatePickerFilter extends Vue {
    @Prop({ type: Array, default: () => [new Date().toISOString().slice(0, 10), new Date().toISOString().slice(0, 10)] } /* Today */) dateRange!: any;
    menu = false;
    dates: any = this.dateRange;
    dateRangeText: any = formatDatesRangeText(this.dates[0], this.dates[1]);

    updateFromDateRange = false;
    updateFromDates = false;
    debounceTimeout = null as any

    onDateRangeTextChanged() {
      if (this.updateFromDates) {
        this.updateFromDates = false;
        return;
      }

      this.updateFromDateRange = true;

      clearTimeout(this.debounceTimeout);

      this.debounceTimeout = setTimeout(() => { // delay formatDatesRangeText
        const datesArray = this.dateRangeText.split(' - ');

        if (datesArray.length === 2) {
          const formattedDates = datesArray.map((date: string) =>
          dayjs(date, 'D/M/YYYY').format('YYYY-MM-DD')
          );

          const startDate = new Date(formattedDates[0]);
          const endDate = new Date(formattedDates[1]);

          if (!isNaN(startDate.getTime()) && !isNaN(endDate.getTime())) {
            if (formattedDates.length === 2) {
              this.dates = [formattedDates[0], formattedDates[1]];
              this.dateRangeText = formatDatesRangeText(this.dates[0], this.dates[1]);
              this.$emit('update-filter', this.dates);
            }
          }
        }
      }, 700);
    }

    selectFilter() {
      if (this.updateFromDateRange) {
        this.updateFromDateRange = false;
        return;
      }

      this.updateFromDates = true;
      this.$emit('update-filter', this.dates);

      if (this.isEditorInPayments()) {
        if (this.dates == null) {
          this.dateRangeText = formatDatesRangeText(null, null);
        } else if (this.dates.length === 1) {
          this.dateRangeText = this.isAdmin() ? formatDatesRangeText(this.dates[0], null) : formatDatesRangeText(this.dates, this.dates);
        } else if (this.dates.length === 2 && this.isAdmin()) {
          sortDateRange(this.dates);
          this.dateRangeText = formatDatesRangeText(this.dates[0], this.dates[1]);
        }
      } else {
        if (this.dates == null) {
          this.dateRangeText = formatDatesRangeText(null, null);
        } else if (this.dates.length === 1) {
          this.dateRangeText = formatDatesRangeText(this.dates[0], null);
        } else if (this.dates.length === 2) {
          sortDateRange(this.dates);
          this.dateRangeText = formatDatesRangeText(this.dates[0], this.dates[1]);
        }
      }
    }

  isEditorInPayments() {
    const kc = new KeycloakSession()
    if(kc.userHasRole('editor') && !kc.userHasRole('admin') && this.$route.name === 'My Company') {
      return true;
    } else {
      return false;
    }
  }

  isAdmin () {
    const kc = new KeycloakSession()
    if(kc.userHasRole('admin')) {
      return true;
    }
    else if (kc.userHasRole('editor')) {
      return false;
    }
  }

  clearDateRange() {
    this.dates = null
    this.dateRangeText = ''
    this.selectFilter();
  }

  closeMenu() {
    this.menu = false;
  }

  }

  