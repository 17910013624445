import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators'
import CompanyApi from '@/api/company-api'
import { TimeUtil } from '@/helpers/TimeUtil'
import ComboApi from '@/api/combo-api';
import axios from 'axios';

@Module({ namespaced: true, name: 'company' })
export default class CompanyModule extends VuexModule {
  public company: CompanyInfoDto = {} as CompanyInfoDto;
  public personnel: CompanyPersonnelDto[] = [];
  public isLoadingTable = true;


  @Action({ commit: 'setCompanyInfo'})
  async getCompanyInfo (): Promise<CompanyInfoDto> {

    const companyApi = new CompanyApi()
    const company = await companyApi.getCompanyInfo();
    return company
  }

  @Action({ commit: 'setCompanyPersonnel'})
  async getCompanyPersonnel (): Promise<CompanyPersonnelDto[]> {

    const companyApi = new CompanyApi()
    const companyPersonnel = await companyApi.getCompanyPersonnel();
    return companyPersonnel
  }

  @Action
  async getBackUpFile() {
    const companyApi = new CompanyApi()
    const response = await companyApi.getCompanyBackUp();
    const blob = new Blob([response], { type: "application/zip" });
    return blob
  }

  @Mutation
  public setCompanyInfo (company: CompanyInfoDto): void {
    this.company = company
  }

  @Mutation
  public setCompanyPersonnel (companyPersonnel: CompanyPersonnelDto[]): void {
    try {
    companyPersonnel.map(item => {
      item.created_at = `${TimeUtil.formatUnixTS(item.createdTimestamp/1000)}`
    })
    this.personnel = companyPersonnel;
    this.isLoadingTable = false;
  } catch(error) {
    this.isLoadingTable = false;
  }
  }

  @Mutation
  public setIsLoadingTable(isLoadingTable: boolean): void {
    this.isLoadingTable = isLoadingTable
  }

  @Action
  async editCompany(data: Record<string, any>) {
    const companyApi = new CompanyApi()
    await companyApi.updateCompany(data.id, data)
  }

  @Action
  async editCompanyInfo({companyId, data} : {companyId: string, data: Record<string, any>}) {
    const companyApi = new CompanyApi()
    await companyApi.updateCompanyInfo(companyId, data)
  }

}
