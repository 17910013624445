
  import { Component, Vue, Prop } from 'vue-property-decorator'
  import { commonModule, installationsModule, partnersModule } from '@/store/modules/store-accessor'
  import LoadingIndicator from '@/components/TheLoadingIndicator.vue'
  import PartnersSideviewGeneralTab from '@/components/sideviews/partners/PartnersSideviewGeneralTab.vue'
  import { TimeUtil } from '@/helpers/TimeUtil'
  import TheMiniLoader from '@/components/TheMiniLoader.vue'
  import PartnersSideviewJobsTab from '@/components/sideviews/partners/PartnersSideviewJobsTab.vue'
  import PartnersSideviewTasksTab from '@/components/sideviews/partners/PartnersSideviewTasksTab.vue'
import { formatAmount } from '@/helpers/data'
  
  @Component({
    components: {
      LoadingIndicator,
      PartnersSideviewGeneralTab,
      PartnersSideviewJobsTab,
      PartnersSideviewTasksTab,
      TheMiniLoader,
    }
  })
  export default class PartnersSideview extends Vue {
    @Prop()
    loading!: boolean;
  
    @Prop()
    payload!: any;
  
    tab = 0;
  
    isEditing!: boolean;
  
    async mounted() {
      installationsModule.getInstallationsByIdAction(this.payload['partnerId']);
      commonModule.getContactTypeCombo();
    }
  
    async hideSideview () {
      commonModule.hideSideview();
    }
  
    get currPartnerId () {
      return this.payload['partnerId'];
    }
  
    get currPartner () {
      return partnersModule.currentPartner;
    }
  
    get isLoading() {
      return partnersModule.isLoadingSideView;
    }
  
    partnerIconSrc() {
      if (this.currPartner.info.is_business) {
        return require('@/assets/img/company_icon.png');
      } else {
        return require('@/assets/img/client_icon.png');
      }
    }
  
    formatDate (date: Date): string {
      return TimeUtil.formatDateTime(date)
    }
  
    updated() {
      this.isEditing = false;
    }
  
    refreshData () {
      partnersModule.setIsLoadingSideView(true);
      partnersModule.getPartnersInfo(this.currPartnerId)
    }
  
    formatBalance(balance: number) {
      return formatAmount(balance);
    }
  
    async editPartner() {
      await partnersModule.getPartnersInfo(this.currPartnerId)
      commonModule.showModal({ name: 'edit-partner-modal', payload: { partner: this.currPartner.info, contacts: this.currPartner.contacts }})
    }
  
    deletePartner() {
      commonModule.showModal({ name: 'delete-partner-modal', payload: { partner: this.currPartner.info }} )
    }
  
    close() {
        partnersModule.unsetCurrentPartner();
        partnersModule.getAllPartners();
        this.hideSideview();
    }
  
  };
