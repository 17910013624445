import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators'
import PaymentsApi from '@/api/payments-api'
import { TimeUtil } from '@/helpers/TimeUtil'
import { installationsModule, partnersModule } from '@/store/modules/store-accessor'
import dayjs from 'dayjs';

@Module({ namespaced: true, name: 'payments' })
export default class PaymentsModule extends VuexModule {
  public payments: PaymentDto[] = [];
  public paymentsTotals: PaymentDto = {} as PaymentDto;
  public isLoadingTable = true;
  public filters = "";
  public search = '';
  public queryObject: any = {};

  @Action({ commit: 'setPayments'})
  async getPaymentsAction (query: string): Promise<PaymentDto[]> {
    const paymentsApi = new PaymentsApi()
    const payments = await paymentsApi.getAllPayments(query.replace(/\s+/g, ''))
    return payments
  }

  @Action({ commit: 'setPaymentsTotals'})
  async getPaymentsTotals (query: string): Promise<PaymentDto> {
    const paymentsApi = new PaymentsApi()
    const paymentsTotals = await paymentsApi.getPaymentsTotals(query)
    return paymentsTotals
  }

  @Action
  async deletePayment(data: Record<string, any>) {

    const paymentsApi = new PaymentsApi()
    await paymentsApi.deletePayments(data.id)
    if(data.finc_type) {
      await this.getPaymentsAction(this.filters);
      await this.getPaymentsTotals(this.filters);
    } else {
      await installationsModule.getInstallationInfo(data.installation_id);
    }
  }

  @Action
  async editPayment(payment: Record<string, any>) {
    const paymentsApi = new PaymentsApi()
    if(payment.created_at_date && payment.created_at_time) {
      payment.created_at = dayjs(payment.created_at_date + ' ' +  payment.created_at_time);
      delete payment.created_at_date;
      delete payment.created_at_time;
    }

    await paymentsApi.updatePayment(payment.id, { cost: payment.cost, notes: payment.notes, assignee_id: payment.assignee_id.id,
      payment_type_id: payment.payment_type.id, amount: payment.amount, client_id: payment.client.id, created_at: payment.created_at })

    if(payment.finc_type) {
      await this.getPaymentsAction(this.filters);
      await this.getPaymentsTotals(this.filters);
    } else {
      await installationsModule.getInstallationInfo(payment.installation_id);
    }

    // Update partner jobs tab
    if(payment.partner_id) {
      await partnersModule.getPartnersInfo(payment.partner_id);
    }
  }

  @Action
  async getPaymentsPdfExportAction({ query, data } : { query: string, data: Record<string, any> }) {
    const paymentsApi = new PaymentsApi();
    const response = await paymentsApi.getPaymentsPdfExport(query, data);
    const blob = new Blob([response], { type: "application/pdf" });
    return blob
  }

  @Mutation
  public setPayments (payments: PaymentDto[]): void {
    try {
      payments.map(item => {
        item.readable_created_at = `${TimeUtil.formatDateTime(item.created_at)}`;
        item.tag_split = item.tag_id ? item.tag_id.split('_').pop() : '-';
        if (item.finc_type === 'EXPENSE') {
         (item.amount as any) = item.cost;
        }
      })
      this.payments = payments
      this.isLoadingTable = false;
    } catch (error) {
      this.isLoadingTable = false;
    }
  }

  @Mutation
  public clearPayments (): void {
    this.payments = [];
    this.paymentsTotals = {} as PaymentDto;
  }

  @Mutation
  public setPaymentsTotals (paymentsTotals: PaymentDto): void {
    this.paymentsTotals = paymentsTotals
  }

  @Mutation
  public setIsLoadingTable(isLoadingTable: boolean): void {
    this.isLoadingTable = isLoadingTable
  }

  @Mutation
  public setFilters(filters: string): void {
    this.filters = filters
  }

  @Mutation
  public setSearch(search: string): void {
    this.search = search
  }

  @Mutation
  public setQueryObject(queryObject: any): void {
    this.queryObject = queryObject
  }
}