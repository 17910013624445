
  import { Component, Vue, Prop } from 'vue-property-decorator'
  import { commonModule, companyModule } from '@/store/modules/store-accessor'
  
  
  @Component
  export default class EditCompanyInfoModal extends Vue {
    @Prop()
    payload!: any;

    isEditingCompany = false;
   
    companyModel = {
        address: this.payload.companyInfo.address || '',
        vat_number: this.payload.companyInfo.vat_number || '',
        entity_name: this.payload.companyInfo.entity_name || '',
        name: this.payload.companyInfo.name || '',
        email: this.payload.companyInfo.email || '',
        contact: this.payload.companyInfo.contact || '',
        image: null,
    }

    logoPreviewUrl = '';
  
    async hideModal () {
      commonModule.hideModal();
    }
  
    async doEditCompanyInfo () {
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        this.isEditingCompany = true;
        let formData = new FormData();

        Object.entries(this.companyModel).forEach(([key, value]) => {
          formData.append(key, value);
        });

        await companyModule.editCompanyInfo({companyId: companyModule.company.id, data: formData});
        await companyModule.getCompanyInfo();
        this.hideModal();
      }
    }
  
    async mounted() {
      commonModule.initSnackbar({});
    }

    addressRules = [
      (val: any) => !!val || this.$i18n.t('address_required'),
      (val: any) => val.length <= 100 || this.$i18n.t('form_address_length_rule'),
    ]
    vatRules = [
      (val: any) => !!val || this.$i18n.t('vatRequired'),
      (val: any) => val.length <= 14 || this.$i18n.t('vatRule'),
    ]
    companyNameRules = [
      (val: any) => !!val || this.$i18n.t('companyNameRequired'),
      (val: any) => val.length <= 100 || this.$i18n.t('entityNameRule'),
    ]
    companyContactPersonRules = [
      (val: any) => !!val || this.$i18n.t('companyContactPersonRequired'),
      (val: any) => val.length <= 80 || this.$i18n.t('companyContactPersonLengthRule'),
    ]
    emailRules = [
      (val: any) => {
        if(val != null) return val.length <= 70 || this.$i18n.t("client_form_email_length_rule");
        else return true;
      },
      (val: any) => {
        //eslint-disable-next-line
        const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if(val != null && val.length > 0) return reg.test(val) || this.$i18n.t("form_email_rule");
        else return true;
      },
    ]
    contactRules = [
      (val: any) => !!val || this.$i18n.t("phone_required"),
      (val: any) => val.replace(/ /g, '').replace(/-/g, '').length <= 20 || this.$i18n.t("client_form_phone_length_rule"),
      (val: any) => {
        //eslint-disable-next-line
        let reg = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,14}$/im;
        if(val.length > 0) return reg.test(val.replace(/ /g, '').replace(/-/g, '')) || this.$i18n.t("form_phone_rule");
        else return true;
      },
    ]

    PreviewLogo() {
       if(this.companyModel.image !== undefined && this.companyModel.image !== null) {
        this.logoPreviewUrl = URL.createObjectURL(this.companyModel.image);
       } else {
        // Clear logo preview
        this.companyModel.image = null;
        this.logoPreviewUrl = '';
       }
    }
  
  };
  