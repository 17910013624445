import { BaseApi } from '@/helpers/base-api'

export default class ComboApi extends BaseApi {
  constructor () {
    super('/api/v1/')
    this.errorMessage = 'There was an issue when trying to fetch the combo boxes. If this error persists, please contact our support.'

  }
  public getActionTypeCombo = (): Promise<any[]> => this.instance.get('actionTypeCombo')
  public getPersonnelCombo = (): Promise<any[]> => this.instance.get('personnelCombo')
  public getClientsCombo = (): Promise<any[]> => this.instance.get('clientsCombo')
  public getFuelTypeCombo = (): Promise<any[]> => this.instance.get('fuelTypeCombo')
  public getContactTypeCombo = (): Promise<any[]> => this.instance.get('contactTypeCombo')
  public getPlacementTypeCombo = (): Promise<any[]> => this.instance.get('installationPlacementTypeCombo')
  public getItemTypeCombo = (): Promise<any[]> => this.instance.get('itemTypeCombo')
  public getAppointmentStatusCombo = (): Promise<any[]> => this.instance.get('appointmentStatusCombo')
  public getInventoryItemCombo = (): Promise<any[]> => this.instance.get('itemTypeCombo')
  public getInstallationsmCombo = (): Promise<any[]> => this.instance.get('installationsAllCombo')
  public getPaymentTypeCombo = (): Promise<any[]> => this.instance.get('paymentTypeCombo')
  public getPersonnelPartnersCombo = (): Promise<any[]> => this.instance.get('personnelPartnersCombo')
}
