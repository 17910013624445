
import { Component, Vue, Prop } from 'vue-property-decorator'
import { commonModule, installationsModule, clientsModule } from '@/store/modules/store-accessor'
import QuickAddClient from '@/components/Modals/QuickAdd/QuickAddClient.vue';
import QuickAddInstallation from '@/components/Modals/QuickAdd/QuickAddInstallation.vue';
import QuickAddAppointment from '@/components/Modals/QuickAdd/QuickAddAppointment.vue';

@Component({
  components: { QuickAddClient, QuickAddInstallation, QuickAddAppointment },
})
export default class QuickAddAppointmentModal extends Vue {

  wizardStep = 1;
  client = { id: -1, firstname: '', lastname: '', entity_name: '', is_business: false};
  installation = { id: -1, address: '', area: '', zip_code: '', floor: 0};

  get clientName () {
    return this.client.is_business ? `${this.client.entity_name} (${this.client.firstname} ${this.client.lastname})`
    : `${this.client.firstname} ${this.client.lastname}`
  }

  get installationAddress () {
    let addres_arr = [this.installation.address, this.installation.area, this.installation.zip_code, this.installation.floor]

    return addres_arr.filter(Boolean).join(', ')
  }

  async hideModal () {
    commonModule.hideModal();
  }

  async mounted() {
    commonModule.initSnackbar({});
  }

  doClose () {
    this.hideModal()
    installationsModule.clearNewInstallationCoordinates();
  }

  doNextStep (val: any) {
    if (this.wizardStep == 1) {
      this.client = val
    }
    else if (this.wizardStep == 2) {
      this.installation = val
    }
    
    this.wizardStep++;
  }


  doPrevStep () {
    if (this.wizardStep > 1) {
      this.wizardStep--;

      if (this.wizardStep == 1) {
        this.client = { id: -1, firstname: '', lastname: '', entity_name: '', is_business: false};
      }

      if (this.wizardStep == 2) {
        this.installation = { id: -1, address: '', area: '', zip_code: '', floor: 0};
      }
    }
  }
};
