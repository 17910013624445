
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { clientsModule, commonModule, jobsModule, paymentsModule } from '@/store/modules/store-accessor'
import _ from 'lodash'
import { TimeUtil } from '@/helpers/TimeUtil'
import { isDecimalNumber } from "@/helpers/data";
import dayjs from 'dayjs';
import AssigneeCombo from '@/components/Combobox/AssigneeCombo.vue';

@Component({
  components: {
    AssigneeCombo,
  },
})
export default class EditPaymentModal extends Vue {
  @Prop()
  payload!: any;

  createdAtMenu = false;
  createAtTimeMenu = false;

  paymentModel = {} as any;
  payloadPaymentModel = {} as any;
  isEditingPayment = false;
  jobBalance = 0;

  async mounted() {
    this.payloadPaymentModel = _.cloneDeep(this.payload.payment)    
    
    this.payloadPaymentModel.payment_type = this.findPaymentType(this.payload.payment.payment_type_id);
    this.payloadPaymentModel.assignee_id = this.getAssigneeId(this.payload.payment.assignee_id);
    this.payloadPaymentModel.client = this.clientName(this.payload.payment.client_id);
    this.payloadPaymentModel.created_at_date = dayjs(this.payload.payment.created_at).format('YYYY-MM-DD');
    this.payloadPaymentModel.created_at_time = dayjs(this.payload.payment.created_at).format('HH:mm');

    this.paymentModel = _.cloneDeep(this.payloadPaymentModel)

    this.jobBalance = await jobsModule.getJobPendingBalance(this.payload.job.id);
    commonModule.initSnackbar({});
  }

  updateAssignee(assignee: any) {
    if(assignee) {
      this.paymentModel.assignee_id = assignee;
    }
  }

  async hideModal () {
    this.clientName('')
    commonModule.hideModal();
  }

  get payment() {
    return this.paymentModel
  }

  get editDisabled() {
    return _.isEqual(this.paymentModel, this.payloadPaymentModel)
  }

  get assigneeIdCombo () {
    return commonModule.personnelCombo;
  }

  findPaymentType(payment: any) {
    let combo: any = commonModule.paymentTypeCombo;
    const foundType = combo.find((item: any) => item.id === payment);
    if(foundType) {
      return { id: foundType.id, payment_type: foundType.payment_type };
    }
  }

  getAssigneeId(name: string) {
    const combo: any = commonModule.personnelPartnersCombo;
    const foundItem = combo.find((item: any) => item.name === name);
    if(foundItem) {
      return { name: foundItem.name, id: foundItem.id };
    } else if(!foundItem && this.payload.payment.assignee_id) {
      return { name: this.payload.payment.assignee_id }
    } else {
      return ''
    }
  }

  get paymentTypesCombo () {
    for (let i = 0; i < commonModule.paymentTypeCombo.length; i++) {
      (commonModule.paymentTypeCombo[i]["payment_type"] as any) = this.$t(commonModule.paymentTypeCombo[i]["payment_type"]);
    }
    return commonModule.paymentTypeCombo;
  }

  clientName(id: string) {
    const combo: any = this.clientsCombo;
    const foundItem = combo.find((item: any) => item.id === id);
    if(foundItem) {
      return foundItem;
    }
    else {
      const allClients: any = commonModule.clientsCombo;
      const foundItem = allClients.find((item: any) => item.id === id);
      if(foundItem) {
        this.clientsCombo.push({...foundItem, disabled: true});
        return foundItem;
      }
      else {
        return ''
      }
    }
  }

  async doEditPayment () {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      this.isEditingPayment = true;
      let paymentObj = _.cloneDeep(this.paymentModel);
      await paymentsModule.editPayment(paymentObj);
      this.isEditingPayment = false;
      this.hideModal();
    }
  }

  formatDate (date: Date): string {
    return TimeUtil.formatDateTime(date)
  }

  onlyDecimalNumbers(evt: any) {
    isDecimalNumber(evt);
  }

  get jobPendingBalance() {
    return String(parseFloat(this.jobBalance.toFixed(2)) + parseFloat(this.payload.payment.amount))
  }

  repayment() {
    this.paymentModel.amount = this.jobPendingBalance;
  }

  @Watch('payment.amount')
    onAmountChange(val: string, oldVal: string) {
      this.amountRule = [];
      if (!val) {
        this.amountRule.push((val: any) => !!val || this.$i18n.t("nonZeroAmountRequired"))
      }
      if(val) {
        this.amountRule.push((val: any) => val > 0 || this.$i18n.t("nonZeroAmountRuleText"))
      }
      this.amountRule.push(  (val: number) => {
      if(parseFloat(this.payload.job.amount_paid) > 0) return( val && val <= parseFloat(this.jobPendingBalance)) || this.$i18n.t("pendingBalanceRule");
      else return true;
    },
    (val: any) => {
      if(this.payload.job.amount_paid == 0) return( val && val <= this.payload.job.cost) || this.$i18n.t("pendingBalanceRule");
      else return true;
    });
  }

  amountRule:any =[
    (val: number) => !!val || this.$i18n.t("nonZeroAmountRequired"),
    (val: number) => val > 0 || this.$i18n.t("nonZeroAmountRuleText"),
    (val: number) => {
      if(parseFloat(this.payload.job.amount_paid) > 0) return( val && val <= parseFloat(this.jobPendingBalance)) || this.$i18n.t("pendingBalanceRule");
      else return true;
    },
    (val: any) => {
      if((parseFloat(this.payload.job.amount_paid) - this.payload.payment.amount) > 0) return( val && val <= this.payload.job.cost) || this.$i18n.t("pendingBalanceRule");
      else return true;
    }
  ]

  clientRule= [
    (val: any) => ((!!val || val == '') && val.name.length > 0) || this.$i18n.t("client_required"),
  ]

  notesRule= [
    (val: any) => {
      if(val != null) return val.length <= 1000 || this.$i18n.t("notesLengthRule");
      else return true;
    },
  ]

  clientsCombo = [
  {
    name: this.payload.installation.info.client_is_business ? this.$t("tenant") + " (" +
    this.payload.installation.info.client_entity_name + " - " +
    this.payload.installation.info.client_lastname + " " +
    this.payload.installation.info.client_firstname + ")"
    : this.$t("tenant") + " (" + this.payload.installation.info.client_lastname + " " +
    this.payload.installation.info.client_firstname + ")",
    is_business: this.payload.installation.info.client_is_business,
    id: this.payload.installation.info.user_id,
    type: 'tenant'
  },
  this.payload.installation.info.owner_id && {
    name: this.payload.installation.info.owner_is_business
    ? this.$t("owner") + " (" + this.payload.installation.info.owner_entity_name + " - " +
    this.payload.installation.info.owner_lastname + " " + this.payload.installation.info.owner_firstname + ")"
    : this.$t("owner") + " (" + this.payload.installation.info.owner_lastname + " " +
        this.payload.installation.info.owner_firstname + ")",
    is_business: this.payload.installation.info.owner_is_business,
    id: this.payload.installation.info.owner_id,
    type: 'owner'
  }
].filter(Boolean); // owner can be null/empty. We filter it out in order to not show an icon with empty text

};
