
  import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
  import { commonModule, actionTypesModule } from '@/store/modules/store-accessor'
  import { randomHexColor, hexColorRemoveLastTwoDigits, isDecimalNumber, periodToText, isNumber, getOptimalTextColor, timeChargedUnitOption } from "@/helpers/data";
  import _ from 'lodash';
  
  @Component
  export default class AddActionTypeModal extends Vue {
    @Prop()
    payload!: any;

    isAddingActionType = false;

    actionTypeModel = {
        text: '',
        color: randomHexColor(), // Set a random / default color for the picker
        cost: '',
        is_periodic: false,
        periodic_days: '',
        periodic_option_value: '',
        periodic_option_combo: { text: "", period: 0 },
        reminder_days: '',
        reminder_option_value: '',
        reminder_option_combo: { text: "", period: 0 },
        periodic_option: '',
        reminder_option: '',
        is_time_charged: false,
        unit: timeChargedUnitOption('HOUR'),
    }

    textRule = [
        (val: any) => !!val || this.$i18n.t("actionTypeRequired"),
        (val: any) => val.length <= 30 || this.$i18n.t("actionTextLengthRule"),
    ]
    costRule = [
      (val: any) => val !== '' && val >= 0 || this.$i18n.t("actionCostRuleText"),
    ]

    periodRule = [
      (val: any) => val !== '' && val > 0 && val.length <= 4 || this.$i18n.t("repeatEveryRuleText"),
    ]

    reminderRule = [
      (val: any) => val !== '' && val > 0 && val.length <= 4 || this.$i18n.t("reminderRuleText"),
    ]

    periodicOptionRule = [
      (val: any) => ((!!val || val == '') && val.text.length > 0) || this.$i18n.t("periodicOptionRuleText"),
    ]

    reminderOptionRule = [
      (val: any) => ((!!val || val == '') && val.text.length > 0) || this.$i18n.t("reminderOptionRuleText"),
    ]
    unitOptionRule = [
      (val: any) => ((!!val || val == '') && val.text.length > 0) || this.$i18n.t("unitOptionRule"),
    ]

    get periodicCombo () {
      return commonModule.periodicCombo;
    }

    get timeChargedUnitCombo() {
      return commonModule.timeChargedUnitCombo;
    }

    optimalTextColor(color: string) {
      return getOptimalTextColor(color);
    }

    defaultPeriodicOption() {
      if(this.periodicCombo) {
        this.actionTypeModel.periodic_option_value = '1';
        return { text: this.$i18n.t('Year'), period: 365 };
      }
      return { text: '', period: 0 };
    }

    defaultPeriodicReminderOption() {
      if(this.periodicCombo) {
        this.actionTypeModel.reminder_option_value = '1';
        return { text: this.$i18n.t('week'), period: 7 };
      }
      return { text: '', period: 0 };
    }

    async hideModal () {
      commonModule.hideModal();
    }
  
    async mounted () {
      commonModule.initSnackbar({});
      await commonModule.getPeriodicCombo(1);
      actionTypesModule.getAllActionTypes();
      await commonModule.getTimeChargedUnitCombo();
      (this.actionTypeModel.periodic_option_combo as any) = this.defaultPeriodicOption();
      (this.actionTypeModel.reminder_option_combo as any) = this.defaultPeriodicReminderOption();
      this.actionTypeModel.unit = this.timeChargedUnitCombo[0];
    }

    get allActionTypes() {
      return actionTypesModule.actionTypes;
    }

    typeFound(type: any) {
      for (let i = 0; i < this.allActionTypes.length; i++) {
        if (this.allActionTypes[i]['text'] === type) {
          return true;
        }
      }
      return false;
    };

    onlyDecimalNumbers(evt: any) {
      isDecimalNumber(evt);
    }

    onlyNumbers(evt: any) {
      isNumber(evt);
    }

    @Watch('actionTypeModel.text')
    onTextChange(val: string, oldVal: string) {
      // Remove typeFound (duplicate) rule if it already exists from a previous check
      if (this.textRule.length > 2) {
        this.textRule.pop();
      }
      if (this.typeFound(this.actionTypeModel.text)) {
        this.textRule.push((val: any) => this.typeFound(val.text) || this.$i18n.t("actionExists"))
      }
    }

    async doAdd () {
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        this.isAddingActionType = true;
        this.actionTypeModel.color = hexColorRemoveLastTwoDigits(this.actionTypeModel.color);

        let actionTypeModelCopy = _.cloneDeep(this.actionTypeModel);

        actionTypeModelCopy.periodic_days = (parseInt(this.actionTypeModel.periodic_option_value) * this.actionTypeModel.periodic_option_combo.period).toString()
        actionTypeModelCopy.periodic_option = this.actionTypeModel.periodic_option_value + periodToText(this.actionTypeModel.periodic_option_combo.period)
        actionTypeModelCopy.reminder_days = (parseInt(this.actionTypeModel.reminder_option_value) * this.actionTypeModel.reminder_option_combo.period).toString()
        actionTypeModelCopy.reminder_option = this.actionTypeModel.reminder_option_value + periodToText(this.actionTypeModel.reminder_option_combo.period)

        await actionTypesModule.addActionType(actionTypeModelCopy);
        this.hideModal();
        await actionTypesModule.getAllActionTypes();
      }
    }
  };
