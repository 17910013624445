
import { Component, Vue, Prop } from 'vue-property-decorator'
import { commonModule, clientsModule, installationsModule } from '@/store/modules/store-accessor'

@Component
export default class DeleteClientModal extends Vue {
  @Prop()
  payload!: any;

  isDeletingClient = false;

async mounted() {
  installationsModule.getInstallationsByIdAction(this.payload.client.id);
  commonModule.initSnackbar({});
}

get clientInstallations() {
  return installationsModule.installationsById;
}

  async hideModal () {
    commonModule.hideModal();
  }

  async hideSideView () {
    commonModule.hideSideview();
  }

  async doDeleteClient () {
        this.isDeletingClient = true;
        await clientsModule.deleteClient(this.payload.client);
        await installationsModule.getInstallationsAction(installationsModule.filters);
        this.hideModal();
        this.hideSideView();
  }
};
