import Vue from "vue";
import App from "./App.vue";
import router from "./router/router";
import store from "./store/store";
import Keycloak from "keycloak-js";
import VueLogger from "vuejs-logger";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import axios from "axios";
import jwt_decode from "jwt-decode";
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete';

import "leaflet/dist/leaflet.css";
import vClickOutside from 'v-click-outside'

import { commonModule, companyModule } from "@/store/modules/store-accessor"

Vue.config.productionTip = false;

const isProduction = process.env.NODE_ENV === "production";

Vue.prototype.$http = axios;
axios.defaults.headers.get["Accept"] = "application/json, application/pdf";

const options = {
  isEnabled: true,
  logLevel: isProduction ? "error" : "debug",
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true,
};

Vue.use(vClickOutside)

Vue.use(VuetifyGoogleAutocomplete, {
  apiKey: (process.env.NODE_ENV === "production") ? "GOOGLE_MAPS_KEY" : "AIzaSyDbgvgejSNtTI410Z1ihbNGl-0iEpfPl0k", // Can also be an object. E.g, for Google Maps Premium API, pass `{ client: <YOUR-CLIENT-ID> }`
  language: localStorage.getItem('lang') || 'el', // Optional
  installComponents: true, // Optional (default: true) - false, if you want to locally install components
  vueGoogleMapsCompatibility: false, // Optional (default: false) - true, requires vue2-google-maps to be configured see https://github.com/xkjyeah/vue-google-maps
});

const initOptions: any = {
  url: (process.env.NODE_ENV === "production")? "SSO_URL" : "https://sso.domx-dev.com/auth",
  realm: "gastools",
  clientId: "gas-tools-frontend",
  onLoad: "login-required",
};

const keycloak = Keycloak(initOptions);
Vue.prototype.$keycloak = keycloak;

keycloak
  .init({ onLoad: initOptions.onLoad })
  .then((auth) => {
    if (!auth) {
      window.location.reload();
    } else {
      // Vue.$log.info("Authenticated");
      if (keycloak.token !== undefined) {
        const tokenClaims: Record<string, any> = jwt_decode(keycloak.token);
        commonModule.setUserInfo(tokenClaims)
        commonModule.setControlAccess(tokenClaims["control_access"]);

        if(!tokenClaims["portal_access"]) {
          //This is not a great solution but it works. Logout user if token claim does is not acceptable
          //Logout user if he has no right ot access control
          keycloak.logout();
        }
      }
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + keycloak.token;

      (window as any).sharedKeycloak = keycloak 
      companyModule.getCompanyInfo();
      new Vue({
        router,
        store,
        vuetify,
        i18n,
        render: h => h(App, { props: { keycloak: keycloak } })
      }).$mount("#app");
    }

    //Token Refresh
    setInterval(() => {
      keycloak
        .updateToken(240) // this number is in seconds and should be a little lower than Access Token Lifespan (5 mins)
        .then((refreshed) => {
          if (refreshed) {
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + keycloak.token;
          }
        })
        .catch((error) => {
          // Vue.$log.error("Failed to refresh token");
        });
    }, 60000); // this number is in milliseconds in order call refresh token every minute
  })
  .catch((err) => {
    console.log("err:", err);
    // Vue.$log.error("Authenticated Failed");
  });
