import { render, staticRenderFns } from "./ToggleIsActivePartnerModal.vue?vue&type=template&id=60c8805b&scoped=true&class=fill-height&"
import script from "./ToggleIsActivePartnerModal.vue?vue&type=script&lang=ts&"
export * from "./ToggleIsActivePartnerModal.vue?vue&type=script&lang=ts&"
import style0 from "./ToggleIsActivePartnerModal.vue?vue&type=style&index=0&id=60c8805b&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60c8805b",
  null
  
)

export default component.exports