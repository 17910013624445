
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { commonModule, installationsModule, clientsModule, companyModule } from '@/store/modules/store-accessor'
import { TimeUtil } from '@/helpers/TimeUtil'
import { formatAmount } from "@/helpers/data";

@Component
export default class ClientsSideviewInstallationsTab extends Vue {
 @Prop() client: Record<string, any>;

  formatDate (date: Date): string {
    return TimeUtil.formatDateTime(date)
  }

  get installationsExist() {
    return this.client.installations.length > 0
  }

  get clientInstallations() {
    return this.client.installations;
  }

  async openInstallation(installationId: any) {
    await installationsModule.getInstallationInfo(installationId);
    clientsModule.unsetCurrentClient();
    commonModule.showSideview({ name: 'installations-sideview' , payload: { installationId: installationId} })
  }

  formatBalance(balance: number) {
    return formatAmount(balance);
  }

  translateRelation(relation: Record<string, any>) {
    return relation.filter(Boolean).map((item: any) => this.$t(item.toLowerCase())).join(', ')
  }

  showInstallationDescription() {
    return (companyModule.company?.preferences as any)?.installation_description
  }

  tableheaders: any [] = [
    { class: 'row-style', text: '', align: 'left', sortable: false, value: 'is_premium', width: '10px' },
    { class:'font-weight-black', text: this.$t("relation"), sortable: false, value: 'relation' },
    { class:'font-weight-black', text: this.$t("tag"), sortable: false, value: 'tag_id'},
    { class:'font-weight-black', text: this.$t("address"), sortable: false, value: 'address' },
    { class:'font-weight-black', text: this.$t("area"), sortable: false, value: 'area'},
    { class:'font-weight-black', text: this.$t("totalCost"), sortable: true, value: 'cost' },
    { class:'font-weight-black', text: this.$t("balance"), sortable: true, value: 'balance' },
    { class:'', text: '', value: 'data-table-expand' }, // Move expand arrow to the right
  ]

};
