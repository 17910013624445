
import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapActions, mapState } from "vuex";
import config from "../../config";
import { commonModule, statsModule } from '@/store/modules/store-accessor'


@Component
export default class Header extends Vue {

  currentLanguage = ((localStorage.getItem('lang') || 'el') === 'en') ? 'en' : 'el'; // for legacy reasons

  languages = ["el", "en"];

  updateLanguage(language: any) {
    this.currentLanguage = language;
    localStorage.setItem("lang", this.currentLanguage);
    this.$vuetify.lang.current = language;
    this.$root.$i18n.locale = language;
    this.$router.go(0); // Reload the whole app/page
  }

  get logo() {
    return require("@/assets/img/new_logo_domX.png")
  }

  get config() {
    return config;
  }

  get user() {
    return commonModule.user;
  }

  get controlAccess() {
    return commonModule.controlAccess;
  }

  get controlUrl () {
    return (process.env.NODE_ENV === 'production' ? 'CONTROL_URL' : 'https://control.business.domx-dev.com/')
  }

  logOut() {
    commonModule.showModal({ name: 'log-out-modal' })
  }

  drawerChange() {
    statsModule.changeDrawerState();
  }

  openLink (link: string) {
      window.open(link, '_blank');
  }
}
