import { BaseApi } from '@/helpers/base-api'

export default class FilterApi extends BaseApi {
  constructor () {
    super('/api/v1/filters')
    this.errorMessage = 'There was an issue when trying to fetch the filters information. If this error persists, please contact our support.'

  }

  public getItemFilters = (): Promise<ItemFilterDto[]> => this.instance.get('/items')
  public getInstallationsFilters = (): Promise<InstallationsFilterDto[]> => this.instance.get('/installations')
  public getJobsFilters = (): Promise<JobsFilterDto> => this.instance.get('/jobs')
  public getMapFilters = (): Promise<InstallationsFilterDto[]> => this.instance.get('/map')
  public getAppointmentsFilters = (): Promise<any[]> => this.instance.get('/appointments')
  public getCalendarFilters = (): Promise<any[]> => this.instance.get('/calendar')
  public getIsPremiumFilters = (): Promise<IsPremiumFilterDto[]> => this.instance.get('/is_premium')
  public getDashboardTasksFilter = (): Promise<any[]> => this.instance.get('/dashboard/tasks')
  public getTasksFilter = (): Promise<TaskFilterDto[]> => this.instance.get('/tasks')
}