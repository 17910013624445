import { BaseApi } from '@/helpers/base-api'

export default class PartnersApi extends BaseApi {
  constructor () {
    super('/api/v1/partners')
    this.errorMessage = 'There was an issue when trying to fetch the partners. If this error persists, please contact our support.'

  }
  public getAllPartners = (): Promise<any[]> => this.instance.get('');
  public getPartner = (partnerId: string): Promise<PartnerDetailsDto> => this.instance.get(`/${partnerId}`)

  public createPartner = (data: Record<string, unknown>): Promise<any> => this.instance.post('', data);

  public updatePartner = (partnerId: string, data: Record<string, unknown>): Promise<string> => this.instance.patch(`/${partnerId}`, data);

  public deletePartner = (partnerId: string): Promise<string> => this.instance.delete(`/${partnerId}`);
}
