
import { Component, Vue, Prop } from 'vue-property-decorator'
import { TimeUtil } from '@/helpers/TimeUtil'
import { commonModule, installationsModule, companyModule } from '@/store/modules/store-accessor'
import FileSaver from 'file-saver';
import dayjs from "dayjs";

@Component
export default class InstallationsSideviewPhotosTab extends Vue {
 @Prop() installation: Record<string, any>;

 isDowloadingPhoto = false;

  get photos() {
    return this.installation.images
  }

  get photosExist() {
    return this.installation.images.length > 0
  }

  photoPath(photo: any) {
    return `${photo.base_url}/${photo.src}`
  }

  async deletePhoto(photo: any) {
    commonModule.showModal({ name: 'delete-installation-photo-modal', payload: { photo: photo } })
  }

  async downloadPhoto(photo: Record<string, any>) {
    this.isDowloadingPhoto = true;
    const companyInfo = await companyModule.getCompanyInfo();
    let downloadPhoto = await installationsModule.getInstallationPhoto({installationId: photo.installation_id, photoId: photo.id} );
    const photoName = `${this.installation.info.tag_id}_${dayjs(photo.created_at).format('DD_MM_YYYY_HH_mm_ss')}.jpeg`;

    downloadPhoto = new File([downloadPhoto], photoName);
    FileSaver.saveAs(downloadPhoto);
    this.isDowloadingPhoto = false;
  }

  formatDate (date: Date): string {
    return TimeUtil.formatDateTime(date)
  }

  addPhotoToInstallation() {
    commonModule.showModal({ name: 'add-photo-to-installation-modal', payload: { installation: this.installation } })
  }

  editPhoto(photo: any) {
    commonModule.showModal({ name: 'edit-installation-photo-modal', payload: { photo: photo } })
  }

};
