
import { filterModule, itemsModule } from "@/store/modules/store-accessor";
import Vue from "vue";
import Component from "vue-class-component";
import FilterList from "./FilterList.vue";

@Component({
  components: {
    FilterList,
  },
})
export default class ItemFilter extends Vue {

get itemFilters() {
  return filterModule.itemFilters;
}

  selectedMakes: string[];
  selectedModels: string[];
  selectedModelYears: string[];
  selectedItems: string[];


  updateMakes(data: string[]) {
    this.selectedMakes = data;
    this.submit();
  }

  updateModels(data: string[]) {
    this.selectedModels = data;
    this.submit();
  }

  updateModelYears(data: string[]) {
    this.selectedModelYears = data;
    this.submit();
  }

  updateItems(data: string[]) {
    this.selectedItems = data;
    this.submit();
  }

  async submit() {
    itemsModule.setIsLoadingTable(true);
    let queryObj: any = {};

    let itemTexts = [];
    let tempText;

    if (this.selectedItems != undefined) {
      for (let i = 0; i < this.selectedItems.length; i++) {
        tempText = (this.selectedItems[i] as any).text
        itemTexts.push(tempText);
      }
    }

    if (this.selectedMakes != undefined && this.selectedMakes.length > 0) {
      queryObj["make[]"] = this.selectedMakes;
    }

    if (this.selectedModels != undefined && this.selectedModels.length > 0) {
      queryObj["model[]"] = this.selectedModels;
    }

    if (this.selectedModelYears != undefined && this.selectedModelYears.length > 0) {
      queryObj["model_year[]"] = this.selectedModelYears;
    }

    if (itemTexts != undefined && itemTexts.length > 0) {
      queryObj["type_name[]"] = itemTexts;
    }

    if (itemsModule.search != '') {
      itemsModule.setSearch(itemsModule.search);
      queryObj["search"] = itemsModule.search;
    }

    this.$router.replace({ query: queryObj }).catch(err => {/* */ });

    // Check if user selected any of the queries
    const queryIndex = this.$route.fullPath.indexOf('?');
    if (queryIndex >= 0) {
      const query = this.$route.fullPath.substring(queryIndex);
      itemsModule.setQueryObject(queryObj);
      itemsModule.setFilters(query);
    } else {
      itemsModule.setFilters("");
      itemsModule.setQueryObject(queryObj); // Will be empty ({})
    }
    await itemsModule.getAllInventoryItems(itemsModule.filters);
    itemsModule.setIsLoadingTable(false);
  }
}
