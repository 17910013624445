
import { Component, Vue, Prop } from 'vue-property-decorator'

import { commonModule } from '@/store/modules/store-accessor'

@Component
export default class TheSnackbar extends Vue {

  get snackbarVisible () {
    return commonModule.snackbar.visible
  }

  get snackbarColor () {
    return commonModule.snackbar.color
  }

  get snackbarTimeout () {
    return commonModule.snackbar.timeout
  }

  get snackbarText () {
    return commonModule.snackbar.text
  }

};
