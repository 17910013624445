import { render, staticRenderFns } from "./EditActionTypeModal.vue?vue&type=template&id=48b2cbf8&scoped=true&class=fill-height&"
import script from "./EditActionTypeModal.vue?vue&type=script&lang=ts&"
export * from "./EditActionTypeModal.vue?vue&type=script&lang=ts&"
import style0 from "./EditActionTypeModal.vue?vue&type=style&index=0&id=48b2cbf8&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48b2cbf8",
  null
  
)

export default component.exports