import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import KeycloakSession from '@/helpers/keycloak-session'

import Layout from "../components/Layout/Layout.vue";

//pages
import Dashboard from "../views/Dashboard.vue";
import Clients from "../views/Clients.vue";
import Installations from "../views/Installations.vue";
import Jobs from "../views/Jobs.vue";
import Items from "../views/Items.vue";
import Map from "../views/Map.vue";
import Payments from "../views/Payments.vue";
import Personnel from "../views/Personnel.vue";
import Calendar from "../views/Calendar.vue";
import Error from "../views/Error.vue";
import Management from "../views/Management.vue";
import MyCompany from "../views/MyCompany.vue";
import Appointments from "../views/Appointments.vue";
import Tasks from "../views/Tasks.vue";

Vue.use(VueRouter);

function isAdmin () {
  const kc = new KeycloakSession()
  return kc.userHasRole('admin');
}

function isAdminOrEditor () {
  const kc = new KeycloakSession()
  return kc.userHasRole('admin') || kc.userHasRole('editor');
}

function isSuperAdmin () {
  const kc = new KeycloakSession()
  return kc.userHasRole('super-admin');
}

const routes: Array<RouteConfig> = [
  { path: "/404", component: Error, name: "Error" },
  {
    path: "/",
    redirect: "dashboard",
    name: "Layout",
    component: Layout,
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: Dashboard,
      },
      {
        path: "clients",
        name: "Clients",
        component: Clients,
      },
      {
        path: "installations",
        name: "Installations",
        component: Installations,
      },
      {
        path: "jobs",
        name: "Jobs",
        component: Jobs,
      },
      {
        path: "items",
        name: "Items",
        component: Items,
      },
      {
        path: "map",
        name: "Map",
        component: Map,
      },
      {
        path: "calendar",
        name: "Calendar",
        component: Calendar,
      },
      {
        path: "my-company",
        name: "My Company",
        component: MyCompany,
        beforeEnter: (to, from, next) => {
          // reject the navigation
          if (!isAdminOrEditor()) {
            next('/404');
          } else {
            next();
          }
        },
      },
      {
        path: "appointments",
        name: "Appointments",
        component: Appointments,
      },
      {
        path: "tasks",
        name: "Tasks",
        component: Tasks,
      },
    ],
  },
  { path: "*", redirect: "/404" },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
