
  import { Component, Vue, Prop } from 'vue-property-decorator'
  import { commonModule, clientsModule, partnersModule } from '@/store/modules/store-accessor'
  import _ from 'lodash';
  import { primaryContactExists } from '@/helpers/data';
  
  @Component
  export default class EditPartnerModal extends Vue {
    @Prop()
    payload!: any;
  
    isEditingPartner = false;
  
    get currPartner() {
      let partner = _.cloneDeep(partnersModule.currentPartner);
      return partner;
    }
  
    copyCurrPartner(currentPartnerContacts: any) {
      if(currentPartnerContacts !== undefined){
        for (let i = 0; i < currentPartnerContacts.length; i++) {
          currentPartnerContacts[i].contact_type = this.$t(currentPartnerContacts[i].contact_type);
        }
        return [...currentPartnerContacts]
      }
      else{
        return []
      }
    }
  
    partnerModel = {
      firstname: this.currPartner.info.firstname, 
      lastname: this.currPartner.info.lastname,
      email: this.currPartner.info.email,
      id: this.currPartner.info.partner_id,
      contacts: this.copyCurrPartner(this.currPartner.contacts),
      view: this.payload.view,
      description: this.payload.description,
      notes: this.payload.partner.notes,
      entity_name: this.payload.partner.entity_name || '',
      vat: this.payload.partner.vat || '',
      tax_office: this.payload.partner.tax_office || '',
      address: this.payload.partner.address || '',
      profession: this.payload.partner.profession || '',
      is_business: this.payload.partner.is_business || false,
    }
  
    get contactTypeCombo() {
      for (let i = 0; i < commonModule.contactTypeCombo.length; i++) {
        (commonModule.contactTypeCombo[i]["contact_info_type"] as any) = this.$t(commonModule.contactTypeCombo[i]["contact_info_type"]);
      }
      return commonModule.contactTypeCombo;
    }
  
    firstnameRule= [
      (val: any) => !!val || this.$i18n.t("firstname_required"),
      (val: any) => val.length <= 45 || this.$i18n.t("client_form_name_length_rule"),
    ]
    lastnameRule= [
      (val: any) => !!val || this.$i18n.t("lastname_required"),
      (val: any) => val.length <= 45 || this.$i18n.t("client_form_lastname_length_rule"),
    ]
    emailRule:any =[
      (val: any) => {
        if(val != null) return val.length <= 70 || this.$i18n.t("client_form_email_length_rule");
        else return true;
      },
      (val: any) => {
        //eslint-disable-next-line
        const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if(val != null && val.length > 0) return reg.test(val) || this.$i18n.t("form_email_rule");
        else return true;
      },
    ]
    contactTypeRule= [
      (val: any) => !!val && val.contact_info_type != "" || this.$i18n.t("contact_type_required")
    ]
    phoneRule= [
      (val: any) => !!val || this.$i18n.t("phone_required"),
      (val: any) => val.length <= 20 || this.$i18n.t("client_form_phone_length_rule"),
      (val: any) => {
      //eslint-disable-next-line
      let reg = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,14}$/im;
      if(val.length > 0) return reg.test(val.replace(/ /g, '').replace(/-/g, '')) || this.$i18n.t("form_phone_rule");
      else return true;
    },
    ]
    descriptionRule= [
      (val: any) => {
        if(val != null) return val.length <= 80 || this.$i18n.t("client_form_description_length_rule");
        else return true;
      },
    ]
    notesRule= [
      (val: any) => {
        if(val != null) return val.length <= 1000 || this.$i18n.t("notesLengthRule");
        else return true;
      },
    ]
    entityNameRule = [
      (val: any) => !!val || this.$i18n.t("entityNameRequired"),
      (val: any) => val.length <= 100 || this.$i18n.t("entityNameRule"),
    ]
    vatRule = [
      (val: any) => !!val || this.$i18n.t("vatRequired"),
      (val: any) => val.length <= 14 || this.$i18n.t("vatRule"),
    ]
  
    onCheckboxChange(ev: any, item: any) {
      if(ev === true) {
          this.partnerModel.contacts.forEach((element: any) => 
          {
            if (element.id === item.id) {
              element.is_primary = true;
            }
            else {
              element.is_primary = false;
            }
          });
      }
  
    }
  
    async hideModal () {
      commonModule.hideModal();
    }
  
    async mounted() {
      commonModule.initSnackbar({});
      await commonModule.getContactTypeCombo();
      if(this.payload.contacts.length === 0) {
        this.addPhone();
      }
    }
  
    addPhone() {
      let newContact = {
          id: Math.random() * -1, // Hack...Need Random and non-integer and also negative, for this to work with the backend properly
          contact_type:(this.contactTypeCombo[1] as any),
          contact_type_id: 0,
          is_primary: false,
          description: '',
          phone: ''
      } as any
  
      if (this.partnerModel.contacts.length == 0) {
        newContact.is_primary = true;
      }
      this.partnerModel.contacts.push(newContact);
    }
  
    deletePhone(index: number) {
      if (this.partnerModel.contacts.length > 1) {
        this.partnerModel.contacts.splice(index, 1);
        if (!primaryContactExists(this.partnerModel)) {
          this.partnerModel.contacts[0].is_primary = true;
        }
      } else if (this.partnerModel.contacts.length === 1) {
        this.partnerModel.contacts[0].is_primary = true;
      }
    }
  
    doEditPartner() {
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        this.isEditingPartner = true;
        this.partnerModel.contacts.map((item) => {
          if(item.contact_type.id !== undefined) {
            item.contact_type_id = item.contact_type.id
          } 
        })
        
        partnersModule.editPartner(this.partnerModel);
        this.hideModal();
      } 
    }
  };
  