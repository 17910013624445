import { render, staticRenderFns } from "./DeleteInstallationFileModal.vue?vue&type=template&id=675a4e6c&class=fill-height&"
import script from "./DeleteInstallationFileModal.vue?vue&type=script&lang=ts&"
export * from "./DeleteInstallationFileModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports