
import { Component, Vue, Prop } from 'vue-property-decorator'
import { commonModule, installationsModule, partnersModule } from '@/store/modules/store-accessor'

@Component
export default class DeleteJobPhotoModal extends Vue {
      @Prop()
      payload!: any;
      photoUrl = `${this.payload.photo.base_url}${this.payload.photo.src}`;

      isDeleting = false;

      async mounted() {
          commonModule.initSnackbar({});
      }

  
      async hideModal() {
          commonModule.hideSecondModal();
      }

      async doDeleteInstallationPhoto() {
          this.isDeleting = true;
          await installationsModule.deleteInstallationPhoto({ installationId: this.payload.photo.installation_id, photoId: this.payload.photo.id });

          if(this.$route.name === 'My Company' && partnersModule.currentPartner.info.partner_id) {
            await partnersModule.getPartnersInfo(partnersModule.currentPartner.info.partner_id);
          } else {
            await installationsModule.getInstallationInfo(this.payload.photo.installation_id);
          }
          this.hideModal();
      }
};
