
import { Component, Vue, Prop } from 'vue-property-decorator'
import { commonModule, itemsModule, filterModule, itemTypesModule } from '@/store/modules/store-accessor'

@Component
export default class EditInventoryItemModal extends Vue {
  @Prop()
  payload!: any;
  modelSearch = '';
  isEditingItem = false;
  
    updItemModel = {
        make: this.payload.editItem.make,
        model: this.payload.editItem.model,
        model_year: this.payload.editItem.model_year,
        notes: this.payload.editItem.notes || '',
        warranty: this.payload.editItem.warranty,
        item_type_id: {text: this.payload.editItem.type_name, id: this.payload.editItem.item_type_id},
        id: this.payload.editItem.id,
    }

  async hideModal () {
    commonModule.hideModal();
  }

  get itemFilters() {
    return filterModule.itemFilters;
  }

  async mounted() {
    commonModule.initSnackbar({});
    await commonModule.getPersonnelCombo();
    await commonModule.getActionCombo();
    await itemTypesModule.getItemTypesCombo();
    await commonModule.getFuelTypeCombo();
    await commonModule.getPlacementTypeCombo();
  }

  itemTypeRule= [
    (val: any) => this.typeFound(val) || this.$i18n.t("item_type_required"),
  ]
  makeRule= [
    (val: any) => !!val || this.$i18n.t("make_required"),
  ]
  notesRule= [
    (val: any) => {
      if(val != null) return val.length <= 1000 || this.$i18n.t("notesLengthRule");
      else return true;
    },
  ]

  get inventoryItemsCombo() {
      return itemTypesModule.itemTypesCombo;
  }

  doUpdate() {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      this.isEditingItem = true;
      if(this.modelSearch && this.modelSearch.length > 0) {
        this.updItemModel.model = this.modelSearch;
      }
      itemsModule.editItem(this.updItemModel);
      this.hideModal()
    }
  }

  typeFound(type: any) {
    if (type) {
      const found = this.inventoryItemsCombo?.find(item => {
        return item['text'] === type.text;
      });
      return found == undefined ? false : true;
    }
    return false;
  };

  isNumber(evt: any) {
      evt = evt || window.event;
      let charCode = evt.which || evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
      return false;
  }
};
