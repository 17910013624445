
import L from "leaflet";
import { LMap, LTileLayer, LMarker, LPopup, LIcon, LCircleMarker, LLayerGroup, LFeatureGroup, LControlLayers } from "vue2-leaflet";
import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";
import { Component, Vue, Watch } from 'vue-property-decorator'
import "../styles/MarkerCluster.css";
import "../styles/MarkerCluster.Default.css";
import TheLoadingIndicator from '@/components/TheLoadingIndicator.vue'

import { commonModule, mapModule, filterModule, installationsModule } from '@/store/modules/store-accessor'
import MapFilter from '../components/Filter/MapFilter.vue'
import { mapTileProviders } from "@/helpers/data";

@Component({
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LCircleMarker,
    LLayerGroup,
    LFeatureGroup,
    LPopup,
    LIcon,
    Vue2LeafletMarkerCluster,
    MapFilter,
    TheLoadingIndicator,
    LControlLayers,
  }
})

export default class Map extends Vue {
  canAddLayers = false;
  map: any;
  popup: any;
  features: any;
  staticAnchor = [16, 37]
  zoom = 7
  center = L.latLng(39.638902, 22.946519)
  url = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  attribution =
    '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
  withPopup = L.latLng(39.638902, 22.946519)
  currentZoom = 8
  currentCenter = L.latLng(39.638902, 22.946519)
  showParagraph = false
  mapOptions = {
    zoomSnap: 0.5,
    preferCanvas: true,
  }
  selectedMarker: any = null;
  selecterMarkerTrueLocation: any = null;
  markerList: any = [];
  markerCluster = L.markerClusterGroup({ chunkedLoading: true });

  get markers() {
    if(mapModule.markers.length > 0) {
      this.canAddLayers = true;
    }
    else {
      this.canAddLayers = false;
    }
    return mapModule.markers
  }

  get markersLength() {
    return this.markers.length
  }

  get isLoading() {
    return mapModule.isLoading;
  }

  @Watch('selectedMarker')
  openMarkerPopup() {
    this.features.mapObject.openPopup(this.selecterMarkerTrueLocation);
  }

  mapReady() {
    this.map = this.$refs.installationMap as LMap
    this.popup = this.$refs.popup as LPopup
    this.features = this.$refs.features as LLayerGroup

    //This can be used to center based on Installation 0 when the feature is implemented
    this.map.mapObject.setView([38.5627882974596, 24.778244688469066], 6);
  }

  @Watch('canAddLayers')
  addMarkers() {
    this.markerCluster.clearLayers();
    this.markerList = [];
    if (this.markersLength > 0) {
      for (let i = 0; i < this.markers.length; i++) {
        let markerTest = L.circleMarker(this.markers[i].coord, {
          radius: 10,
          fillOpacity: 0.6,
          fillColor: this.$vuetify.theme.currentTheme.success, //Change color based on last Service,
          opacity: 1,
          weight: 1,
          color: this.$vuetify.theme.currentTheme.success,
        });

        markerTest.popupData = this.markers[i];

        markerTest.on('click', (e: any) => {
          this.selecterMarkerTrueLocation = e.latLng;
          this.selectedMarker = this.markers[i];
        });

        markerTest.bindPopup(this.popup.mapObject);
        this.markerList.push(markerTest);
      }

      this.markerCluster.addLayers(this.markerList);
      this.map.mapObject.addLayer(this.markerCluster);
      mapModule.setIsLoading(false);

    }
  }

  async selectInstallation(id: string) {
    try {
      mapModule.setIsLoading(true);
      const installationExists = await installationsModule.getInstallationInfo(id);
      mapModule.setIsLoading(false);
      // Check if installation still exists
      if (installationExists) {
        commonModule.showSideview({ name: 'installations-sideview', payload: { installationId: id } })
      }
    } catch (error) {
      mapModule.setIsLoading(false);
    }
  }

  async mounted () {
    this.canAddLayers = false;
    this.markerList = [];
    mapModule.setIsLoading(true);
    await mapModule.getMarkersAction('');
    filterModule.getMapFilters();
    commonModule.initSnackbar({});
  }

  destroyed() {
    mapModule.clearMarkers();
  }

  async refreshData() {
    mapModule.setIsLoading(true);
    await mapModule.getMarkersAction(mapModule.filters);
    await filterModule.getMapFilters();
    mapModule.setIsLoading(false);
  }

  mapTileProviders() {
    return mapTileProviders();
  }

}
