import { BaseApi } from '@/helpers/base-api'

export default class TasksApi extends BaseApi {
  constructor () {
    super('/api/v1/tasks')
    this.errorMessage = 'There was an issue when trying to fetch the tasks. If this error persists, please contact our support.'
  }

  public getAllTasks = (query: string): Promise<TaskDto[]> => this.instance.get(`${query}`);
  public getPendingTasks = (): Promise<TaskDto[]> => this.instance.get(`/pending`);
  public getTaskDetails = (taskId: string): Promise<TaskDto> => this.instance.get(`/${taskId}`);

  public createTask = (data: Record<string, any>): Promise<JSON> => this.instance.post('', data);
  public getTasksPdfExport = (query: string, data: Record<string, unknown>): Promise<Blob> => this.instance.post(`/export${query}`, data, { responseType: 'blob' })
  public getTaskPdfExport = (data: Record<string, unknown>): Promise<Blob> => this.instance.post(`/${data.taskId}/export`, data, { responseType: 'blob' })

  public updateTask = (taskId: string, data: Record<string, unknown>): Promise<JSON> => this.instance.patch(`/${taskId}`, data);
  public completeTask = (taskId: string, data: Record<string, unknown>): Promise<JSON> => this.instance.patch(`/${taskId}/complete`, data);

  public deleteTask = (taskId: string): Promise<JSON> => this.instance.delete(`/${taskId}`)
}
