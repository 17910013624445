
import { clientsModule } from "@/store/modules/store-accessor";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import FilterList from "./FilterList.vue";

@Component({
  components: {
    FilterList,
  },
})
export default class ClientFilter extends Vue {
  isBusinessOptions = [{text: [this.$t('individualClient')], value: ['false'], icon: 'mdi-account'}, {text: [this.$t('company')], value: ['true'], icon: 'mdi-domain'}];

  selectedIsBusiness: string;

  updateIsBusiness(data: any) {
    if (data != undefined) {
      this.selectedIsBusiness = data.value.toString();
    } else {
      this.selectedIsBusiness = ''; // clear filter
    }
    this.submit();
  }

  async submit() {
    clientsModule.setIsLoadingTable(true);
    let queryObj: any = {};

    if (this.selectedIsBusiness != undefined && this.selectedIsBusiness.length > 0) {
      queryObj["is_business"] = this.selectedIsBusiness;
    }

    if (clientsModule.search != '') {
      clientsModule.setSearch(clientsModule.search);
      queryObj["search"] = clientsModule.search;
    }

    this.$router.replace({ query: queryObj }).catch(err => {/* */ });

    // Check if user selected any of the queries
    const queryIndex = this.$route.fullPath.indexOf('?');
    if(queryIndex >= 0) {
      const query = this.$route.fullPath.substring(queryIndex);
      clientsModule.setQueryObject(queryObj);
      clientsModule.setFilters(query);
      clientsModule.setQueryObject(queryObj);
    } else {
      clientsModule.setFilters("");
      clientsModule.setQueryObject(queryObj); // Will be empty ({})
    }
    await clientsModule.getClientsAction(clientsModule.filters)
    clientsModule.setIsLoadingTable(false);
  }
}
