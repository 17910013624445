
import { Component, Vue, Prop } from 'vue-property-decorator'
import LogOutModal from './Modals/LogOutModal.vue'
import AddClientModal from '@/components/Modals/Clients/AddClientModal.vue'
import DeleteClientModal from '@/components/Modals/Clients/DeleteClientModal.vue'
import EditClientModal from '@/components/Modals/Clients/EditClientModal.vue'
import AddInstallationModal from '@/components/Modals/Installations/AddInstallationModal.vue'
import DeleteInstallationModal from '@/components/Modals/Installations/DeleteInstallationModal.vue'
import EditInstallationModal from '@/components/Modals/Installations/EditInstallationModal.vue'
import AddAppointmentModal from '@/components/Modals/Appointments/AddAppointmentModal.vue'
import AddJobModal from '@/components/Modals/Jobs/AddJobModal.vue'
import EditJobModal from '@/components/Modals/Jobs/EditJobModal.vue'
import DeleteJobModal from '@/components/Modals/Jobs/DeleteJobModal.vue'
import AddInventoryItemModal from './Modals/InventoryItems/AddInventoryItemModal.vue'
import DeleteInventoryItemModal from './Modals/InventoryItems/DeleteInventoryItemModal.vue'
import EditInventoryItemModal from './Modals/InventoryItems/EditInventoryItemModal.vue'
import AddAppointmentToJobModal from '@/components/Modals/Jobs/AddAppointmentToJobModal.vue'
import AddPaymentToJobModal from '@/components/Modals/Jobs/AddPaymentToJobModal.vue'
import AddActionsToJobModal from '@/components/Modals/Jobs/AddActionsToJobModal.vue'
import AddMaterialsToJobModal from '@/components/Modals/Jobs/AddMaterialsToJobModal.vue'
import AddInstallationItemModal from './Modals/InstallationItems/AddInstallationItemModal.vue'
import DeleteInstallationItemModal from './Modals/InstallationItems/DeleteInstallationItemModal.vue'
import EditInstallationItemModal from './Modals/InstallationItems/EditInstallationItemModal.vue'
import EditAppointmentModal from '@/components/Modals/Appointments/EditAppointmentModal.vue'
import DeleteAppointmentModal from '@/components/Modals/Appointments/DeleteAppointmentModal.vue'
import EndAppointmentModal from '@/components/Modals/Appointments/EndAppointmentModal.vue'
import CancelAppointmentModal from '@/components/Modals/Appointments/CancelAppointmentModal.vue'
import DeleteActionModal from '@/components/Modals/Jobs/DeleteActionModal.vue'
import EditActionModal from '@/components/Modals/Jobs/EditActionModal.vue'
import DeleteMaterialModal from '@/components/Modals/Jobs/DeleteMaterialModal.vue'
import EditMaterialModal from '@/components/Modals/Jobs/EditMaterialModal.vue'
import DeletePaymentModal from '@/components/Modals/Jobs/DeletePaymentModal.vue'
import EditPaymentModal from '@/components/Modals/Jobs/EditPaymentModal.vue'
import AddTaskModal from '@/components/Modals/Tasks/AddTaskModal.vue'
import EditTaskModal from '@/components/Modals/Tasks/EditTaskModal.vue'
import DeleteTaskModal from '@/components/Modals/Tasks/DeleteTaskModal.vue'
import CompleteTaskModal from '@/components/Modals/Tasks/CompleteTaskModal.vue'
import EditActionTypeModal from '@/components/Modals/ActionTypes/EditActionTypeModal.vue'
import AddActionTypeModal from '@/components/Modals/ActionTypes/AddActionTypeModal.vue'
import DeleteActionTypeModal from '@/components/Modals/ActionTypes/DeleteActionTypeModal.vue'
import AddItemTypeModal from '@/components/Modals/ItemTypes/AddItemTypeModal.vue'
import EditItemTypeModal from '@/components/Modals/ItemTypes/EditItemTypeModal.vue'
import DeleteItemTypeModal from '@/components/Modals/ItemTypes/DeleteItemTypeModal.vue'
import ToggleArchiveItemTypeModal from '@/components/Modals/ItemTypes/ToggleArchiveItemTypeModal.vue'
import ToggleArchiveActionTypeModal from '@/components/Modals/ActionTypes/ToggleArchiveActionTypeModal.vue'
import AddPhotoToInstallationModal from '@/components/Modals/Photos/AddPhotoToInstallationModal.vue'
import DeleteInstallationPhotoModal from '@/components/Modals/Photos/DeleteInstallationPhotoModal.vue'
import EditInstallationPhotoModal from '@/components/Modals/Photos/EditInstallationPhotoModal.vue'
import QuickAddAppointmentModal from '@/components/Modals/QuickAddAppointmentModal.vue'
import AddFileToInstallationModal from '@/components/Modals/Files/AddFileToInstallationModal.vue'
import EditInstallationFileModal from '@/components/Modals/Files/EditInstallationFileModal.vue'
import DeleteInstallationFileModal from '@/components/Modals/Files/DeleteInstallationFileModal.vue'
import ExportCalendarModal from '@/components/Modals/Appointments/ExportCalendarModal.vue'
import AddExpenseToJobModal from '@/components/Modals/Jobs/AddExpenseToJobModal.vue'
import DeleteExpenseModal from '@/components/Modals/Jobs/DeleteExpenseModal.vue'
import EditExpenseModal from '@/components/Modals/Jobs/EditExpenseModal.vue'
import JobPhotosModal from './Modals/Jobs/JobPhotosModal.vue'
import AddPartnerModal from './Modals/partners/AddPartnerModal.vue'
import DeletePartnerModal from './Modals/partners/DeletePartnerModal.vue'
import EditPartnerModal from './Modals/partners/EditPartnerModal.vue'
import ToggleIsActivePartnerModal from './Modals/partners/ToggleIsActivePartnerModal.vue'
import ToggleCompanyPreferencesModal from './Modals/Company/ToggleCompanyPreferencesModal.vue'
import EditCompanyInfoModal from './Modals/Company/EditCompanyInfoModal.vue'
import AddCompanyExpenseModal from './Modals/Company/AddCompanyExpenseModal.vue'
import OpenOrphanTaskModal from './Modals/Tasks/OpenOrphanTaskModal.vue'
import QuickAddTaskModal from '@/components/Modals/QuickAddTaskModal.vue'
import CompanyExpenseModal from './Modals/Company/CompanyExpenseModal.vue'
import AddToCalendarModal from './Modals/Appointments/AddToCalendarModal.vue'

import { commonModule } from '@/store/modules/store-accessor'

@Component({
  components: {
    LogOutModal,
    AddAppointmentModal,
    AddJobModal,
    DeleteInstallationModal,
    AddClientModal,
    DeleteClientModal,
    EditClientModal,
    EditJobModal,
    DeleteJobModal,
    AddInstallationModal,
    EditInstallationModal,
    AddInventoryItemModal,
    DeleteInventoryItemModal,
    EditInventoryItemModal,
    AddAppointmentToJobModal,
    AddPaymentToJobModal,
    AddActionsToJobModal,
    AddMaterialsToJobModal,
    AddInstallationItemModal,
    DeleteInstallationItemModal,
    EditInstallationItemModal,
    EditAppointmentModal,
    DeleteAppointmentModal,
    EndAppointmentModal,
    CancelAppointmentModal,
    DeleteActionModal,
    EditActionModal,
    DeleteMaterialModal,
    EditMaterialModal,
    DeletePaymentModal,
    EditPaymentModal,
    AddTaskModal,
    EditTaskModal,
    DeleteTaskModal,
    CompleteTaskModal,
    EditActionTypeModal,
    AddActionTypeModal,
    DeleteActionTypeModal,
    AddItemTypeModal,
    EditItemTypeModal,
    DeleteItemTypeModal,
    ToggleArchiveItemTypeModal,
    ToggleArchiveActionTypeModal,
    AddPhotoToInstallationModal,
    DeleteInstallationPhotoModal,
    QuickAddAppointmentModal,
    EditInstallationPhotoModal,
    AddFileToInstallationModal,
    EditInstallationFileModal,
    DeleteInstallationFileModal,
    ExportCalendarModal,
    AddExpenseToJobModal,
    DeleteExpenseModal,
    EditExpenseModal,
    JobPhotosModal,
    AddPartnerModal,
    DeletePartnerModal,
    EditPartnerModal,
    ToggleIsActivePartnerModal,
    ToggleCompanyPreferencesModal,
    EditCompanyInfoModal,
    AddCompanyExpenseModal,
    OpenOrphanTaskModal,
    QuickAddTaskModal,
    CompanyExpenseModal,
    AddToCalendarModal,
  }
})
export default class TheModal extends Vue {
  get modalName () {
    return commonModule.modal.name
  }

  get modalPayload () {
    return commonModule.modal.payload
  }
};
