import { BaseApi } from '@/helpers/base-api'

export default class ActionTypesApi extends BaseApi {
  constructor () {
    super('/api/v1/itemTypes')
    this.errorMessage = 'There was an issue when trying to fetch the item types. If this error persists, please contact our support.'
  }
  
  public getAllItemTypes = (): Promise<any[]> => this.instance.get('')

  public createAItemType = (data: Record<string, unknown>): Promise<JSON> => this.instance.post('', data)

  public updateItemType = (itemTypeId: string, data: Record<string, unknown>): Promise<JSON> => this.instance.patch(`/${itemTypeId}`, data)

  public deleteItemType = (itemTypeId: string): Promise<JSON> => this.instance.delete(`/${itemTypeId}`)

  public itemTypesCombo = (): Promise<any[]> => this.instance.get('combo')

  public getItemTypeById = (itemTypeId: string): Promise<ItemsTypeDto[]> => this.instance.get(`/${itemTypeId}`)
}
