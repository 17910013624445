import en from "./assets/i18n/en.json";
import el from "./assets/i18n/gr.json";
import VueI18n from "vue-i18n";
import Vue from "vue";

Vue.use(VueI18n);
let selectedLanguage = localStorage.getItem('lang') || 'el';
selectedLanguage = (selectedLanguage === 'gr') ? 'el' : selectedLanguage!; // for legacy reasons

export default new VueI18n({
  locale: selectedLanguage,
  messages: {
    en: en,
    el: el,
  },
});
